import React from 'react'
import ConfirmationComponent from './Confirmation.component'
import { authApi } from '../lib/api'
import PubSubSingleton from './PubSub.component'

interface IProps {
  onClose: () => void
}

interface IState {
  oldPassword: string
  password1: string
  password2: string
  hasError: boolean
  errorMessage: string
  loading: boolean
}

class ChangePasswordComponent extends React.Component<IProps, IState> {
  state: IState = {
    oldPassword: '',
    password1: '',
    password2: '',
    hasError: false,
    errorMessage: '',
    loading: false
  }

  handleClick = (buttonName: string) => {
    switch (buttonName) {
      case 'Update':
        const token = window.localStorage.getItem('access_token') as string
        if (!token) throw new Error('no token')
        this.setState({ loading: true })
        authApi
          .changePassword({
            old_password: this.state.oldPassword,
            new_password: this.state.password1,
            token
          })
          .then(this.handleSuccess)
          .catch(this.handleFailure)
        break
      case 'Cancel':
        this.props.onClose()
        break
      default:
        throw new Error(`unknown button: ${buttonName}`)
    }
  }

  handleSuccess = () => {
    PubSubSingleton.getInstance().publishSuccess('Password changed')
    this.setState({ loading: false })
    this.props.onClose()
  }

  handleFailure = (err: any) => {
    const { message } = err
    this.setState({
      loading: false,
      oldPassword: '',
      password1: '',
      password2: '',
      hasError: true,
      errorMessage: message
    })
  }

  handleUpdate = (name: keyof IState, e: any) => {
    const newState = {} as any
    newState[name] = e.target.value
    this.setState(newState)
  }

  getAutoComplete = (field: keyof IState) => {
    if (field === 'password1' || field === 'password2') return 'new-password'
    if (field === 'oldPassword') return 'current-password'
    return ''
  }

  renderField = (field: keyof IState, displayName: string) => {
    return (
      <div className="field">
        <label>{displayName}</label>
        <div className="ui left icon input">
          <i className="lock icon"></i>
          <input
            type="password"
            autoComplete={this.getAutoComplete(field)}
            placeholder={`${displayName}...`}
            value={this.state[field] as string}
            onChange={this.handleUpdate.bind(null, field)}
          />
        </div>
      </div>
    )
  }

  renderForm = () => {
    return (
      <>
        {this.renderField('oldPassword', 'Old password')}
        {this.renderField('password1', 'New password')}
        {this.renderField('password2', 'Repeat new password')}
      </>
    )
  }

  render() {
    const isFormValid =
      this.state.oldPassword &&
      this.state.password1 &&
      this.state.password1 === this.state.password2
    return (
      <ConfirmationComponent
        buttons={['Update', 'Cancel']}
        onClick={this.handleClick}
        title="Change your password"
        isInvalid={!isFormValid}
        loading={this.state.loading}
      >
        {this.state.hasError && (
          <div className="ui message visible error">
            {this.state.errorMessage}
          </div>
        )}

        <form className="ui form">{this.renderForm()}</form>
      </ConfirmationComponent>
    )
  }
}

export default ChangePasswordComponent
