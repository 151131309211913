export default [
  'Ainslie FS',
  'Belconnen FS',
  'West Belconnen FS',
  'Chisholm FS',
  'Fyshwick FS',
  'South Tuggeranong FS',
  'Gungahlin FS',
  'Kambah FS',
  'Phillip FS',
  'Tuggeranong Depot',
  'Belconnen Depot',
  'Woden Depot',
  'Workshop',
  'HQ',
  'Rivers Station',
  'Molonglo Station',
  'Gungahlin Station',
  'Jerrabomberra Station',
  'Guises Creek Station',
  'Southern Station',
  'Tidbinbilla Station',
  'Hume Helibase',
  'Hume Training Centre',
  'PCS Stromlo Depot',
  'Majura Station',
  'Dickson Station',
  'Belconnen Station',
  'Aranda Station',
  'Woden Station',
  'Calwell Station',
  'Fyshwick Station',
  'Greenway Station',
  'West Belconnen Station',
  'Kambah Station',
  'Fairbairn',
  'Pialligo Station',
  'Tuggeranong Station',
  'Depot',
  'Office',
  'PCS Athllon Depot',
  'PCS Murrumbidgee Depot',
  'PCS Mitchell Depot',
  'PCS Namadgi Depot',
  'PCS Tidbinbilla Depot',
  'Toll Southcare',
  'Hall Station',
  'PCS Googong Depot',
  'Forrest Fire Station',
  'Canberra Airport',
  'PCS Molonglo Depot',
  'EPSDD 480NBA'
].sort()
