import React from 'react'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './semantic.min.css'
// import ToastComponent from './components/Toast.component'

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './store'

const domNode = document.getElementById('root');
const root = createRoot(domNode as Element);

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
