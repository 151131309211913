import React from 'react'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import SliderComponent from '../Slider.component'
import { toIsoDateString } from '../../lib/utils'
import { createParameter } from '../../lib/api'

class FilterByDaysCriteria extends IQueryBuilderComponent<
  {},
  { value: number }
> {
  state = { value: 1 }

  getStartDate = () => {
    const now = new Date().getTime()
    const diff = 1000 * 60 * 60 * 24 * this.state.value
    return new Date(now - diff)
  }

  getParameters() {
    return [createParameter('start', toIsoDateString(this.getStartDate()))]
  }

  getType() {
    return 'date'
  }

  handleOnChange = (value: number) => this.setState({ value })

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>
            Within {this.state.value} day{this.state.value === 1 ? '' : 's'}
          </h4>
        </div>
        <div className="four wide column">
          <SliderComponent
            min={1}
            max={31}
            onChange={this.handleOnChange}
            value={this.state.value}
          />
        </div>
        <div className="four wide column">
          from {this.getStartDate().toLocaleString()}
        </div>
      </>
    )
  }
}

export default FilterByDaysCriteria
