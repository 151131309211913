import React from 'react'

const SearchButton = (props: { enable: boolean; loading?: boolean }) => (
  <button
    className={`ui large secondary icon labeled button ${
      props.loading ? 'loading' : ''
    }`}
    type="submit"
    disabled={!props.enable}
  >
    <i className="ui icon search"></i>
    Search
  </button>
)

export default SearchButton
