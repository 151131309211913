import React, { createRef, useState } from 'react'
import styled from 'styled-components'
import { Radio, RadioMetadata } from '../lib/models'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import { getAgencyForRadio } from '../lib/utils'
import ICommonProps from './ICommonProps'
import { capabilityList } from '../lib/capabilities'
import capabilityIconList from '../lib/capabilityIconList.json'
import { setHighlightedRadios } from '../store/mapDrawingSlice'
import { useAppDispatch } from '../store/hooks'
import { setSelectedDuressRadio } from '../store/mapHistorySlice'

// the height of the element in the search results
const elementHeight = 56.81

// the number of search results on the page
const elementsOnPage = 8

const DivContainer = styled.div`
  position: relative;
  width: 100%;
`

const SearchResultsDiv = styled.div`
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5000;
  color: #333;
`

const StyledInput = styled.input`
  width: 400px;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
`

const ClickableDiv = styled.div`
  cursor: pointer;
  text-align: left !important;
  height: ${elementHeight};
`

const RightFloatedSpan = styled.span`
  float: right;
`

const Img = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 8px;
  margin-top: -8px;
  margin-bottom: -8px;
`

const RadioTitle = styled.span`
  font-weight: bold;
`

const AliasTitle = styled.span`
  opacity: 0.5;
  padding-left: 8px;
`

const CenteredDiv = styled.span`
  max-width: 768px;
`
const ScrollBarDiv = styled.div`
  overflow-y: scroll;
  max-height: ${elementsOnPage * elementHeight}px;
`

// interface IState {
//   value: string
//   activeIndex: number
//   results: Radio[]
// }

function SearchComponent({ radios, agencies, positions, duresses, isMobile }: ICommonProps) {
    const [value, setValue] = useState('')
    const [activeIndex, setActiveIndex] = useState(-1)
    const [results, setResults] = useState<Radio[]>([])

    const scrollableRef = createRef<HTMLDivElement>()

    const dispatch = useAppDispatch()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const results = searchRadios(e.target.value)
        setValue(e.target.value)
        setActiveIndex(-1)
        setResults(results)
    }

    const scroll = () => {
        const element = scrollableRef.current
        if (!element) return

        const scrollHeight = element.scrollTop
        const totalHeight = results.length * elementHeight

        const maxHeight = Math.min(
        totalHeight,
        activeIndex * elementHeight
        )
        const minHeight = Math.max(
            0,
            activeIndex * elementHeight -
            (elementsOnPage - 1) * elementHeight
        )

        if (minHeight > scrollHeight) {
            element.scrollTo(0, minHeight)
            return
        }

        if (maxHeight < scrollHeight) {
            element.scrollTo(0, maxHeight)
            return
        }
    }

    const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.keyCode) {
            case 40:
                setActiveIndex(Math.min(results.length - 1, activeIndex + 1))
                scroll()

                e.preventDefault()
                e.stopPropagation()
                break
            case 38:
                // the [up] arrow key has been pressed
                setActiveIndex(Math.max(0, activeIndex - 1))
                scroll()

                e.preventDefault()
                e.stopPropagation()
                break
            case 13:
                // the [enter] key has been pressed
                if (activeIndex < 0 && results.length === 1) {
                    // users often press enter when their results are narrowed down to one
                    // and expect the same behavior as if they'd clicked on that one result
                    handleViewRadio(results[0])
                    return
                }
                if (activeIndex < 0) {
                    handleFilterRadios()
                    return
                }
                handleViewRadio(results[activeIndex])
                break
            case 27:
                // the [ESC] key has been pressed
                if (value.length > 0) {
                    setValue('')
                }
                break
        }
    }

    const doesRadioContainSearchTerm = (radio: Radio, searchTerm: string) => {
        const term = searchTerm.toUpperCase().trim()
        if (radio.radio.startsWith(term)) {
            return true
        }

        if (radio.cam && radio.cam.alias && radio.cam.alias.toUpperCase().indexOf(term) >= 0) {
            return true
        }

        // check the values of the static dictionary
        if (!radio.static) {
            return false
        }

        const keys = Object.keys(radio.static) as Array<keyof RadioMetadata>
        for (let i = 0; i < keys.length; i++) {
            if ((radio.static[keys[i]] || '').toUpperCase().startsWith(term)) {
                return true
            }
        }
        return false
    }

    const resetState = () => {
        setValue('')
        setActiveIndex(-1)
        setResults([])
    }

    const searchRadios = (value: string) => radios.filter(radio => doesRadioContainSearchTerm(radio, value))

    const handleViewRadio = (radio: Radio) => {
        if (radio) {
            dispatch(setHighlightedRadios([radio.radio]))
            PubSubSingleton.getInstance().publish(messageTypes.radiosHighlighted, [radio.radio])
        }
        dispatch(setSelectedDuressRadio(radio.radio))
        PubSubSingleton.getInstance().publish(messageTypes.showRadio, radio)
        resetState()
    }

    const handleFilterRadios = () => {
        PubSubSingleton.getInstance().publish(
        messageTypes.radiosHighlighted,
        results.map(x => x.radio)
        )
        resetState()
    }

    const handleBlur = () => {
        setTimeout(() => {
            resetState()
        }, 500)
    }

    const getIcon = (radio: Radio) => {
        const standardIcon = '/marker.svg'
        const capName = radio.static?.capability
        if (!capName) return standardIcon

        const capability = capabilityList.find(c => c.name === capName)
        if (!capability?.symbol) {
            return standardIcon
        }

        if (!capabilityIconList.includes(`${capability.symbol}.svg`)) {
            return standardIcon
        }

        return `/capability/${capability.symbol}.svg`
    }

    const renderRadio = (radio: Radio) => {
        const agency = getAgencyForRadio(radio, agencies)
        let warnNoLocation = !(positions.get(radio.radio) || duresses.find(x => x.radio === radio.radio))

        return (
        <>
            {warnNoLocation && (<Img src="/noGPS.png" title="Location not available" />)}
            <Img src={getIcon(radio)} />
            <RadioTitle>{(radio.cam && radio.cam.alias) || radio.radio}</RadioTitle>
            <AliasTitle>{radio.static?.capability}</AliasTitle>
            {agency && (
            <RightFloatedSpan className="ui small label">
                {agency.name}
            </RightFloatedSpan>
            )}
        </>
        )
    }

    const renderRadioResults = () => {
        let _results = results.slice(0, 100)
        if (!_results.length) {
            return <div className="ui large clearing segment">No matching radios</div>
        }

        return _results.map((radio, index) => {
            const active = index === activeIndex
            return (
                <ClickableDiv
                    onClick={handleViewRadio.bind(null, radio)}
                    className={`ui large clearing segment ${
                        active ? 'inverted blue' : ''
                    }`}
                    key={radio.radio}
                >
                    {renderRadio(radio)}
                </ClickableDiv>
            )
        })
    }

    const renderResultsFooter = () => {
        const num = results.length
        if (num <= 100) return <></>

        return (
        <div className="ui large clearing segment">
            Showing 100 results, {num - 100} more results not shown...
        </div>
        )
    }

  
    return (
        <DivContainer>
            <div className="ui fluid inverted input tiny">
                <StyledInput /* eslint-disable-line jsx-a11y/no-access-key */
                    type="text"
                    placeholder="Search..."
                    accessKey="s"
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKey}
                    onBlur={handleBlur}
                />
            </div>
            {value.length > 1 && (
                <SearchResultsDiv style={{ top: isMobile ? 112 : 62 }}>
                    <div className="ui grid">
                    <CenteredDiv className="wide centered column">
                        <ScrollBarDiv className="ui segments" ref={scrollableRef}>
                        {renderRadioResults()}
                        {renderResultsFooter()}
                        </ScrollBarDiv>
                    </CenteredDiv>
                    </div>
                </SearchResultsDiv>
            )}
        </DivContainer>
    )
  
}

export default SearchComponent
