import { UserInfo } from './models'

import { MapOptionsState } from '../store/mapOptionsSlice'

// Backwards compatibility is fun
export interface MapOptions extends MapOptionsState {};

const defaultMapOptions = (user: UserInfo): MapOptions => ({
  showTraffic: true,
  showEsaIncidents: true,
  showFiresNearMe: true,
  showTransit: true,
  hiddenAgencies: [],
  minAge: 0,
  maxAge: 48,
  username: user.email
})

export const getMapOptions = (user: UserInfo): MapOptions => {
  const savedOptions = readMapOptions(user)

  // the user may have changed
  if (savedOptions.username !== user.email) return defaultMapOptions(user)
  return savedOptions
}

export const setMapOptions = (value: MapOptions) => {
  window.localStorage.setItem('mapOptions', JSON.stringify(value))
}

export const readMapOptions = (user: UserInfo) => {
  const value = window.localStorage.getItem('mapOptions')
  if (!value) return defaultMapOptions(user)
  return JSON.parse(value) as MapOptions
}
