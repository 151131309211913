import { QueryParameter } from '../lib/models'
import React from 'react'
import { IReportingProps } from './ICommonProps'

export abstract class IQueryBuilderComponent<T, Y> extends React.Component<
  T & IReportingProps,
  Y
> {
  abstract getParameters(): QueryParameter[]
  abstract getType(): string
}
