import React, { useState } from 'react'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import ConfirmationComponent from './Confirmation.component'
import { Radio, Duress } from '../lib/models'
import ICommonProps from './ICommonProps'
import { scrollToBottom } from '../lib/utils'
import styled from 'styled-components'
import { hasPlatformAdmin, hasOpsAccess } from '../lib/accessControl'
import RadioInfoComponent from './RadioInfo.component'
import { getAgencyForRadio, formatTimestamp } from '../lib/utils'
import { mapsApi } from '../lib/api'
import ExpandoIconComponent from './ExpandoIcon.component'
import duressTypes from '../lib/duressTypes'

import { useAppDispatch } from '../store/hooks'
import { setSelectedDuressRadio } from '../store/mapHistorySlice'
import { setDuressLoading } from '../store/duressControl'

const RightFloatedDiv = styled.div`
  float: right;
  cursor: pointer;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

interface IProps extends ICommonProps {
  duress: Duress
  titleType: string
  onRefresh: () => void
}

function DuressComponent(props: IProps) {
    const isAcked = () => props.duress && props.duress.acknowledgements.length > 0
    
    const [loading, setLoading] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(!isAcked())
    const [bulkAck, setBulkAck] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [duressType, setDuressType] = useState<string>('')

    const dispatch = useAppDispatch()

    const canExpand = () => isAcked()

    const handleView = () => {
        dispatch(setSelectedDuressRadio(props.duress.radio))

        if (props.isMobile) {
            scrollToBottom()
        }
    }

    const handleConfirmationResult = (result: string) => {
        switch (result) {
            case 'Acknowledge':
                setLoading(true)
                let duresses = [props.duress]
                if (bulkAck) duresses = getUnackedDuresses()

                Promise.all(
                    duresses.map(x => mapsApi.acknowledgeDuress(duressType, x))
                ).then(values => {
                    const num = values.length
                    PubSubSingleton.getInstance().publishSuccess(
                        `${num} duress event${
                            num > 1 ? 's' : ''
                        } acknowledged successfully`
                    )
                }).catch(error =>
                    PubSubSingleton.getInstance().publishError(
                        'Unexpected error when attempting to acknowledge duress'
                    )
                ).finally(() => {
                    dispatch(setDuressLoading(true))
                    PubSubSingleton.getInstance().publish(messageTypes.reloadPositions)
                    props.onRefresh()
                    setLoading(false)
                    setShowModal(false)
                    setDuressType('')
                })
                break
            case 'Cancel':
                setShowModal(false)
                break
        }
    }

    const getUnackedDuresses = () => props.duresses.filter(x => x.radio === props.duress.radio && x.acknowledgements.length === 0)

    const renderTitle = (radio: Radio) => {
        const duress = props.duress
        switch (props.titleType) {
        case 'radio':
            return radio?.cam?.alias || radio.radio
        case 'timestamp':
            return formatTimestamp(duress.timestamp)
        }
    }

    const renderModal = () => {
        if (!showModal) return <></>

        const radio = props.radios.find(
        x => x.radio === props.duress.radio
        )
        const alias = radio?.cam?.alias || radio?.radio
        const num = getUnackedDuresses().length

        return (
            <ConfirmationComponent
                title="Acknowledge duress"
                onClick={handleConfirmationResult}
                buttons={['Acknowledge', 'Cancel']}
                buttonIcons={['check', 'close']}
                loading={loading}
                isInvalid={!duressType}>

                <div className="ui form">
                {num > 1 && (
                    <>
                        <h4>Bulk Acknowledgement</h4>
                        <div className="inline field">
                            <div
                            className="ui toggle checkbox"
                            >
                                <input
                                    type="checkbox"
                                    name="bulk"
                                    tabIndex={0}
                                    title="Bulk acknowledgement"
                                    checked={bulkAck}
                                    onChange={() => setBulkAck(!bulkAck)}
                                />
                                <label>Acknowledge all {num} duress events?</label>
                            </div>
                        </div>
                        <div className="ui divider"></div>
                    </>
                )}

                <h4>Type of duress</h4>
                <div className="grouped fields">
                    {duressTypes.map(x => (
                        <div
                            key={x}
                            className="field"
                        >
                            <div className="ui radio checkbox">
                                <input
                                    type="radio"
                                    name="duressType"
                                    checked={duressType === x}
                                    onChange={() => setDuressType(x)}
                                />{' '}
                                <label>{x}</label>
                            </div>
                        </div>
                    ))}
                </div>

                {duressType && (
                    <>
                        <h4>Confirmation</h4>
                        <p>
                            Are you sure that you want to acknowledge{' '}
                            {bulkAck ? (
                                <>
                                    all <strong>{num}</strong> currently unacknowledged duress
                                    events
                                </>
                            ) : (
                                <>the duress event</>
                            )}{' '}
                            for radio <strong>{alias}</strong> as{' '}
                            <strong>{duressType}</strong>?
                        </p>
                    </>
                )}
                </div>
            </ConfirmationComponent>
        )
    }

    const renderAckControl = () => {
        return (
            <>
                <div className="ui segment secondary">
                    <div
                        className="ui fluid button secondary large"
                        onClick={() => setShowModal(true)}>
                            <i className="check icon"></i>
                            Acknowledge ...
                    </div>
                </div>
                {renderModal()}
            </>
        )
    }

    let colour = 'red'
    let radio = props.radios.find(x => x.radio === props.duress.radio)
    if (!radio) {
        colour = 'orange'
        radio = {
            radio: props.duress.radio,
            cam: { alias: 'UNKNOWN' },
            static: {}
        } as Radio
    }

    let allowAck = hasPlatformAdmin(props.user)
    const agency = getAgencyForRadio(radio, props.agencies)

    if (agency) {
        allowAck = allowAck || hasOpsAccess(props.user, agency)
    }

    const hasBeenAcked = props.duress.acknowledgements.length > 0

    return (
        <>
            <div className="ui segments">
            <div
                className={`ui segment ${
                props.duress.active ? `inverted ${colour}` : ''
                }`}
            >
                {canExpand() && (
                    <RightFloatedDiv
                        onClick={() => setExpanded(!expanded)}>
                        <ExpandoIconComponent expanded={expanded} />
                    </RightFloatedDiv>
                )}
                {expanded && (
                    <RadioInfoComponent
                        agencies={props.agencies}
                        duress={props.duress}
                        positions={props.positions}
                        radio={radio}
                        showViewButton={true}
                        onView={handleView}
                    />
                )}
                {!expanded && <Title>{renderTitle(radio)}</Title>}
            </div>

            {!props.isMobile &&
                !hasBeenAcked &&
                allowAck &&
                expanded &&
                renderAckControl()}
            </div>
        </>
    )
    
}

export default DuressComponent
