const send = (
  baseUrl: string,
  method: string,
  url: string,
  body?: any,
  disableAuthToken?: boolean
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open(method, baseUrl + url, true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Accept', 'application/json')
    const token = window.localStorage.getItem('token')
    if (token && !disableAuthToken) {
      xhr.withCredentials = false
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    }
    xhr.onload = () => {
      try {
        if (xhr.status >= 400) {
          return reject(parseResponse(xhr))
        }
        resolve(parseResponse(xhr))
      } catch (e) {
        reject(e)
      }
    }

    xhr.onerror = reject
    xhr.send(body ? JSON.stringify(body) : undefined)
  })
}

const parseResponse = (xhr: XMLHttpRequest) => {
  const contentType = xhr.getResponseHeader('content-type')
  if (contentType?.startsWith('application/json') && xhr.responseText)
    return JSON.parse(xhr.responseText)
  if (contentType?.startsWith('text/')) return xhr.responseText
  return null
}

export function get<T>(baseUrl: string, url: string): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    send(baseUrl, 'GET', url)
      .then(data => resolve(data as T))
      .catch(reject)
  })
}

export function put<T>(baseUrl: string, url: string, body: any): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    send(baseUrl, 'PUT', url, body)
      .then(data => resolve(data as T))
      .catch(reject)
  })
}

export function post<T>(
  baseUrl: string,
  url: string,
  body: any,
  disableAuthToken?: boolean
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    send(baseUrl, 'POST', url, body, disableAuthToken)
      .then(data => resolve(data as T))
      .catch(reject)
  })
}

export function del<T>(baseUrl: string, url: string): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    send(baseUrl, 'DELETE', url)
      .then(data => resolve(data as T))
      .catch(reject)
  })
}
