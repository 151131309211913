import React from 'react'
import { authApi } from '../lib/api'
import {
  AuthGetUserResponse,
  AuthVerificationAttribute,
  UserAttributes
} from '../lib/models'
// import PubSubComponent from './PubSub.component'
import ConfirmationComponent from './Confirmation.component'
import Styled from 'styled-components'

const StyleIcon = Styled.i`
  margin-top: 5px !important
`

interface IProps {
  title: string
  onClose: () => void
}

interface IState {
  loading: boolean
  hasError: boolean
  errorMessage: string
  phoneVerificationCode: string
  emailVerificationCode: string
  currentUserAttributes: UserAttributes[]
  gettingCode: boolean
  verifyingCode: boolean
  smsMfaEnabled: boolean
  user?: AuthGetUserResponse
}

class UserDetailsComponent extends React.Component<IProps, IState> {
  state: IState = {
    loading: true,
    hasError: false,
    errorMessage: '',
    phoneVerificationCode: '',
    emailVerificationCode: '',
    currentUserAttributes: [],
    gettingCode: false,
    verifyingCode: false,
    smsMfaEnabled: false
  }

  componentDidMount() {
    this.getUserDetails()
  }

  handleGetUserSuccess = (user: AuthGetUserResponse) => {
    this.setState({ loading: false })

    const smsMfaEnabled =
      user?.UserMFASettingList?.includes('SMS_MFA') ||
      !!user?.MFAOptions?.find(x => x.AttributeName === 'phone_number')

    if (!user) return
    // Copy current user attributes for comparison on changes
    const currentUserAttributes = JSON.parse(
      JSON.stringify(user.UserAttributes)
    )
    this.setState({ user, smsMfaEnabled, currentUserAttributes })
  }

  getUserDetails = () => {
    this.setState({
      loading: true,
      errorMessage: '',
      hasError: false
    })
    const token = window.localStorage.getItem('access_token') as string
    authApi
      .userDetails({ access_token: token })
      .then(this.handleGetUserSuccess)
      .catch(this.handleFailure)
  }

  handleFailure = (err: any) => {
    const { message } = err

    this.setState({
      gettingCode: false,
      verifyingCode: false,
      loading: false,
      hasError: true,
      errorMessage: message
    })
  }

  verifiedIcon(isVerified?: boolean) {
    const verified = isVerified ? 'green check' : 'red circle times'
    const title = isVerified ? 'Verified' : 'Unverified'
    return (
      <span>
        <StyleIcon
          className={`right icon big circle ${verified}`}
          title={title}
        />
      </span>
    )
  }

  getVerificationCode = (
    attribute: AuthVerificationAttribute,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    this.setState({ gettingCode: true })
    const accessToken = window.localStorage.getItem('access_token') as string
    authApi
      .getVerificationCode({ access_token: accessToken, attribute })
      .then(() => this.setState({ gettingCode: false }))
      .catch(this.handleFailure)
  }

  verifyCodeSuccessHandler = (e: any) => {
    this.setState({
      verifyingCode: false
    })
    this.getUserDetails()
  }

  verifyCode = (
    attribute: AuthVerificationAttribute,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    this.setState({ verifyingCode: true })
    const accessToken = window.localStorage.getItem('access_token') as string
    const code = this.getFieldState(attribute)

    authApi
      .verifyUserAttribute({ access_token: accessToken, attribute, code })
      .then(this.verifyCodeSuccessHandler)
      .catch(this.handleFailure)
  }

  handleUpdateUser = (name: string, e: any) => {
    const { user } = this.state
    if (!user) return
    const attr = user.UserAttributes.find(x => x.Name === name)

    if (attr) {
      attr.Value = e.target.value
    } else {
      user.UserAttributes.push({
        Name: name,
        Value: e.target.value
      })
    }
    this.setState({ user })
  }

  onUpdateClicked = () => {
    const { user } = this.state

    if (!user) return

    const { UserAttributes, UserMFASettingList } = user
    const phoneNumber =
      UserAttributes?.find(x => x.Name === 'phone_number')?.Value || ''
    const accessToken = window.localStorage.getItem('access_token') as string
    //Update User Settings First
    this.setState({ loading: true })
    authApi
      .updateUser({ access_token: accessToken, phone_number: phoneNumber })
      .then(this.getUserDetails)
      .catch(this.handleFailure)

    //If Changes to MFA then update MFA settings
    const smsMfaEnabled = UserMFASettingList?.includes('SMS_MFA')
    if (!smsMfaEnabled && !this.state.smsMfaEnabled) return
    const smsEnabled = this.state.smsMfaEnabled ? 'true' : 'false'
    authApi
      .updateMFAPreferences({
        access_token: accessToken,
        sms_enabled: smsEnabled
      })
      .then(this.getUserDetails)
      .catch(this.handleFailure)
  }

  getFieldState = (field: AuthVerificationAttribute) => {
    switch (field) {
      case 'phone_number':
        return this.state.phoneVerificationCode
      case 'email':
        return this.state.emailVerificationCode
    }
  }

  setFieldState = (field: AuthVerificationAttribute, value: any) => {
    switch (field) {
      case 'phone_number':
        this.setState({ phoneVerificationCode: value })
        break
      case 'email':
        this.setState({ emailVerificationCode: value })
        break
    }
  }

  renderField = (
    field: AuthVerificationAttribute,
    label: string,
    icon: string,
    disabled: boolean = false
  ) => {
    const { currentUserAttributes, user } = this.state

    if (!user) return

    const { UserAttributes } = user
    const value = UserAttributes?.find(x => x.Name === field)?.Value
    const currentValue = currentUserAttributes?.find(x => x.Name === field)
      ?.Value
    const valueVerified =
      UserAttributes?.find(x => x.Name === `${field}_verified`)?.Value ===
      'true'
    const showVerify = !!(
      !valueVerified &&
      this.state.user &&
      !this.state.loading &&
      value &&
      value === currentValue
    )

    return (
      <div className="field">
        <label>{label}</label>
        <div className="ui left icon input">
          <i className={`${icon} icon`}></i>
          <input
            type="text"
            value={value || ''}
            readOnly={disabled}
            disabled={disabled}
            onChange={this.handleUpdateUser.bind(null, field)}
          />
          {!this.state.loading &&
            currentValue === value &&
            this.verifiedIcon(valueVerified)}
        </div>
        {showVerify && (
          <div className="inline fields" style={{ marginTop: '5px' }}>
            <div className="field">
              <button
                className={`ui button ${
                  this.state.gettingCode ? 'loading' : ''
                }`}
                onClick={this.getVerificationCode.bind(null, field)}
              >
                Get Code
              </button>
              <input
                type="text"
                value={this.getFieldState(field)}
                onChange={e => this.setFieldState(field, e.target.value)}
              />
            </div>
            <div className="field">
              <button
                className={`ui button ${
                  this.state.verifyingCode ? 'loading' : ''
                }`}
                onClick={this.verifyCode.bind(null, field)}
                disabled={this.getFieldState(field).length !== 6}
              >
                Verify
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderMfaOptions = () => {
    const { user } = this.state
    const mfaAvailable =
      user?.UserAttributes?.find(x => x.Name === `phone_number_verified`)
        ?.Value === 'true'
    const disabled =
      !!user?.MFAOptions?.find(x => x.AttributeName === 'phone_number') ||
      this.state.loading

    if (!mfaAvailable) {
      return (
        <div className="field">
          <h5>To enable SMS MFA a verified phone number is required</h5>
        </div>
      )
    }

    return (
      <div className="field">
        <label>MFA Options</label>
        <div
          className="ui checkbox"
          title={
            disabled
              ? 'This has been enabled by an administrator and can not be changed'
              : ''
          }
        >
          <input
            type="checkbox"
            name="sms_enabled"
            disabled={disabled}
            checked={this.state.smsMfaEnabled || false}
            onChange={() =>
              this.setState(prevState => ({
                smsMfaEnabled: !prevState.smsMfaEnabled
              }))
            }
          />
          <label>Enable SMS MFA</label>
        </div>
      </div>
    )
  }

  renderForm = () => {
    const { user, loading } = this.state
    const phoneDisabled =
      !!user?.MFAOptions?.find(x => x.AttributeName === 'phone_number') ||
      loading
    return (
      <div>
        {this.renderField('email', 'E-mail Address', 'envelope', true)}
        {this.renderField(
          'phone_number',
          'Phone Number',
          'phone',
          phoneDisabled
        )}
        {user && this.renderMfaOptions()}
      </div>
    )
  }

  handleClick = (buttonName: string) => {
    switch (buttonName) {
      case 'Update':
        const token = window.localStorage.getItem('access_token') as string
        if (!token) throw new Error('no token')
        this.onUpdateClicked()
        break
      case 'Cancel':
        this.props.onClose()
        break
      default:
        throw new Error(`unknown button: ${buttonName}`)
    }
  }

  render() {
    return (
      <ConfirmationComponent
        buttons={['Update', 'Cancel']}
        onClick={this.handleClick}
        title="User Details"
        loading={this.state.loading}
      >
        {this.state.hasError && (
          <div className="ui message visible error">
            {this.state.errorMessage}
          </div>
        )}
        <form className="ui form">{this.renderForm()}</form>
      </ConfirmationComponent>
    )
  }
}

export default UserDetailsComponent
