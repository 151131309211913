import React from 'react'
import { IReportingProps } from './ICommonProps'
import TabsComponent from './Tabs.component'
import RadioByRadioId from './radioFilters/RadioByField.component'
import RadioByFilter from './radioFilters/RadioByFilter.component'
import RadioWithNoAlias from './radioFilters/RadiosWithNoAlias.component'
import RadioWithNoAgency from './radioFilters/RadiosWithNoAgency.component'
import { hasPlatformAdmin } from '../lib/accessControl'

interface IState {
  activeIndex: number
}

class RadioSearchFormComponent extends React.Component<
  IReportingProps,
  IState
> {
  state: IState = {
    activeIndex: 0
  }

  handleTabChange = (activeIndex: number) => this.setState({ activeIndex })

  renderForm() {
    switch (this.state.activeIndex) {
      case 0:
        return <RadioByRadioId {...this.props} />
      case 1:
        return <RadioByFilter {...this.props} />
      case 2:
        return <RadioWithNoAlias {...this.props} />
      case 3:
        return <RadioWithNoAgency {...this.props} />
      default:
        return <>Unknown tab</>
    }
  }

  render() {
    let tabs = ['Find radio by ID', 'Find radios with filters']

    if (hasPlatformAdmin(this.props.user)) {
      tabs = [
        ...tabs,
        ...['Find radios with no Alias', 'Find radios with no Agency']
      ]
    }

    return (
      <div className="ui grid">
        <div className="four wide column">
          <h4>Filter options</h4>
          <TabsComponent
            tabs={tabs}
            activeIndex={this.state.activeIndex}
            onChange={this.handleTabChange}
            tabClass="ui secondary vertical fluid menu"
          />
        </div>
        <div className="twelve wide stretched column">{this.renderForm()}</div>
      </div>
    )
  }
}

export default RadioSearchFormComponent
