import React from 'react'
import {
    Radio,
    Agency,
    PositionEvent,
    Duress,
    Acknowledgement,
    RadioMetadata
} from '../lib/models'
import {
    getAgencyForRadio,
    formatTimestamp,
    prettifyLatLng,
    titleCase
} from '../lib/utils'
import MgaComponent from './Mga.component'
import styled from 'styled-components'
import pretty from 'pretty-time'
import geocode from '../lib/geocode'
import PubSubSingleton from './PubSub.component'
import locator from '../lib/locator'
import HistoryRangeComponent from './HistoryRange.component'
import DisplaySlugComponent from './DisplaySlug.component'

const prettyTimespan = (timestamp: number) =>
    pretty([Math.max(0, (new Date().getTime() - timestamp) / 1000), 0], 's')

const prettyElapsedTime = (time1: number, time2: number) =>
    pretty([Math.max(0, time2 - time1), 0], 's')

const Bold = styled.span`
    font-weight: bold;
`

const Smaller = styled.span`
    font-size: 12px;
`

const Title = styled.span`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
`

const PaddedButton = styled.button`
    margin-top: 12px !important;
    margin-bottom: 12px !important;
`

const PaddedDiv = styled.div`
    padding-bottom: 12px;
`

interface IProps {
    radio: Radio
    agencies: Agency[]
    showViewButton?: boolean
    onView?: () => void
    duress?: Duress
    positions: Map<string, PositionEvent>
}

interface IState {
    address: string
    loadingHistory: boolean
    showHistory: boolean
    historyRange: number
}

export class RadioInfoComponent extends React.Component<IProps, IState> {
    state: IState = {
        address: '',
        loadingHistory: false,
        showHistory: false,
        historyRange: 1
    }

    getPosition = (props: IProps) => {
        // attempt to find the latest position
        let position = props.positions.get(props.radio.radio)
        if (position) return position

        if (props.duress) {
            return {
                radio: props.duress.radio,
                position: props.duress.location,
                timestamp: props.duress.timestamp,
                expiry: props.duress.timestamp
            }
        }
        return null
    }
    timer?: ReturnType<typeof setInterval>;

    componentDidMount() {
        this.geocodeAddress()
        this.timer = setInterval(() => this.setState({}), 1000)
    }

    geocodeAddress = async () => {
        try {
            const position = this.getPosition(this.props)
            if (!position) return
            const address = await geocode(position.position)
            this.setState({ address })
        } catch (e) {
            console.log("Geocode error", e)
            this.setState({ address: 'Error when attempting to find the address' })
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        PubSubSingleton.getInstance().componentWillUnmount()
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props === undefined || !this.props.radio) {
            return
        }
        if (oldProps.radio?.radio !== this.props.radio.radio) {
            this.setState({ address: '', showHistory: false })
            this.geocodeAddress()
            return
        }
        const oldPosition = this.getPosition(oldProps)
        const newPosition = this.getPosition(this.props)
        if (!newPosition) return
        if (!oldPosition) return
        if (
            newPosition.position.latitude !== oldPosition.position.latitude ||
            newPosition.position.longitude !== oldPosition.position.longitude
        ) {
            this.geocodeAddress()
            return
        }
    }

    renderAck = (ack: Acknowledgement, index: number) => {
        if (!this.props.duress) return <></>
        return (
            <p key={index}>
                Acknowledged after{' '}
                <Bold>
                    {prettyElapsedTime(this.props.duress.timestamp, ack.timestamp)}
                </Bold>
                <br />
                <Smaller>
                    at {formatTimestamp(ack.timestamp)}
                    <br />
                    by {ack.username}
                    <br />
                    as <Bold>{ack.type}</Bold>
                </Smaller>
            </p>
        )
    }

    handleView = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (this.props.onView) this.props.onView()
    }

    handleDirections = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        const position = this.getPosition(this.props)
        if (!position) return
        const { latitude, longitude } = position.position
        const lastPosition = locator.getLastPosition()

        const url = lastPosition
            ? `?api=1&origin=${lastPosition.coords.latitude},${lastPosition.coords.longitude}&destination=${latitude},${longitude}&travelmode=driving`
            : `?api=1&destination=${latitude},${longitude}`

        window.open(`https://maps.google.com/maps/dir/${url}&travelmode=driving`)
    }

    renderDuressStatus = () => {
        if (!this.props.duress) return <></>
        const text = this.props.duress.active
            ? 'Emergency Active'
            : 'Emergency Inactive'
        return (
            <PaddedDiv>
                <span className="ui label black">{text}</span>
            </PaddedDiv>
        )
    }

    renderTitle = () => {
        let title = this.props.radio?.cam?.alias || this.props.radio.radio
        return <Title>{title}</Title>
    }

    renderAgency = () => {
        const agency = getAgencyForRadio(this.props.radio, this.props.agencies)
        if (!agency) return <>Unknown Agency</>
        const name = agency ? agency.name : 'Unknown Agency'
        return (
            <p title="Agency">
                <Smaller>{name}</Smaller>
            </p>
        )
    }

    renderMetadata = (key: keyof RadioMetadata, includeKey?: boolean) => {
        if (!this.props.radio.static) return <></>
        if (this.props.radio.static[key] === undefined) return <></>
        if (includeKey)
        return (
            <p>
                {titleCase(key)}:{' '}
                <Bold>
                    <DisplaySlugComponent value={this.props.radio.static[key]} />
                </Bold>
            </p>
        )
        return <p title={key}>{this.props.radio.static[key]}</p>
    }

    renderCurrentLocation = () => {
        const position = this.getPosition(this.props)
        if (!position)
        return (
            <p>
                <Smaller>No Position Available</Smaller>
            </p>
        )

        const mgaCoordinates = MgaComponent(position.position)
        return (
            <div className="ui segment">
                <p>
                    <Bold>Location</Bold> reported at{' '}
                    {formatTimestamp(position.timestamp)}
                </p>
                <p>
                    <Bold>{prettifyLatLng(position.position)}</Bold>
                </p>
                {this.state.address && <p>{this.state.address}</p>}
                <p>{mgaCoordinates}</p>
                <p>
                    <Smaller>
                        Reported {prettyTimespan(position.timestamp * 1000)} ago
                    </Smaller>
                </p>
            </div>
        )
    }

    renderViewHistory = () => <HistoryRangeComponent radio={this.props.radio} />

    renderGetDirections = () => {
        return (
            <PaddedButton
                className="fluid ui button black large labeled icon"
                onClick={this.handleDirections}
            >
                <i className="ui icon car"></i>
                Get directions
            </PaddedButton>
        )
    }

    renderDuress() {
        if (!this.props.duress) return <></>

        const duressDate = formatTimestamp(this.props.duress.timestamp)

        return (
            <div className="ui segment">
                <p>
                    <Bold>Duress</Bold> reported at {duressDate}
                </p>
                {this.props.duress.acknowledgements.map(this.renderAck)}
                <p>
                    <Smaller>
                        Reported at {prettifyLatLng(this.props.duress.location)}
                    </Smaller>
                </p>
            </div>
        )
    }

    renderViewLocation = () => {
        if (!this.props.showViewButton) return <></>
        return (
            <PaddedButton
                className="fluid ui button black large labeled icon"
                onClick={this.handleView}
            >
                <i className="ui icon map marker alternate"></i>
                View location
            </PaddedButton>
        )
    }

    renderRadioId = () => (
        <p>
            Radio ID: <Bold>{this.props.radio.radio}</Bold>
        </p>
    )

    render() {
        if (this.props === undefined || !this.props.radio) return <>Radio info unavailable</>
        return (
            <>
                {this.renderDuressStatus()}
                {this.renderTitle()}
                {this.renderAgency()}
                {this.renderMetadata('capability')}
                {this.renderMetadata('capacity')}
                {this.renderDuress()}
                {this.renderCurrentLocation()}
                {this.renderMetadata('type', true)}
                <Smaller>
                    {this.renderRadioId()}
                    {this.renderMetadata('home_base', true)}
                    {this.renderMetadata('service_id', true)}
                    {this.renderMetadata('notes', true)}
                </Smaller>
                {this.renderViewLocation()}
                {this.renderGetDirections()}
                {this.renderViewHistory()}
            </>
        )
    }
}

export default RadioInfoComponent
