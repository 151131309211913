import React from 'react'
import { useParams } from 'react-router'
// import { withRouter } from '../components/WithRouter.component'
import ICommonProps from '../components/ICommonProps'
import EditRadioComponent from '../components/EditRadio.component'

type PathParamsType = {
  radioId: string
}

// Your component own properties
// type PropsType = RouterProps & ICommonProps

function EditRadioPage({ radios, user, agencies }: ICommonProps) {
  const { radioId } = useParams<PathParamsType>()

  const radio = radios.find(x => x.radio === radioId)
  if (!radio) return <>Not found</>
  return (
    <EditRadioComponent
      radio={radio}
      agencies={agencies}
      user={user}
    />
  )
}

export default EditRadioPage
