import React from 'react'
import { radiosToQueryResult } from './utilities'
import { Radio } from '../../lib/models'
import SearchButton from '../SearchButton.component'
import { ISearchForm } from '../ISearchForm'
import RadioIdInput from '../RadioIdInput.component'

interface IState {
  radio?: Radio
}

class RadioByRadioId extends ISearchForm<{}, IState> {
  state: IState = {}
  handleSearch = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const results: Radio[] = []
    if (this.state.radio) results.push(this.state.radio)
    this.props.onResults(radiosToQueryResult(this.props.agencies, results))
  }
  handleChange = (radio?: Radio) => {
    this.setState({ radio })
  }

  render() {
    return (
      <form className="ui form" onSubmit={this.handleSearch}>
        <div className="field">
          <label>Radio ID</label>
          <RadioIdInput
            onChange={this.handleChange}
            radios={this.props.radios}
          />
        </div>
        <SearchButton enable={!!this.state.radio} />
      </form>
    )
  }
}

export default RadioByRadioId
