import React, { createRef } from 'react'
// import { withRouter } from '../components/WithRouter.component'
import ICommonProps from '../components/ICommonProps'
import TabsComponent from '../components/Tabs.component'
import RadioSearchFormComponent from '../components/RadioSearchForm.component'
import { QueryResult } from '../lib/models'
import { convertToCsv } from '../lib/csv'
import ReportingResultsComponent from '../components/ReportingResults.component'
import styled from 'styled-components'
import SearchByFilterComponent from '../components/SearchByFilter.component'
import ReportingResultsMap from '../components/ReportingResultsMap.component'
import FilterByDateRangeCriteria from '../components//filterCriteria/FilterByDateRangeCriteria.component'
import FilterByDaysCriteria from '../components//filterCriteria/FilterByDaysCriteria.component'
import FilterByHoursCriteria from '../components//filterCriteria/FilterByHoursCriteria.component'
import FilterByAliasCriteria from '../components//filterCriteria/FilterByAliasCriteria.component'
import FilterByRadioIDCriteria from '../components//filterCriteria/FilterByRadioIDCriteria.component'
import FilterByEmailCriteria from '../components//filterCriteria/FilterByEmailCriteria.component'
import FilterByPathCriteria from '../components//filterCriteria/FilterByPathCriteria.component'
import FilterByIPCriteria from '../components//filterCriteria/FilterByIPCriteria.component'
import FilterByHTTPMethodCriteria from '../components//filterCriteria/FilterByHTTPMethodCriteria.component'
import FilterByHTTPStatusCriteria from '../components//filterCriteria/FilterByHTTPStatusCriteria.component'
import { adminApi } from '../lib/api'
import { hasPlatformAdmin } from '../lib/accessControl'
import { prettifyReportValues } from '../lib/utils'
// import { RouterProps } from 'react-router-dom'

// type PropsType = RouterProps & ICommonProps

interface IState {
  activeIndex: number
  queryResult?: QueryResult
  resultTabIndex: number
}

const HiddenLink = styled.a`
  display: none;
`

const StyledButton = styled.button`
  margin-left: 24px !important;
`

const MapContainer = styled.div`
  width: 100%;
  height: calc(100vh - 24px);
`

const Link = styled.a`
  text-decoration: underline;
`

const fieldIgnoreMatrix = [
  [],
  ['event_type', 'address'],
  [
    'event_type',
    'modified',
    'timestamp',
    'created',
    'emergency',
    'address',
    'active'
  ],
  []
]

class ReportingPage extends React.Component<ICommonProps, IState> {
  state: IState = {
    activeIndex: 0,
    resultTabIndex: 0
  }

  handleTabChange = (activeIndex: number) => {
    const newState: any = { activeIndex, queryResult: undefined }
    if (activeIndex === 0) {
      newState.resultTabIndex = 0
    }
    this.setState(newState)
  }

  handleResultTabIndex = (resultTabIndex: number) =>
    this.setState({ resultTabIndex })

  handleResults = (queryResult?: QueryResult) => {
    let { resultTabIndex } = this.state
    if (queryResult && queryResult.values.length > 1000) {
      resultTabIndex = 0
    }
    this.setState({ queryResult, resultTabIndex })
  }

  generateCsv = () => {
    if (!this.state.queryResult) return ''
    return convertToCsv(this.state.queryResult, prettifyReportValues)
  }

  linkRef = createRef<HTMLAnchorElement>()

  handleExport = () => {
    const link = this.linkRef.current
    if (!link) return
    const csv = this.generateCsv()
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
    link.click()
  }

  renderExportLink = () => {
    if (!this.state.queryResult || this.state.queryResult.values.length === 0)
      return <></>

    return (
      <>
        <StyledButton
          className="ui labeled icon button"
          onClick={this.handleExport}
        >
          <i className="ui icon download"></i>
          Export as CSV
        </StyledButton>
        <HiddenLink
          download={`RPP Export ${new Date().toLocaleDateString()}.csv`}
          ref={this.linkRef}
        />
      </>
    )
  }

  renderForms = () => {
    switch (this.state.activeIndex) {
      case 0:
        return (
          <RadioSearchFormComponent
            {...this.props}
            onResults={this.handleResults}
          />
        )
      case 1:
        // Position history
        return (
          <SearchByFilterComponent
            {...this.props}
            key={1}
            filters={[
              FilterByAliasCriteria,
              FilterByRadioIDCriteria,
              FilterByHoursCriteria,
              FilterByDaysCriteria,
              FilterByDateRangeCriteria
            ]}
            filterNames={[
              'Radio by Alias',
              'Radio by ID',
              'Last few hours',
              'Last few days',
              'Custom date range'
            ]}
            onSearch={adminApi.reporting.queryEvents}
            onResults={this.handleResults}
          />
        )
      case 2:
        // Duress history
        return (
          <SearchByFilterComponent
            {...this.props}
            key={2}
            filters={[
              FilterByAliasCriteria,
              FilterByRadioIDCriteria,
              FilterByHoursCriteria,
              FilterByDaysCriteria,
              FilterByDateRangeCriteria
            ]}
            filterNames={[
              'Radio by Alias',
              'Radio by ID',
              'Last few hours',
              'Last few days',
              'Custom date range'
            ]}
            onSearch={adminApi.reporting.queryDuresses}
            onResults={this.handleResults}
          />
        )
      case 3:
        // Application logs
        if (!hasPlatformAdmin(this.props.user)) return <>Forbidden</>
        return (
          <SearchByFilterComponent
            {...this.props}
            filters={[
              FilterByHoursCriteria,
              FilterByDaysCriteria,
              FilterByDateRangeCriteria,
              FilterByEmailCriteria,
              FilterByIPCriteria,
              FilterByPathCriteria,
              FilterByHTTPMethodCriteria,
              FilterByHTTPStatusCriteria
            ]}
            filterNames={[
              'Last few hours',
              'Last few days',
              'Custom date range',
              'Email address',
              'IP address',
              'Request path',
              'HTTP method',
              'HTTP response status'
            ]}
            onSearch={adminApi.reporting.queryAppLogs}
            onResults={this.handleResults}
          />
        )
      default:
        return <>Unknown tab</>
    }
  }

  renderResults = () => {
    if (!this.state.queryResult) return <></>
    if (this.state.resultTabIndex === 0) {
      return (
        <ReportingResultsComponent
          queryResult={this.state.queryResult}
          fieldsToIgnore={fieldIgnoreMatrix[this.state.activeIndex]}
        />
      )
    }
    const showHistoryPaths = this.state.resultTabIndex === 1
    return (
      <MapContainer>
        <ReportingResultsMap
          queryResult={this.state.queryResult}
          showHistoryPaths={showHistoryPaths}
        />
      </MapContainer>
    )
  }

  renderWarning = () => {
    if (
      this.state.queryResult &&
      this.state.queryResult.values &&
      this.state.queryResult.values.length > 1000
    ) {
      return (
        <div className="ui message blue">
          <p>
            Only the first 1000 records will be shown in the table and the map
            is not available.
          </p>
          <p>To view all the records, please export as CSV.</p>
          <p>
            Consider revising your search criteria to reduce the number of
            records.
          </p>
          <p>
            To visualise large amounts of data on a map, please contact ESA
            Spatial Services{' '}
            <Link href="mailto:esamapping@act.gov.au">
              esamapping@act.gov.au
            </Link>
            .
          </p>
        </div>
      )
    }
    return <></>
  }

  renderResultsTabs = () => {
    if (!this.state.queryResult) return <></>
    const tabs = ['Table']
    if (
      ![0, 3].includes(this.state.activeIndex) &&
      this.state.queryResult &&
      this.state.queryResult.values.length < 1000
    ) {
      tabs.push('Map')
    }
    return (
      <div className="ui secondary pointing menu">
        <div className="header item">
          <h3>
            {this.state.queryResult.values.length} Result
            {this.state.queryResult.values.length === 1 ? '' : 's'}{' '}
            {this.renderExportLink()}
          </h3>
        </div>
        <TabsComponent
          tabClass="right menu"
          tabs={tabs}
          onChange={this.handleResultTabIndex}
          activeIndex={this.state.resultTabIndex}
        />
      </div>
    )
  }

  render() {
    const tabs = ['Radios', 'Position history', 'Duress history']
    if (hasPlatformAdmin(this.props.user)) tabs.push('Application logs')
    return (
      <>
        <TabsComponent
          tabClass="ui secondary pointing massive menu"
          tabs={tabs}
          onChange={this.handleTabChange}
          activeIndex={this.state.activeIndex}
        />
        {this.renderForms()}
        {this.renderResultsTabs()}
        {this.renderWarning()}
        {this.renderResults()}
      </>
    )
  }
}

export default ReportingPage
