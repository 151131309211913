import React from 'react'
import { Radio } from '../../lib/models'
import { doesAgencyHaveRadio } from '../../lib/utils'
import { radiosToQueryResult } from './utilities'
import SearchButton from '../SearchButton.component'
import { ISearchForm } from '../ISearchForm'

class RadioWithNoAgency extends ISearchForm<{}, {}> {
  isInAgency = (radio: Radio) => {
    for (const agency of this.props.agencies) {
      if (doesAgencyHaveRadio(agency, radio)) return true
    }
    return false
  }
  handleSearch = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    const filteredRadios = this.props.radios.filter(x => !this.isInAgency(x))
    this.props.onResults(
      radiosToQueryResult(this.props.agencies, filteredRadios)
    )
  }
  render() {
    return (
      <form className="ui form" onSubmit={this.handleSearch}>
        <p>Find radios which are not included in an agency range</p>
        <SearchButton enable={true} />
      </form>
    )
  }
}

export default RadioWithNoAgency
