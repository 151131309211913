import React from 'react'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import { Radio } from '../../lib/models'
import RadioAliasInput from '../RadioAliasInput.component'
import { createParameter } from '../../lib/api'

interface IState {
  value?: Radio
}

class FilterByAliasCriteria extends IQueryBuilderComponent<{}, IState> {
  state: IState = {}

  handleChange = (value?: Radio) => {
    this.setState({ value })
  }

  getParameters() {
    return [createParameter('radio', this.state.value?.radio || '_')]
  }

  getType() {
    return 'radio'
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>Radio Alias</h4>
        </div>
        <div className="eight wide column">
          <RadioAliasInput
            onChange={this.handleChange}
            radios={this.props.radios}
          />
        </div>
      </>
    )
  }
}

export default FilterByAliasCriteria
