import React, { useState } from 'react'
import {
  hasAnyAdminAccess,
  hasPlatformAdmin,
  hasAnyOperationsAccess
} from '../lib/accessControl'
import styled from 'styled-components'
import SearchComponent from './Search.component'
import ICommonProps from './ICommonProps'
import {
  NavLink as Link, useLocation,
} from 'react-router-dom'
import agencyLogos from '../lib/agencyLogos.json'
import { UserInfo, UserAccessLevel } from '../lib/models'
// import { withRouter } from './WithRouter.component'

const HeaderLogo = styled.img`
  max-height: 38px;
`

const UserInfoDiv = styled.div`
  color: #666;
  padding: 24px 24px 0 24px;
  font-size: 14px;
`

const Centered = styled.div`
  text-align: center;
`

const PaddedDiv = styled.div`
  margin-bottom: 12px;
`

const Smaller = styled.span`
  font-size: 13px;
`

const BigIcon = styled.i`
  font-size: 48px !important;
  color: #aaa;
`

const AgencyItem = styled.div`
  margin-bottom: 4px;
  height: 22px;
`

const getHeadingColour = (user: UserInfo) => {
  if (hasPlatformAdmin(user)) return 'orange'
  if (hasAnyAdminAccess(user)) return 'grey'
  return 'blue'
}

type IProps = // RouterProps &
  ICommonProps & {
    onPasswordChange: () => void
    onSignout: () => void
    onUserDetails: () => void
  }

function HeaderComponent(props: IProps) {
    const [showMenu, setShowMenu] = useState(false)

    const location = useLocation();

    const handleToggleMenu = () => setShowMenu(!showMenu)
    const handleBlur = () => setShowMenu(false)

    const handleSignOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        props.onSignout()
    }

    const handlePasswordChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        props.onPasswordChange()
    }

    const handleUserDetails = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        props.onUserDetails()
    }

    const showSearch = () => {
        const pathname = location.pathname || ''
        if (pathname.startsWith('/reporting')) return false
        if (pathname.startsWith('/help')) return false
        return true
    }

    const renderAccessLevelLabel = (accessLevel: UserAccessLevel) => {
        if (props.user.platformAdmin) return <></>
        
        switch (accessLevel.accessLevel) {
            case 'agency-admins':
                return <span className="ui label red tiny">ADMIN</span>
            case 'ops-users':
                return <span className="ui label blue tiny">OPERATIONS</span>
            case 'map-users':
                return <span className="ui label green tiny">READONLY</span>
            default:
                return <></>
        }
    }

    const renderAccessLevel = (accessLevel: UserAccessLevel) => {
        const agency = props.agencies.find(x => x.agency === accessLevel.agency)
        if (!agency) return <></>

        const isPrimary = props.user.primaryAgency === accessLevel.agency

        return (
            <AgencyItem>
                {renderAccessLevelLabel(accessLevel)} {agency.name}{' '}
                {isPrimary && <span className="ui label black tiny">PRIMARY</span>}
            </AgencyItem>
        )
    }

    const renderAccessLevelBadge = () => {
        if (props.user.platformAdmin) {
            return (
                <span className="ui label orange">
                <i className="ui icon shield" /> PLATFORM ADMIN
                </span>
            )
        }
        if (hasAnyAdminAccess(props.user)) {
            return (
                <span className="ui label red">
                <i className="ui icon shield" /> AGENCY ADMIN
                </span>
            )
        }
        if (hasAnyOperationsAccess(props.user)) {
            return (
                <span className="ui label blue">
                <i className="ui icon shield" /> OPERATIONS
                </span>
            )
        }
        return (
            <span className="ui label blue">
                <i className="ui icon shield" /> MAPS
            </span>
        )
    }

    const renderAccessLevels = () => {
        let primaryAgency = props.user.accessLevels.find(x => x.agency === props.user.primaryAgency)
        if (!primaryAgency) {
            primaryAgency = {
                agency: props.user.primaryAgency,
                accessLevel: 'map-users'
            }
        }

        return (
            <UserInfoDiv>
                <Centered>
                    <PaddedDiv>
                        <BigIcon className="ui icon user" />
                    </PaddedDiv>
                    <PaddedDiv>{renderAccessLevelBadge()}</PaddedDiv>
                    <PaddedDiv>
                        <Smaller>{props.user.email}</Smaller>
                    </PaddedDiv>
                </Centered>
                {renderAccessLevel(primaryAgency)}
                {props.user.accessLevels
                    .filter(x => x.agency !== props.user.primaryAgency)
                    .map(renderAccessLevel)}
            </UserInfoDiv>
        )
    }

    const renderMenu = () => {
        if (!showMenu) return <></>
        return (
        <div className="ui menu transition visible">
            {renderAccessLevels()}
            <div className="item" onClick={handleUserDetails}>
                <i className="user icon"></i>
                User Details
            </div>
            <div className="item" onClick={handlePasswordChange}>
                <i className="lock icon"></i>
                Change password
            </div>
            <div className="item" onClick={handleSignOut}>
                <i className="sign out alternate icon"></i>
                Sign out
            </div>
        </div>
        )
    }

    const renderMobileHeader = () => {
        const colour = getHeadingColour(props.user)
        return (
        <>
            <div
                className={`ui secondary huge inverted ${colour} menu`}
                style={{ marginTop: 0, marginBottom: 0 }}
            >
            <Link className="item" to="/" style={{ marginLeft: 17 }}>
                Home
            </Link>
            <Link className="item" to="/administration">
                Admin
            </Link>
            <Link className="item" to="/help/">
                Help
            </Link>
            <div className="right icon menu">
                <div
                    className="ui dropdown item visible"
                    onClick={handleToggleMenu}
                >
                    <i className="user icon"></i>
                    <i className="dropdown icon"></i>
                    {renderMenu()}
                </div>
            </div>
            </div>

            <div
                tabIndex={0}
                className={`ui secondary huge inverted ${colour} one item menu`}
                style={{ marginBottom: 0, marginTop: 0 }}
                onBlur={handleBlur}
            >
                {showSearch() && (
                    <div className="item">
                        <SearchComponent {...props} />
                    </div>
                )}
            </div>
        </>
        )
    }

    const renderDesktopHeader = () => {
        const colour = getHeadingColour(props.user)
        let agency = props.user.primaryAgency || 'default'
        if (!agencyLogos.includes(`${agency}.png`)) agency = 'default'

        let username = props.user.email
        if (username.indexOf('@') !== -1) username = username.split('@')[0]
        return (
        <div
            className={`ui secondary huge inverted ${colour} menu`}
            style={{ marginBottom: 0, marginTop: 0 }}
        >
            <div className="header item">
                <Link to="/">
                    <HeaderLogo
                    className="ui"
                    src={`/agency/${agency}.png`}
                    alt={`${agency} logo`}
                    />
                </Link>
            </div>
            <div className="header item" style={{ width: 250 }}>
                <Link to="/">Radio Positioning Platform</Link>
            </div>
            {hasAnyOperationsAccess(props.user) && (
                <Link className="item" to="/" style={{ marginLeft: 15 }}>
                    Home
                </Link>
            )}
            {hasAnyAdminAccess(props.user) && (
                <Link className="item" to="/administration">
                    Administration
                </Link>
            )}
            {hasAnyOperationsAccess(props.user) && (
                <Link className="item" to="/reporting">
                    Reporting
                </Link>
            )}
            <Link className="item" to="/help/">
            Help
            </Link>
            {showSearch() && (
                <div className="item" style={{ flex: '1', minWidth: 130 }}>
                    <SearchComponent {...props} />
                </div>
            )}
            <div className="right icon menu">
                <div
                    tabIndex={0}
                    className="ui dropdown item visible"
                    onClick={handleToggleMenu}
                    onBlur={handleBlur}
                >
                    <span title={props.user.email}>
                        <i className="user icon"></i>
                        {username}
                    </span>
                    <i className="dropdown icon"></i>
                    {renderMenu()}
                </div>
            </div>
        </div>
        )
    }

  
    return props.isMobile
        ? renderMobileHeader()
        : renderDesktopHeader()
  
}

export default HeaderComponent
