import React from 'react'
import styled from 'styled-components'
import { authApi } from '../lib/api'
import { MfaCodeRequest } from '../lib/models'

const PaddedField = styled.div`
  padding-top: 12px;
  padding-top: 12px;
`

const PaddedLabel = styled.label`
  padding-bottom: 12px;
  font-size: 18px !important;
  text-align: left;
`

const StyledIconContainer = styled.div`
  font-size: 100px;
  padding-top: 24px;
  color: #2185d0;
`

const StyledH1 = styled.h1`
  margin-top: 0 !important;
`

interface IProps {
  challenge: any
  onResponse: (response: any) => void
}

interface IState {
  mfaCode: string
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export class MfaCodeComponent extends React.Component<IProps, IState> {
  state: IState = {
    mfaCode: '',
    loading: false,
    hasError: false,
    errorMessage: ''
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const request: MfaCodeRequest = {
      challenge_name: this.props.challenge.message,
      session: this.props.challenge.details.session,
      challenge_response: {
        SMS_MFA_CODE: this.state.mfaCode,
        USERNAME: this.props.challenge.details.USER_ID_FOR_SRP
      }
    }

    authApi
      .challengeResponse(request)
      .then(this.handleSuccess)
      .catch(this.handleFailure)

    this.setState({ loading: true })
  }

  handleSuccess = (authResponse: any) => {
    this.props.onResponse(authResponse)
    this.setState({ loading: false, mfaCode: '' })
  }

  handleFailure = (err: any) => {
    console.error(err)
    const { message } = err
    this.setState({
      loading: false,
      mfaCode: '',
      hasError: true,
      errorMessage: message
    })
  }

  render() {
    return (
      <>
        <StyledIconContainer>
          <i className="ui icon user circle" />
        </StyledIconContainer>
        <StyledH1>Please Enter MFA Code</StyledH1>
        <form className="ui form">
          <div className="ui segments">
            <div
              className={`ui segment ${this.state.hasError ? 'red' : 'blue'}`}
            >
              {this.state.hasError && (
                <div className="ui message visible error">
                  {this.state.errorMessage}
                </div>
              )}

              <PaddedField className="field">
                <PaddedLabel>
                  Code Sent to{' '}
                  {this.props.challenge?.details?.CODE_DELIVERY_DESTINATION}
                </PaddedLabel>
                <div className="ui left icon input">
                  <i className="lock icon"></i>
                  <input
                    type="text"
                    placeholder="Code..."
                    value={this.state.mfaCode}
                    onChange={e => this.setState({ mfaCode: e.target.value })}
                  />
                </div>
              </PaddedField>
            </div>
            <div className="ui segment secondary">
              <button
                disabled={!this.state.mfaCode}
                className={`ui fluid large secondary button ${
                  this.state.loading ? 'loading' : ''
                }`}
                onClick={this.handleSubmit}
              >
                Submit Code
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default MfaCodeComponent
