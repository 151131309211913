import React from 'react'
import { Radio } from '../../../lib/models'
import { ICriteriaComponent } from '../utilities'

class RadioRangeCriteria extends ICriteriaComponent<
  {},
  { lowerValue: string; upperValue: string }
> {
  state = {
    lowerValue: '',
    upperValue: ''
  }

  handleChange = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {} as any
    newState[key] = e.target.value
    this.setState(newState)
  }

  filter(radio: Radio) {
    const values = [this.state.lowerValue, this.state.upperValue].map(x =>
      parseInt(x)
    )
    const id = parseInt(radio.radio)
    return id >= values[0] && id <= values[1]
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>Radio ID Range</h4>
        </div>
        <div className="four wide column">
          <input
            type="number"
            value={this.state.lowerValue}
            onChange={this.handleChange.bind(null, 'lowerValue')}
            placeholder="lower value"
          />
        </div>
        <div className="four wide column">
          <input
            type="number"
            value={this.state.upperValue}
            onChange={this.handleChange.bind(null, 'upperValue')}
            placeholder="upper value"
          />
        </div>
      </>
    )
  }
}

export default RadioRangeCriteria
