import { QueryResult } from './models'

export const rowToCsvLine = (row: any[]) => {
  return row
    .map(x => (x || '').toString())
    .map(x => x.replace(/"/g, '""'))
    .map(x => (x.indexOf(',') >= 0 || x.indexOf('"') >= 0 ? `"${x}"` : x))
    .join(',')
}

export const rowsToCsv = (rows: any[][]) => {
  return rows.map(rowToCsvLine).join('\n')
}

const rowValueConverion = (
  value: string[],
  fields: string[],
  valueMapper: (value: string, field: string) => string
) => {
  return value.map((x, index) => {
    const field = fields[index]
    return valueMapper(x, field)
  })
}

export const convertToCsv = (
  queryResult: QueryResult,
  valueMapper: (value: string, field: string) => string
) => {
  return rowsToCsv([
    queryResult.fields,
    ...queryResult.values.map(row =>
      rowValueConverion(row, queryResult.fields, valueMapper)
    )
  ])
}
