
import { createSlice } from '@reduxjs/toolkit'

interface DuressControlState {
    duressLoading: boolean
}

const initialState: DuressControlState = {
    duressLoading: false,
}

export const duressControlSlice = createSlice({
    name: 'toastControl',
    initialState,
    reducers: {
        setDuressLoading: (state, action) => {
            state.duressLoading = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setDuressLoading } = duressControlSlice.actions

export default duressControlSlice.reducer
