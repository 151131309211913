import React, { useEffect, useRef } from 'react'
import SliderComponent from './Slider.component'
import PubSubSingleton from './PubSub.component'
import { Radio } from '../lib/models'
import { mapsApi } from '../lib/api'
import styled from 'styled-components'
import { useAppDispatch } from '../store/hooks'
import { setHistory, setShowHistory } from '../store/mapHistorySlice'

const Bold = styled.div`
  font-weight: bold;
`

interface IProps {
  radio: Radio
}

function HistoryRangeComponent(props: IProps) {
    const [value, setValue] = React.useState(1)
    const [loading, setLoading] = React.useState(false)
    const [active, setActive] = React.useState(false)
    const [noData, setNoData] = React.useState(false)
    
    const dispatch = useAppDispatch()
    
    function usePrevious(value: string) {
        const ref = useRef<string>();
        useEffect(() => {
            ref.current = value;
        },[value]);
        return ref.current;
    }

    const prevRadio = usePrevious(props.radio.radio)
    useEffect(() => {
        if (props.radio.radio !== prevRadio) {
            setActive(false)
            setLoading(false)
            setNoData(false)
        }
    }, [props.radio.radio, prevRadio])

    const handleChange = (value: number) => setValue(value)

    const handleShowHistory = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        setLoading(true)
        setNoData(false)
        setActive(false)

        try {
            const history = await mapsApi.getHistory(props.radio, value)
            if (history.length > 1) {
                // PubSubSingleton.getInstance().publish(messageTypes.showHistory, history)
                dispatch(setHistory(history))
                dispatch(setShowHistory(true))
                setLoading(false)
                setActive(true)
                return
            }
            if (history.length <= 1) {
                setNoData(true)
                setLoading(false)
                dispatch(setShowHistory(false))
            }
        } catch (_) {
            PubSubSingleton.getInstance().publishError('Unexpected error encountered loading radio history')
            setLoading(false)
        }
    }

    const handleClearHistory = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(false)
        setActive(false)
        dispatch(setHistory([]))
        dispatch(setShowHistory(false))
    }

    return (
        <>
            <Bold>Show location history from the current time</Bold>
            {noData && (
                <div className="ui message negative">
                    No positions were recorded in this period
                </div>
            )}
            <div className="ui padded grid">
                <div className="eight wide column">
                    <SliderComponent onChange={handleChange} value={value} min={1} max={12} />
                </div>
                <div className="eight wide column">
                    <button onClick={handleShowHistory} className={`ui fluid large button ${loading ? 'loading' : ''}`}>
                        {value} hour{value < 2 ? '' : 's'}
                    </button>
                </div>
            </div>
            {active && !loading && (
                <div>
                    <button onClick={handleClearHistory} className={`ui fluid large button labeled icon`}>
                        <i className="ui icon times"></i>
                        Clear history
                    </button>
                </div>
            )}
        </>
    )
}

export default HistoryRangeComponent
