import React from 'react'
import { Radio } from '../../../lib/models'
import { ICriteriaComponent } from '../utilities'
import MultiValueCriteria from '../../MultiValueCriteria.component'
import homeBases from '../../../lib/baseStations'

const values = homeBases.map(x => ({
  key: x,
  value: x
}))

class HomeBaseCriteria extends ICriteriaComponent<{}, { value: string }> {
  state = { value: homeBases[0] || '' }

  filter(radio: Radio) {
    return radio.static?.home_base === this.state.value
  }

  handleOnChange = (value: string) => this.setState({ value })

  render() {
    return (
      <MultiValueCriteria
        value={this.state.value}
        values={values}
        title="Home Base"
        onChange={this.handleOnChange}
      />
    )
  }
}

export default HomeBaseCriteria
