import React from 'react'
import { Radio } from '../lib/models'

interface IProps {
  radios: Radio[]
  onChange: (radio?: Radio) => void
}

interface IState {
  value: string
}

class RadioIdInput extends React.Component<IProps, IState> {
  state: IState = {
    value: ''
  }

  isRadioIdValid = () => {
    return this.props.radios.find(x => x.radio === this.state.value)
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({ value: e.target.value })
    const radio = this.props.radios.find(x => x.radio === e.target.value)
    this.props.onChange(radio)
  }

  render() {
    const isValid = this.isRadioIdValid()
    const icon = isValid
      ? 'ui green icon check circle'
      : 'ui red icon exclamation circle'
    return (
      <div className={`ui left icon fluid input ${isValid ? '' : 'error'}`}>
        <i className={icon}></i>
        <input
          type="text"
          placeholder="Radio ID..."
          value={this.state.value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export default RadioIdInput
