import React from 'react'

interface IProps {
  buttonClass?: string
  title: string
  icon?: string
  values: string[]
  onSelect: (value: string) => void
}

interface IState {
  active: boolean
}

class DropdownComponent extends React.Component<IProps, IState> {
  state: IState = {
    active: false
  }

  handleClick = () => {
    this.setState({ active: !this.state.active })
  }

  handleSelect = (item: string) => {
    this.setState({ active: false })
    this.props.onSelect(item)
  }

  handleBlur = () => this.setState({ active: false })

  render() {
    return (
      <div
        className={this.props.buttonClass || 'ui button dropdown'}
        onClick={this.handleClick}
        tabIndex={0}
        onBlur={this.handleBlur}
      >
        {this.props.icon && <i className={`${this.props.icon} icon`}></i>}
        {this.props.title}
        <i className="dropdown icon"></i>
        <div
          className={`menu transition ${
            this.state.active ? 'visible' : 'hidden'
          }`}
        >
          {this.props.values.map(x => (
            <div
              key={x}
              className="item"
              onClick={this.handleSelect.bind(null, x)}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
export default DropdownComponent
