import React, { useEffect, useState } from 'react'
// import { RouterProps } from 'react-router'
// import { withRouter } from '../components/WithRouter.component'
import { helpApi } from '../lib/api'
import { marked } from 'marked'
import LoadingComponent from '../components/Loading.component'
import PubSubSingleton, { messageTypes } from '../components/PubSub.component'
import { useNavigate, useParams } from 'react-router-dom'
// import PubSubComponent from '../components/PubSub.component'

declare global {
    interface Window {
        navigateTo: (href: string) => void
    }
}

window.navigateTo = (href: string) => {
    PubSubSingleton.getInstance().publish(messageTypes.navigateTo, { href })
}

// if the link links to another page in the site
// then intercept and raise an event
// this component then listens to the event,
// and navigates using the history api
// it's hacky, but it works
const renderLink = (href: string, title: string, text: string): string => {
    if (href.startsWith('#') || href.startsWith('http://') || href.startsWith('https://') || href.startsWith('mailto:')) {
        return `<a href="${href}" title="${title || ''}">${text}</a>`
    }

    return `<a href="javascript:void(0);" onclick="window.navigateTo('/help/${href}')" title="${
        title || ''
    }">${text}</a>`
}

const renderer = new marked.Renderer();
renderer.link = renderLink;

type IProps = { isMobile: boolean, page?: string }

function HelpPage({ isMobile }: IProps) {
    const [content, setContent] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [sidebarContent, setSidebarContent] = useState<string>('')

    const navigate = useNavigate();
    const { page } = useParams()

    helpApi.get('_sidebar').then(markdown => {
        setSidebarContent(marked.parse(markdown, {renderer}))
    });
    
    useEffect(() => {
        try {
            const _page = page || 'home'
            setLoading(true);
            setContent('');
            helpApi.get(_page).then(markdown => {
                setLoading(false);
                setContent(marked.parse(markdown, {renderer}));
            })
        } catch {
            setLoading(false);
            setContent('');
            PubSubSingleton.getInstance().publishError('Unexpected error when loading help content')
        }

        helpApi.get('_sidebar').then(markdown => {
            setSidebarContent(marked.parse(markdown, {renderer}))
        });
    }, [page])
    
    useEffect(() => {
        const clickHandler = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            const href = e.target.getAttribute('href').replace('#', '')

            if (href) {
                const target = document.getElementById(href)
                if (target !== undefined && target !== null) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: target.offsetTop,
                    })
                }
            }
        }

        const links = document.querySelectorAll('a[href^="#"]:not([href^="#/"])')
        links.forEach(link => {
            link.removeEventListener('click', clickHandler)
            link.addEventListener('click', clickHandler)
        })
    }, [content])

    PubSubSingleton.getInstance().subscribe(messageTypes.navigateTo, (_: any, message: { href: string }) => {
        navigate(message.href)
    })

    if (loading) {
        return <LoadingComponent />
    }

    if (isMobile) {
        return (
            <>
                <div className="ui top attached header">Pages</div>
                <div className="ui attached segment">
                    <div dangerouslySetInnerHTML={{ __html: sidebarContent }}></div>
                </div>

                <div className="ui segment">
                    <div
                        className="wiki-content"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </div>
            </>
        )
    } else {
        return (
            <div className="ui grid">
                <div className="ui column twelve wide">
                    <div className="ui segment">
                        <div
                        className="wiki-content"
                        dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                    </div>
                </div>
                <div className="ui column four wide">
                    <div className="ui segments">
                        <div className="ui segment">Pages</div>
                        <div className="ui segment">
                            <div dangerouslySetInnerHTML={{ __html: sidebarContent }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpPage
