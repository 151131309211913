
import { configureStore } from '@reduxjs/toolkit'
import { mapOptionsSlice } from './mapOptionsSlice'
import { mapHistorySlice } from './mapHistorySlice'
import { mapDrawingSlice } from './mapDrawingSlice'
import { toastControlSlice } from './toastControl'
import { duressControlSlice } from './duressControl'

const store = configureStore({
    reducer: {
        mapOptions: mapOptionsSlice.reducer,
        mapHistory: mapHistorySlice.reducer,
        mapDrawing: mapDrawingSlice.reducer,
        toastControl: toastControlSlice.reducer,
        duressControl: duressControlSlice.reducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
