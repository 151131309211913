import React from 'react'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import { createParameter } from '../../lib/api'

interface IState {
  value?: string
}

class FilterByPathCriteria extends IQueryBuilderComponent<{}, IState> {
  state: IState = {}

  handleChange = (value?: string) => {
    this.setState({ value })
  }

  getParameters() {
    return [createParameter('path', this.state.value || '')]
  }

  getType() {
    return 'path'
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>Request path</h4>
        </div>
        <div className="eight wide column">
          <div className="ui form">
            <div className="field">
              <input
                name="path"
                value={this.state.value}
                onChange={e => this.handleChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FilterByPathCriteria
