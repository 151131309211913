import { get, put, del, post } from './http'
import {
  Radio,
  Agency,
  PositionEvent,
  PasswordResetRequest as PasswordResetChallengeRequest,
  AuthRequest,
  AuthRefreshRequest,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ConfirmPasswordRequest,
  Duress,
  RadioEvent,
  UploadResult,
  QueryResult,
  QueryParameter,
  RadioDetails,
  AgencyDetails,
  VersionedAgency,
  AuthGetUserRequest,
  AuthGetUserResponse,
  AuthGetVerificationCode,
  AuthVerifyUserAttribute,
  AuthUpdateUser,
  AuthSetMfaPreference,
  MfaCodeRequest as MfaCodeChallengeRequest
} from './models'
import { toIsoDateString } from './utils'

const adminBaseUrl = process.env.REACT_APP_ADMIN_API_BASE || ''
const authBaseUrl = process.env.REACT_APP_AUTH_API_BASE || ''
export const mapBaseUrl = process.env.REACT_APP_MAP_API_BASE || ''

export const createParameter = (key: string, value: string) => ({ key, value })

export const toQueryString = (parameters: QueryParameter[]) => {
  return parameters
    .map(x => `${x.key}=${encodeURIComponent(x.value)}`)
    .join('&')
}

export const adminApi = {
  radios: {
    getAll: () => get<Radio[]>(adminBaseUrl, '/radios'),
    get: (id: string) => get<RadioDetails>(adminBaseUrl, `/radio/${id}`),
    put: (radio: Radio) =>
      put<Radio>(adminBaseUrl, `/radio/${radio.radio}`, {
        version: radio.version,
        static: radio.static
      })
  },
  agencies: {
    getAll: async () => {
      // agencies are returned as an object, so this function converts this into a list
      const agencyDictionary = await get<any>(adminBaseUrl, '/agencies')
      return Object.keys(agencyDictionary).map(x => {
        const agency = agencyDictionary[x] as Agency
        agency.agency = x
        return agency
      })
    },
    get: (id: string) => get<AgencyDetails>(adminBaseUrl, `/agency/${id}`),
    put: (agency: VersionedAgency) =>
      put<Agency>(adminBaseUrl, `/agency/${agency.agency}`, agency),
    del: (agency: Agency) =>
      del<Agency>(adminBaseUrl, `/agency/${agency.agency}`)
  },
  cam: {
    post: (data: Array<string[]>) =>
      post<UploadResult>(adminBaseUrl, '/cam', data)
  },
  reporting: {
    queryDuresses: (parameters: QueryParameter[]) =>
      get<QueryResult>(
        adminBaseUrl,
        `/reports/duress?${toQueryString(parameters)}`
      ),
    queryEvents: (parameters: QueryParameter[]) =>
      get<QueryResult>(
        adminBaseUrl,
        `/reports/events?${toQueryString(parameters)}`
      ),
    queryAppLogs: (parameters: QueryParameter[]) =>
      get<QueryResult>(
        adminBaseUrl,
        `/reports/applogs?${toQueryString(parameters)}`
      )
  }
}

export const mapsApi = {
  getAllRadios: () => get<Radio[]>(mapBaseUrl, '/radios'),
  getAllPositions: (start?: string) =>
    get<PositionEvent[]>(
      mapBaseUrl,
      start ? `/positions?start=${start}` : '/positions'
    ),

  getAllDuresses: () => get<Duress[]>(mapBaseUrl, '/duress'),

  getAcknowledgedDuresses: () =>
    get<Duress[]>(mapBaseUrl, '/duress/acknowledged'),

  acknowledgeDuress: (duressType: string, duress: Duress) =>
    post<Duress>(
      mapBaseUrl,
      `/duress/acknowledge/${duress.radio}/${duress.timestamp}`,
      { type: duressType }
    ),

  getHistory: (radio: Radio, hours: number) => {
    const startTimestamp = new Date().getTime() - hours * 1000 * 60 * 60
    const start = toIsoDateString(new Date(startTimestamp))
    return get<RadioEvent[]>(
      mapBaseUrl,
      `/events/${radio.radio}?start=${start}`
    )
  }
}

export const authApi = {
  login: (request: AuthRequest) => {
    window.localStorage.removeItem('token')
    return post<any>(authBaseUrl, '/auth', request)
  },

  challengeResponse: (
    request: PasswordResetChallengeRequest | MfaCodeChallengeRequest
  ) => {
    window.localStorage.removeItem('token')
    return post<any>(authBaseUrl, '/auth/challenge', request)
  },

  requestRefreshToken: (request: AuthRefreshRequest) => {
    window.localStorage.removeItem('token')
    return post<any>(authBaseUrl, '/auth', request)
  },

  changePassword: (request: ChangePasswordRequest) => {
    return post<ChangePasswordResponse>(
      authBaseUrl,
      '/auth/changepassword',
      request,
      true
    )
  },

  forgotPassword: (request: ForgotPasswordRequest) => {
    return post<ForgotPasswordResponse>(
      authBaseUrl,
      '/auth/forgotpassword',
      request
    )
  },

  confirmPassword: (request: ConfirmPasswordRequest) => {
    return post<ChangePasswordResponse>(
      authBaseUrl,
      '/auth/confirmpassword',
      request
    )
  },

  userDetails: (request: AuthGetUserRequest) => {
    return post<AuthGetUserResponse>(authBaseUrl, '/auth/user', request)
  },

  getVerificationCode: (request: AuthGetVerificationCode) => {
    return post<ForgotPasswordResponse>(
      authBaseUrl,
      '/auth/getverificationcode',
      request
    )
  },

  verifyUserAttribute: (request: AuthVerifyUserAttribute) => {
    return post<any>(authBaseUrl, '/auth/verifyuserattribute', request)
  },

  updateUser: (request: AuthUpdateUser) => {
    return post<ForgotPasswordResponse>(
      authBaseUrl,
      '/auth/updateuser',
      request
    )
  },

  updateMFAPreferences: (request: AuthSetMfaPreference) => {
    return post<any>(authBaseUrl, '/auth/setusermfa', request)
  }
}

const incidentFeedUrl = process.env.REACT_APP_INCIDENT_FEED_URL || ''
export const incidentApi = {
  get: () => get<any>(incidentFeedUrl, '')
}

export const helpApi = {
  get: (page: string) => get<string>('/help/', `${page}.md`)
}
