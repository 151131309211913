import React from 'react'

const LoadingComponent = () => (
  <div style={{ height: 100 }}>
    <div className="ui active inverted dimmer">
      <div className="ui text loader">Loading...</div>
    </div>
  </div>
)

export default LoadingComponent
