import React from 'react'
import UsernamePasswordComponent from '../components/UsernamePassword.component'
import styled from 'styled-components'
import PasswordResetComponent from '../components/PasswordReset.component'
import PubSubSingleton, { messageTypes } from '../components/PubSub.component'
import ForgottenPasswordComponent from '../components/ForgottenPassword.component'
import ForgottenPasswordCodeComponent from '../components/ForgottenPasswordCode.component'
import { ForgotPasswordResponse, ChangePasswordResponse } from '../lib/models'
import MfaCodeComponent from '../components/MfaCode.component'

const GridDiv = styled.div`
  background: whitesmoke;
  height: 100vh;
`

const FixedWidthColum = styled.div`
  max-width: 500px;
  margin-top: 100px;
`

export enum PageMode {
  Login,
  PasswordReset,
  PasswordResetCode,
  PasswordChallenge,
  MFAChallenge
}

type IState = {
  challenge?: any
  pageMode: PageMode
  username: string
  mailToAddress: string
}

class LoginPage extends React.Component<{}, IState> {
  state: IState = {
    pageMode: PageMode.Login,
    username: '',
    mailToAddress: ''
  }

  handleLoginResponse = (response: any) => {
    if (!response) return
    if (response.nextMode !== undefined) {
      this.setState({ pageMode: response.nextMode })
      return
    }

    if (response.error === 'CHALLENGE_REQUIRED') {
      if (response.message === 'SMS_MFA') {
        this.setState({ pageMode: PageMode.MFAChallenge, challenge: response })
        return
      }

      this.setState({
        pageMode: PageMode.PasswordChallenge,
        challenge: response
      })
      return
    }

    if (response.id_token) PubSubSingleton.getInstance().publish(messageTypes.authSuccess, response)
  }

  handleSwitchPage = (pageMode: PageMode) => this.setState({ pageMode })

  handleForgottenPasswordRequestSuccess = (
    username: string,
    response: ForgotPasswordResponse
  ) => {
    this.setState({
      username,
      mailToAddress: response.Destination,
      pageMode: PageMode.PasswordResetCode
    })
  }
  handleForgottenPasswordUpdated = (response: ChangePasswordResponse) => {
    PubSubSingleton.getInstance().publishSuccess(response.message)
    this.setState({ pageMode: PageMode.Login })
  }

  renderComponent() {
    switch (this.state.pageMode) {
      case PageMode.Login:
        return (
          <UsernamePasswordComponent
            onSwitchPage={this.handleSwitchPage}
            onSuccess={this.handleLoginResponse}
          />
        )

      case PageMode.PasswordChallenge:
        return (
          <PasswordResetComponent
            challenge={this.state?.challenge}
            onResponse={this.handleLoginResponse}
          />
        )
      case PageMode.MFAChallenge:
        return (
          <MfaCodeComponent
            challenge={this.state.challenge}
            onResponse={this.handleLoginResponse}
          />
        )

      case PageMode.PasswordReset:
        return (
          <ForgottenPasswordComponent
            onSwitchPage={this.handleSwitchPage}
            onSuccess={this.handleForgottenPasswordRequestSuccess}
          />
        )

      case PageMode.PasswordResetCode:
        return (
          <ForgottenPasswordCodeComponent
            mailToAddress={this.state.mailToAddress}
            username={this.state.username}
            onSwitchPage={this.handleSwitchPage}
            onSuccess={this.handleForgottenPasswordUpdated}
          />
        )
    }
  }

  render() {
    return (
      <GridDiv className="ui middle center aligned grid">
        <FixedWidthColum className="column">
          {this.renderComponent()}
        </FixedWidthColum>
      </GridDiv>
    )
  }
}

export default LoginPage
