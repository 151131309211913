import React from 'react'
import styled from 'styled-components'
import { authApi } from '../lib/api'
import { ConfirmPasswordRequest, ChangePasswordResponse } from '../lib/models'
import { PageMode } from '../pages/Login.page'

const PaddedField = styled.div`
  padding-top: 12px;
  padding-top: 12px;
`

const PaddedLabel = styled.label`
  padding-bottom: 12px;
  font-size: 18px !important;
  text-align: left;
`

const StyledIconContainer = styled.div`
  font-size: 100px;
  padding-top: 24px;
  color: #2185d0;
`

const StyledH1 = styled.h1`
  margin-top: 0 !important;
`

interface IProps {
  onSuccess: (response: ChangePasswordResponse) => void
  onSwitchPage: (nextPage: PageMode) => void
  mailToAddress: string
  username: string
}

interface IState {
  code: string
  password1: string
  password2: string
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export class ForgottenPasswordCodeComponent extends React.Component<
  IProps,
  IState
> {
  state: IState = {
    code: '',
    password1: '',
    password2: '',
    loading: false,
    hasError: false,
    errorMessage: ''
  }

  handleUpdate = (name: keyof IState, e: any) => {
    const newState = {} as any
    newState[name] = e.target.value
    this.setState(newState)
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { code, password1 } = this.state

    const request: ConfirmPasswordRequest = {
      confirmation_code: code,
      password: password1,
      username: this.props.username.toLowerCase()
    }

    authApi
      .confirmPassword(request)
      .then(this.handleSuccess)
      .catch(this.handleFailure)

    this.setState({ loading: true })
  }

  handleSuccess = (authResponse: ChangePasswordResponse) => {
    this.props.onSuccess(authResponse)
    this.setState({ loading: false, code: '' })
  }

  handleFailure = (err: any) => {
    const { message } = err
    this.setState({
      loading: false,
      hasError: true,
      errorMessage: message
    })
  }

  handleBackToLogin = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onSwitchPage(PageMode.Login)
  }

  render() {
    return (
      <>
        <StyledIconContainer>
          <i className="ui icon user circle" />
        </StyledIconContainer>
        <StyledH1>Password Reset</StyledH1>
        <form className="ui form">
          <div className="ui segments">
            <div
              className={`ui segment ${this.state.hasError ? 'red' : 'blue'}`}
            >
              {this.state.hasError && (
                <div className="ui message visible error">
                  {this.state.errorMessage}
                </div>
              )}

              <div className="ui message visible information">
                <p>
                  A reset code has been emailed to {this.props.mailToAddress}.
                </p>
                <p>Please enter this code to continue.</p>
              </div>

              <PaddedField className="field">
                <PaddedLabel>Enter code</PaddedLabel>
                <div className="ui left icon input">
                  <i className="keyboard icon"></i>
                  <input
                    type="text"
                    autoComplete="new-password"
                    placeholder="Enter code..."
                    value={this.state.code}
                    onChange={this.handleUpdate.bind(null, 'code')}
                  />
                </div>
              </PaddedField>

              <PaddedField className="field">
                <PaddedLabel>New Password</PaddedLabel>
                <div className="ui left icon input">
                  <i className="lock icon"></i>
                  <input
                    type="password"
                    autoComplete="new-password"
                    placeholder="Password..."
                    value={this.state.password1}
                    onChange={this.handleUpdate.bind(null, 'password1')}
                  />
                </div>
              </PaddedField>

              <PaddedField className="field">
                <PaddedLabel>Confirm New Password</PaddedLabel>
                <div className="ui left icon input">
                  <i className="lock icon"></i>
                  <input
                    type="password"
                    autoComplete="new-password"
                    placeholder="Confirm password..."
                    value={this.state.password2}
                    onChange={this.handleUpdate.bind(null, 'password2')}
                  />
                </div>
              </PaddedField>
            </div>
            <div className="ui segment secondary">
              <button
                disabled={
                  !(
                    this.state.code &&
                    this.state.password1 &&
                    this.state.password1 === this.state.password2
                  )
                }
                className={`ui fluid large secondary button ${
                  this.state.loading ? 'loading' : ''
                }`}
                onClick={this.handleSubmit}
              >
                Reset Password
              </button>
            </div>
          </div>
          <div>
            <button
              className="ui basic button"
              onClick={this.handleBackToLogin}
            >
              Return to the login screen
            </button>
          </div>
        </form>
      </>
    )
  }
}

export default ForgottenPasswordCodeComponent
