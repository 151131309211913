import React from 'react'
import utmLatLng from 'utm-latlng'
import styled from 'styled-components'
import { ILatLng } from '../lib/models'

const converter = new utmLatLng('WGS 84')

const Bold = styled.span`
  font-weight: bold;
`

const renderCoord = (coord: string[], index: number) => (
  <span key={index}>
    {coord[0]}
    <Bold>{coord[1]}</Bold>
    {coord[2]}{' '}
  </span>
)

const MgaComponent = (position: ILatLng, asText?: boolean) => {
  const result = converter.convertLatLngToUtm(
    position.latitude,
    position.longitude,
    0
  )

  const [northing, easting]: string[][] = [result.Northing, result.Easting]
    .map(x => Math.round(x).toString())
    .map((x: string) => x.padStart(7, ' ')) // normalise to a 7 digit number
    .map(x =>
      [x.substr(0, 2), x.substr(2, 3), x.substr(5, 2)].map(x => x.trim())
    )

  if (asText) {
    return [easting.join(''), northing.join(''), result.ZoneNumber].join(' ')
  }

  return (
    <>
      UTM-MGA{result.ZoneNumber}: {[easting, northing].map(renderCoord)}
    </>
  )
}
export default MgaComponent
