
import React, { useEffect, useState } from 'react'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import { Radio } from '../lib/models'
import { setShowDrawing, setSelectedRadios, setHighlightedRadios, setDrawnShapes } from '../store/mapDrawingSlice'
import { useAppDispatch } from '../store/hooks'
import { connect } from 'react-redux'
import { RootState } from '../store'

interface IProps {
    onDrawing: (isDrawing: boolean) => void
}

const mapStateToProps = (state: RootState) => state.mapDrawing

function MapDrawingToolsComponent(props: IProps & ReturnType<typeof mapStateToProps>) {
    // const [drawing, setDrawing] = useState(false)
    const [selectedRadioIds, setSelectedRadioIds] = useState<string[]>([])

    const dispatch = useAppDispatch()

    const handleDrawClick = () => dispatch(setShowDrawing(true))
    const handleDrawCancel = () => {
        props.drawnShapes.forEach(shape => {
            shape.setMap(null)
        })
        dispatch(setDrawnShapes([]))
        dispatch(setHighlightedRadios([]))
        dispatch(setShowDrawing(false))
    }

    const handleDrawComplete = () => {
        handleDrawCancel()
        dispatch(setSelectedRadios(props.highlightedRadios))
    }

    const renderDrawing = () => {
        return (
            <>
                <div className="ui segment">
                    Use the drawing toolbar at the top of the map to select radios. Press
                    Select to confirm your selection.
                </div>
                <div>
                    <p>
                        <button className="fluid ui button secondary large labeled icon" onClick={handleDrawComplete} disabled={props.highlightedRadios.length === 0}>
                            <i className="ui icon check"></i>
                            Select {props.highlightedRadios.length} radios
                        </button>
                    </p>
                    <p>
                        <button className="fluid ui button large labeled icon" onClick={handleDrawCancel}>
                            <i className="ui icon times"></i>
                            Cancel
                        </button>
                    </p>
                </div>
            </>
        )
    }

    useEffect(() => {
        // PubSubSingleton.getInstance().subscribe(messageTypes.startDrawing, () => {
        //     // setDrawing(true)
        //     props.onDrawing(true)
        // })
        // PubSubSingleton.getInstance().subscribe(messageTypes.stopDrawing, () => {
        //     // setDrawing(false)
        //     setSelectedRadioIds([])
        //     props.onDrawing(false)
        // })
        PubSubSingleton.getInstance().subscribe(messageTypes.radiosHighlightedOnMap, (_: any, radios: Radio[]) => {
            const _selectedRadioIds = Array.from(
                new Set<string>([
                    ...selectedRadioIds,
                    ...radios.map(x => x.radio)
                ])
            )
            setSelectedRadioIds(_selectedRadioIds)
        })
    }, [props, selectedRadioIds])

    if (props.showDrawing) return renderDrawing()
    return (
        <>
            <button className="fluid ui button large labeled icon secondary" onClick={handleDrawClick}>
                <i className="ui icon object ungroup outline"></i>
                Select by shape
            </button>
        </>
    )
}

export default connect(mapStateToProps)(MapDrawingToolsComponent)
