import React from 'react'
import { Radio } from '../lib/models'
import styled from 'styled-components'

const TopPadDiv = styled.div`
  padding-top: 4px;
`

const ClickableSpan = styled.span`
  cursor: pointer;
`

interface IProps {
  radios: Radio[]
  onChange: (radio?: Radio) => void
}

interface IState {
  value: string
}

class RadioAliasInput extends React.Component<IProps, IState> {
  state: IState = {
    value: ''
  }

  getMatchingRadios = () => {
    if (this.state.value.length < 1) return []
    const lowerValue = this.state.value.toLowerCase()

    const matches: Radio[] = []

    const exactMatch = this.props.radios.find(
      x => (x.cam?.alias || '').toLowerCase() === lowerValue
    )
    if (exactMatch) {
      matches.push(exactMatch)
    }

    return [
      ...matches,
      ...this.props.radios.filter(
        x =>
          (x.cam?.alias || '').toLocaleLowerCase().startsWith(lowerValue) &&
          (x.cam?.alias || '').toLocaleLowerCase() !== lowerValue
      )
    ]
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({ value: e.target.value }, () => {
      const radios = this.getMatchingRadios()
      const radio = radios.length === 1 ? radios[0] : undefined
      this.props.onChange(radio)
    })
  }

  handleClickAlias = (value: string) => this.setState({ value })

  renderMatches = (radios: Radio[]) => {
    if (this.state.value.length < 1) return <></>
    return (
      <TopPadDiv>
        {radios.length} match{radios.length === 1 ? ' ' : 'es '}
        {radios.slice(0, 10).map(x => (
          <ClickableSpan
            key={x.radio}
            className={`ui label ${
              (x.cam?.alias || '').toLowerCase() ===
              this.state.value.toLowerCase()
                ? 'blue'
                : ''
            }`}
            onClick={this.handleClickAlias.bind(null, x.cam?.alias || '')}
          >
            {x.cam?.alias}
          </ClickableSpan>
        ))}{' '}
        {radios.length > 10 ? '...' : ''}
      </TopPadDiv>
    )
  }

  render() {
    const radios = this.getMatchingRadios()
    const isValid = radios.length === 1
    const icon = isValid
      ? 'ui green icon check circle'
      : 'ui red icon exclamation circle'
    return (
      <>
        <div className={`ui left icon fluid input ${isValid ? '' : 'error'}`}>
          <i className={icon}></i>
          <input
            type="text"
            placeholder="Radio Alias..."
            value={this.state.value}
            onChange={this.handleChange}
          />
        </div>
        {this.renderMatches(radios)}
      </>
    )
  }
}

export default RadioAliasInput
