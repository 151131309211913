import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useJsApiLoader, GoogleMap, MarkerF, Libraries, TrafficLayer, MarkerProps, InfoWindowF, DrawingManager } from "@react-google-maps/api";

import ICommonProps from "./ICommonProps";
import { centerOfCanberra, getBounds, getOpacity, toLatLng } from "../lib/mapUtils";
import { capabilityList } from "../lib/capabilities";
import capabilityIconList from '../lib/capabilityIconList.json'
import { PositionEvent } from "../lib/models";
import { formatTimestamp, prettifyLatLng } from "../lib/utils";
import RadioInfoComponent from "./RadioInfo.component";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { connect } from "react-redux";
import { setSelectedDuressRadio, unsetViewButtonPressed } from "../store/mapHistorySlice";
import { setDrawnShapes, setExpandedRadioId, setHighlightedRadios, setSelectedRadios } from "../store/mapDrawingSlice";
import PubSubSingleton from "./PubSub.component";

interface MapCompenentProps extends ICommonProps {
    // mapOptions: MapOptions
}
/*

TODO:
- create active markers state?
- push to state on the fly
- let props updates handle drawing
*/

const mapStyles = {
    "on": [],
    "off": [
        {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
        }
    ],
};

const mapStateToProps = (state: RootState) => state

const mapLibraries: Libraries = ['core', 'maps', 'drawing']

function Map2Component(props: MapCompenentProps & ReturnType<typeof mapStateToProps>) {
    const [map, setMap] = React.useState<google.maps.Map | null>(null)
    const mapRef = React.useRef<GoogleMap>(null);

    const [esaPins, setEsaPins] = React.useState<MarkerProps[]>([]);

    const [infoWindowPosition, setInfoWindowPosition] = React.useState<google.maps.LatLng|null>(null);
    const [infoWindowVisible, setInfoWindowVisible] = React.useState<boolean>(false);
    const [infoWindowContent, setInfoWindowContent] = React.useState<JSX.Element|null>(null);

    const [selectedRadio, setSelectedRadio] = React.useState<string|null>(null);

    const [path, setPath] = React.useState<google.maps.Polyline>();
    const [pathCircles, setPathCircles] = React.useState<google.maps.Marker[]>([]);
    const [prevCoords, setPrevCoords] = React.useState<any[]>([]);

    const [showDrawing, setShowDrawing] = React.useState<boolean>(useAppSelector((state: RootState) => state.mapDrawing.showDrawing))
    const drawnShapes = useAppSelector((state: RootState) => state.mapDrawing.drawnShapes)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (props.mapOptions?.showEsaIncidents) {
            //Update esaPins with info from https://esa.act.gov.au/act-gov-esa/incidents/feed
            //Get JSON from https://esa.act.gov.au/act-gov-esa/incidents/feed
            //const data = fetch("https://esa.act.gov.au/act-gov-esa/incidents/feed"); //CORS!
            fetch("https://uat.esa.esatestsite.com/feeds/allincidents.json").then(async (data) => {
                const esaFeed: any = await data.json();
                setEsaPins(esaFeed.map((incident: any) => {
                    return {
                        position: {lat: +incident.latitude, lng: +incident.longitude},
                        title: incident.title,
                        icon: {
                            url: '/esa.svg',
                            size: new window.google.maps.Size(42, 42),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(21, 36),
                            scaledSize: new window.google.maps.Size(42, 42)
                        },
                        key: incident.id,
                    } as MarkerProps
                }));
            });
        }
    }, [props.mapOptions?.showEsaIncidents]);

    useEffect(() => {
        if (showDrawing !== props.mapDrawing.showDrawing) {
            setShowDrawing(props.mapDrawing.showDrawing)
        }
    }, [props.mapDrawing.showDrawing, showDrawing]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
        libraries: mapLibraries,
    })

    const getDuress = (radioId: string) => {
        // prioritise the active duress
        const duress = props.duresses.find(
            x => x.radio === radioId && x.active
        )
        if (duress) return duress
        return props.duresses.find(x => x.radio === radioId)
    }

    const getMarkers = (): any[] => {
        const duressIcon = {
            url: '/red-circle.svg',
            size: new window.google.maps.Size(120, 120),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(60, 60),
            scaledSize: new window.google.maps.Size(120, 120)
        }

        const standardIcon = {
            url: '/marker.svg',
            size: new window.google.maps.Size(42, 42),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(21, 36),
            scaledSize: new window.google.maps.Size(42, 42)
        }
        
        let _radios = props.radios

        const filtered_markers = _radios.filter(radio => {
            const duress = getDuress(radio.radio)
            const position = props.positions.get(radio.radio)?.position ?? duress?.location;
            const activeDuress = duress && duress.active

            if (activeDuress) {
                return true;
            }

            if (position === undefined) {
                return false;
            }

            const positionEvent = props.positions.get(radio.radio)
            const age = ((Date.now() / 1000) - positionEvent?.timestamp!!) / 3600

            if (props.mapOptions && props.mapOptions!!.minAge > 0) {
                if (age > props.mapOptions!!.maxAge || age < props.mapOptions!!.minAge) {
                    return false
                }
            }

            return true
        })
        
        let _markers = filtered_markers.map(radio => {
            const duress = getDuress(radio.radio)
            const position = props.positions.get(radio.radio)?.position ?? duress?.location;

            const activeDuress = duress && duress.active
            if (activeDuress) {
                return {
                    name: radio.radio,
                    position: {lat: position?.latitude, lng: position?.longitude},
                    icon: duressIcon,
                    clickable: false,
                    optimized: false,
                    zIndex: 100,
                }
            }

            let icon = standardIcon;
            if (radio.static?.capability) {
                const capability = capabilityList.find(c => c.name === radio.static?.capability)
                if (capability?.symbol && capabilityIconList.includes(`${capability.symbol}.svg`)) {
                    icon = {
                        url: `/capability/${capability.symbol}.svg`,
                        size: new window.google.maps.Size(42, 42),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(21, 36),
                        scaledSize: new window.google.maps.Size(42, 42)
                    }
                }
            }

            let opacity = 0
            const now = new Date().getTime() / 1000
            const positionEvent = props.positions.get(radio.radio)
            // const age = ((Date.now() / 1000) - positionEvent?.timestamp!!) / 3600
            if (positionEvent) {
                const age = Math.max(0, (now - positionEvent.timestamp) / (60 * 60))
                if (activeDuress || (age >= props.mapOptions!!.minAge && age <= props.mapOptions!!.maxAge)) {
                    opacity = getOpacity(positionEvent, now - props.mapOptions!!.minAge * 60 * 60)
                }
            }

            const highlighted = false; // this.highlightedRadioIds.find(x => x === radio.radio)
            const selected = !!(radio.radio === selectedRadio)

            return {
                position: {lat: position?.latitude, lng: position?.longitude},
                title: radio?.cam?.alias || radio.radio,
                icon: icon,
                onClick: () => setSelectedMarker(radio.radio),
                key: radio.radio,
                opacity: highlighted || selected ? 1 : opacity
            } as MarkerProps
        }).filter(m => m)

        if (props.mapDrawing.selectedRadios.length > 0) {
            const highlightedIcon = {
                url: '/highlighted.svg',
                size: new google.maps.Size(48, 48),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 37),
                scaledSize: new google.maps.Size(48, 48)
            }

            const highlighted_markers = filtered_markers.filter(m => props.mapDrawing.selectedRadios.includes(m.radio)).map(m => {
                const duress = getDuress(m.radio)
                const position = props.positions.get(m.radio)?.position ?? duress?.location;

                return {
                    position: {lat: position!!.latitude, lng: position!!.longitude},
                    icon: highlightedIcon,
                    clickable: false,
                    zIndex: 9
                }
            })

            _markers = [..._markers, ...highlighted_markers]
        }

        // console.log("MARKERS", _markers)
        return _markers
    }

    const setSelectedMarker = useCallback((radioId: string) => {
        // Only rerun this code if a different readio has been selected or the view button (on either duress or radio) has been pressed)
        if (radioId === '') {
            setSelectedRadio('')
            return;
        }
        if (selectedRadio !== radioId || props.mapHistory.viewButtonPressed) {
            console.log(props.mapDrawing.selectedRadios)

            setSelectedRadio(radioId)
            if (props.mapDrawing.selectedRadios.length === 0) {
                dispatch(setSelectedRadios([radioId]))
            }
            dispatch(unsetViewButtonPressed())
            dispatch(setExpandedRadioId(radioId))

            // if (props.mapHistory.viewButtonPressed && !props.mapDrawing.selectedRadios.includes(radioId)) {
            //     if (props.mapDrawing.selectedRadios.length === 1) {
            //         dispatch(setSelectedRadios([radioId]))
            //     } else {
            //         dispatch(setSelectedRadios([...props.mapDrawing.selectedRadios, radioId]))
            //     }
            // }
            
            const _radio = props.radios.find(r => r.radio === radioId)
            const _position = props.positions.get(radioId)?.position!!

            if (!_position) {
                PubSubSingleton.getInstance().publishWarning('Location not available')
                return
            }

            if (selectedRadio === radioId && infoWindowPosition !== null) {
                setInfoWindowVisible(false)
                setTimeout(() => {
                    setInfoWindowVisible(true)
                }, 100)
            } else {
                setInfoWindowVisible(true)
            }
            setInfoWindowPosition(new google.maps.LatLng({lat: _position.latitude, lng: _position.longitude}))
            setInfoWindowContent(<RadioInfoComponent
                radio={_radio!!}
                agencies={props.agencies}
                positions={props.positions}
            />)

            map?.setZoom(16)
            map?.panTo({lat: _position.latitude, lng: _position.longitude})

            // dispatch(setExpandedRadioId(radioId))
            // dispatch(setSelectedRadios([radioId]))
        }
    }, [selectedRadio, props.mapHistory.viewButtonPressed, props.mapDrawing.selectedRadios, props.radios, props.positions, props.agencies, dispatch, infoWindowPosition, map])

    useLayoutEffect(() => {
        // if (props.mapHistory.selectedDuressRadio) {
            setSelectedMarker(props.mapHistory.selectedDuressRadio ?? '')
        // }
    }, [props.mapHistory.selectedDuressRadio, setSelectedMarker])

    const onCloseInfoWindow = () => {
        setInfoWindowVisible(false)
        setInfoWindowContent(null)
        dispatch(setSelectedDuressRadio(null))
    }

    const onLoad = React.useCallback(function callback(_map: google.maps.Map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(centerOfCanberra)
        _map.setCenter(centerOfCanberra)
        _map.setZoom(12)
        
        setMap(_map)
    }, [])
    
    const onUnmount = React.useCallback(function callback(_: any) {
        setMap(null)
    }, [])

    // const handleShowHistory = (_: any, history: RadioEvent[]) => {
    useEffect(() => {
        if (!google.maps.Polyline) return;

        let _path = new google.maps.Polyline({
            geodesic: true,
            strokeColor: '#0000FF',
            strokeOpacity: 0.6,
            strokeWeight: 3,
            clickable: false
        })

        if (!props.mapHistory.showHistory) {
            if (pathCircles.length > 0) {
                pathCircles.forEach(x => x.setMap(null))
                setPathCircles([])
                path?.setMap(null)
                setSelectedMarker(selectedRadio!!)
                setPrevCoords([])
            }
            return
        }

        const coords = props.mapHistory.history.filter(x => x && x.location).map(x => x.location).map(toLatLng)
        
        if (coords.length === 0) {
            if (pathCircles.length > 0) {
                pathCircles.forEach(p => p.setMap(null))
                setPathCircles([])
                path?.setMap(null)
                setSelectedMarker(selectedRadio!!)
                setPrevCoords([])
            }
            return
        }

        if (JSON.stringify(coords) === JSON.stringify(prevCoords)) {
            return;
        }

        path?.setMap(null)
        _path.setPath(coords)
        _path.setMap(map)

        const _history = props.mapHistory.history.filter(x => x && x.location).map(position => new google.maps.Marker({
            position: toLatLng(position.location),
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 5,
                fillColor: '#0000FF',
                fillOpacity: 0.8,
                strokeOpacity: 0
            },
            draggable: false,
            clickable: true,
            map: map,
            title: `${formatTimestamp(position.timestamp)}\n${prettifyLatLng(position.location)}`
        }))

        pathCircles.forEach(x => x.setMap(null))
        setPathCircles(_history)
        setPrevCoords(coords)

        setPath(_path)

        map?.fitBounds(getBounds(coords.map(c => ({ latitude: c.lat, longitude: c.lng }))))
    }, [map, props.mapHistory, path, pathCircles, prevCoords, selectedRadio, setSelectedMarker])

    const onDrawingComplete = (e: google.maps.drawing.OverlayCompleteEvent) => {
        // setDrawnShapes([...drawnShapes, e.overlay])
        dispatch(setDrawnShapes([...drawnShapes, e.overlay]))
        const positions = Array.from(props.positions.values())

        let radios;
        switch (e.type) {
            case google.maps.drawing.OverlayType.POLYGON:
                radios = positions.filter((position: PositionEvent) =>
                    google.maps.geometry.poly.containsLocation(
                        new google.maps.LatLng(position.position.latitude, position.position.longitude),
                        e.overlay as google.maps.Polygon
                    )
                )
                break
            case google.maps.drawing.OverlayType.CIRCLE:
                radios = positions.filter((position: PositionEvent) => {
                    if (e.overlay == null) return false

                    const _overlay: google.maps.Circle = e.overlay as google.maps.Circle

                    return _overlay.getBounds()?.contains(
                        new google.maps.LatLng(position.position.latitude, position.position.longitude)
                    )
                    &&
                    google.maps.geometry.spherical.computeDistanceBetween(
                        _overlay.getCenter()!!,
                        new google.maps.LatLng(
                            position.position.latitude,
                            position.position.longitude
                        )
                    ) <= _overlay.getRadius()
                })
                break
            case google.maps.drawing.OverlayType.RECTANGLE:
            default:
                radios = positions.filter((position: PositionEvent) =>
                (e.overlay as google.maps.Rectangle).getBounds()?.contains(
                    new google.maps.LatLng(
                        position.position.latitude,
                        position.position.longitude
                    )
                ))
        }
        
        const matchingRadios = radios.map(position => props.radios.find(r => r.radio === position.radio)).filter(x => !!x).map(r => r?.radio)

        dispatch(setHighlightedRadios([...props.mapDrawing.highlightedRadios, ...matchingRadios]))
    }

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{width: "100%", height: "100%"}}
            center={centerOfCanberra}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
            ref={mapRef}
            options={{styles: mapStyles[props.mapOptions!!.showTransit ? "on" : "off"]}}>

            {showDrawing && <DrawingManager
                drawingMode={google.maps.drawing.OverlayType.CIRCLE}
                options={{
                    // drawingControl: props.mapDrawing.showDrawing,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            google.maps.drawing.OverlayType.CIRCLE,
                            google.maps.drawing.OverlayType.POLYGON,
                            google.maps.drawing.OverlayType.RECTANGLE,
                        ],
                    },
                }}
                onOverlayComplete={onDrawingComplete}
            />}
            
            {infoWindowVisible && infoWindowPosition &&
                <InfoWindowF position={infoWindowPosition} onCloseClick={onCloseInfoWindow} options={{pixelOffset: new google.maps.Size(0, -21)}}>
                    <div id="infowindowContent" style={{minWidth: "300px", maxWidth: "700px"}}>
                        {infoWindowContent}
                    </div>
                </InfoWindowF>
            }

            {getMarkers().map((marker: MarkerProps) => <MarkerF key={marker.title} {...marker} />)}

            {props.mapOptions?.showTraffic &&
                <TrafficLayer options={{autoRefresh: true}} />
            }

            {props.mapOptions?.showEsaIncidents &&
                esaPins.map((marker: MarkerProps) => <MarkerF key={marker.title} {...marker} />)
            }
        </GoogleMap>
    ) : <></>
}

export default connect(mapStateToProps)(Map2Component)
