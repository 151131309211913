import React from 'react'
import styled from 'styled-components'
import { authApi } from '../lib/api'
import { ForgotPasswordRequest, ForgotPasswordResponse } from '../lib/models'
import { PageMode } from '../pages/Login.page'

const PaddedField = styled.div`
  padding-top: 12px;
  padding-top: 12px;
`

const PaddedLabel = styled.label`
  padding-bottom: 12px;
  font-size: 18px !important;
  text-align: left;
`

const StyledIconContainer = styled.div`
  font-size: 100px;
  padding-top: 24px;
  color: #2185d0;
`

const StyledH1 = styled.h1`
  margin-top: 0 !important;
`

interface IProps {
  onSwitchPage: (nextPage: PageMode) => void
  onSuccess: (username: string, response: ForgotPasswordResponse) => void
}

interface IState {
  username: string
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export class ForgottenPasswordComponent extends React.Component<
  IProps,
  IState
> {
  state: IState = {
    username: '',
    loading: false,
    hasError: false,
    errorMessage: ''
  }

  handleUpdate = (name: keyof IState, e: any) => {
    const newState = {} as any
    newState[name] = e.target.value
    this.setState(newState)
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { username } = this.state

    const request: ForgotPasswordRequest = {
      username: username.toLowerCase()
    }

    authApi
      .forgotPassword(request)
      .then(this.handleSuccess)
      .catch(this.handleFailure)

    this.setState({ loading: true })
  }

  handleSuccess = (authResponse: ForgotPasswordResponse) => {
    this.props.onSuccess(this.state.username, authResponse)
    this.setState({ loading: false, username: '' })
  }

  handleFailure = (err: any) => {
    const { message } = err
    this.setState({
      loading: false,
      hasError: true,
      errorMessage: message
    })
  }

  handleBackToLogin = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onSwitchPage(PageMode.Login)
  }

  render() {
    return (
      <>
        <StyledIconContainer>
          <i className="ui icon user circle" />
        </StyledIconContainer>
        <StyledH1>Password Reset</StyledH1>
        <form className="ui form">
          <div className="ui segments">
            <div
              className={`ui segment ${this.state.hasError ? 'red' : 'blue'}`}
            >
              {this.state.hasError && (
                <div className="ui message visible error">
                  {this.state.errorMessage}
                </div>
              )}

              <PaddedField className="field">
                <PaddedLabel>Email address</PaddedLabel>
                <div className="ui left icon input">
                  <i className="user icon"></i>
                  <input
                    type="email"
                    placeholder="Email address..."
                    value={this.state.username}
                    onChange={this.handleUpdate.bind(null, 'username')}
                  />
                </div>
              </PaddedField>
            </div>
            <div className="ui segment secondary">
              <button
                disabled={!this.state.username}
                className={`ui fluid large secondary button ${
                  this.state.loading ? 'loading' : ''
                }`}
                onClick={this.handleSubmit}
              >
                Request a Reset Code
              </button>
            </div>
          </div>
          <div>
            <button
              className="ui basic button"
              onClick={this.handleBackToLogin}
            >
              Return to the login screen
            </button>
          </div>
        </form>
      </>
    )
  }
}

export default ForgottenPasswordComponent
