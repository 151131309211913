
import { createSlice } from '@reduxjs/toolkit'
import { RadioEvent } from '../lib/models'

interface MapHistoryState {
    showHistory: boolean,
    history: RadioEvent[],
    selectedDuressRadio: string|null,
    viewButtonPressed: boolean
}

const initialState: MapHistoryState = {
    showHistory: false,
    history: [],
    selectedDuressRadio: null,
    viewButtonPressed: false
}

export const mapHistorySlice = createSlice({
    name: 'mapHistory',
    initialState,
    reducers: {
        setShowHistory: (state, action) => {
            state.showHistory = action.payload
        },
        setHistory: (state, action) => {
            state.history = action.payload
        },
        setSelectedDuressRadio: (state, action) => {
            if (action.payload !== '' || action.payload !== null) {
                state.viewButtonPressed = true
            }
            state.selectedDuressRadio = action.payload
        },
        unsetViewButtonPressed: (state) => {
            state.viewButtonPressed = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowHistory, setHistory, setSelectedDuressRadio, unsetViewButtonPressed } = mapHistorySlice.actions

export default mapHistorySlice.reducer
