import React from 'react'
import { Radio, DuressGroup } from '../lib/models'
import ICommonProps from './ICommonProps'
import styled from 'styled-components'
import DuressComponent from './Duress.component'
import ExpandoIconComponent from './ExpandoIcon.component'

const RightFloatedDiv = styled.div`
    float: right;
    cursor: pointer;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
`

interface IProps extends ICommonProps {
    group: DuressGroup
    onRefresh: () => void
}

function DuressGroupComponent(props: IProps) {
    const [expanded, setExpanded] = React.useState<boolean>(false)

    let colour = 'red'
    let radio = props.radios.find(x => x.radio === props.group.radio)
    if (!radio) {
        colour = 'orange'
        radio = {
            radio: props.group.radio,
            cam: { alias: 'UNKNOWN' },
            static: {}
        } as Radio
    }

    return (
        <div className="ui segments">
            <div className={`ui segment ${props.group.active ? `inverted ${colour}` : '' }`}>
                <RightFloatedDiv onClick={() => setExpanded(!expanded)}>
                    <ExpandoIconComponent expanded={expanded} />
                </RightFloatedDiv>
                <Title>
                    <RightFloatedDiv>
                        <div className="ui label">{props.group.duresses.length}</div>
                    </RightFloatedDiv>
                    {radio?.cam?.alias || radio.radio}
                </Title>
                {expanded && props.group.duresses.map(x => (
                    <DuressComponent
                        duress={x}
                        key={x.radio + x.timestamp}
                        titleType="timestamp"
                        {...props}
                    />
                ))}
            </div>
        </div>
    )
}

export default DuressGroupComponent
