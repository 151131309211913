
import { createSlice } from '@reduxjs/toolkit'

export interface MapDrawingState {
    showDrawing: boolean,
    selectedRadios: string[],
    highlightedRadios: string[],
    drawnShapes: any[],
    expandedRadioId: string,
}

const initialState: MapDrawingState = {
    showDrawing: false,
    selectedRadios: [],
    highlightedRadios: [],
    drawnShapes: [],
    expandedRadioId: '',
}

export const mapDrawingSlice = createSlice({
    name: 'mapDrawing',
    initialState,
    reducers: {
        setShowDrawing: (state, action) => {
            state.showDrawing = action.payload
        },
        setSelectedRadios: (state, action) => {
            state.selectedRadios = action.payload
        },
        setHighlightedRadios: (state, action) => {
            state.highlightedRadios = action.payload
        },
        setDrawnShapes: (state, action) => {
            state.drawnShapes = action.payload
        },
        setExpandedRadioId: (state, action) => {
            state.expandedRadioId = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowDrawing, setSelectedRadios, setHighlightedRadios, setDrawnShapes, setExpandedRadioId } = mapDrawingSlice.actions

export default mapDrawingSlice.reducer
