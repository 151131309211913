
import { createSlice } from '@reduxjs/toolkit'
import { Message } from '../lib/models'

interface MapHistoryState {
    showToast: boolean
    toastMessages: Message[]
}

const initialState: MapHistoryState = {
    showToast: false,
    toastMessages: []
}

export const toastControlSlice = createSlice({
    name: 'toastControl',
    initialState,
    reducers: {
        setShowToast: (state, action) => {
            state.showToast = action.payload
        },
        addToastMessage: (state, action) => {
            state.toastMessages = [...state.toastMessages, action.payload]
        },
        removeToastMessage: (state, action) => {
            state.toastMessages = state.toastMessages.filter((msg) => msg.message !== action.payload.message)
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowToast, addToastMessage, removeToastMessage } = toastControlSlice.actions

export default toastControlSlice.reducer
