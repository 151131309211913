import React from 'react'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import { createParameter } from '../../lib/api'

interface IState {
  value: string
}

const methods = ['GET', 'POST', 'PUT', 'DELETE', 'OPTIONS']

class FilterByHTTPMethodCriteria extends IQueryBuilderComponent<{}, IState> {
  state: IState = {
    value: 'GET'
  }

  handleChange = (value: string) => {
    this.setState({ value })
  }

  getParameters() {
    return [createParameter('method', this.state.value || '')]
  }

  getType() {
    return 'method'
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>HTTP Method</h4>
        </div>
        <div className="eight wide column">
          <div className="ui form">
            <div className="inline fields">
              {methods.map(x => (
                <div className="field">
                  <div className="ui radio checkbox">
                    <input
                      type="radio"
                      name="method"
                      value={x}
                      checked={x === this.state.value}
                      onChange={e => this.handleChange(e.target.value)}
                    />
                    <label>{x}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FilterByHTTPMethodCriteria
