import { Capability } from './models'

export const capabilityList: Capability[] = [
  {
    name: 'Intensive Care Ambulance',
    symbol: 'aspic',
    description: ''
  },
  {
    name: 'Pumper',
    symbol: 'farspump',
    description: ''
  },
  {
    name: 'Paramedic Ambulance',
    symbol: 'aspic',
    description: ''
  },
  {
    name: 'Patient Transfer Ambulance',
    symbol: 'aspts',
    description: ''
  },
  {
    name: 'Heavy Rescue Pumper',
    symbol: 'farspump',
    description: ''
  },
  {
    name: 'SES Unit',
    symbol: 'sessesu',
    description: ''
  },
  {
    name: 'AS Duty Officer',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'FaRS Duty Officer',
    symbol: 'farsfirefighter',
    description: ''
  },
  {
    name: 'RFS Duty Officer',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'SES Duty Officer',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Single Response Unit',
    symbol: 'assru',
    description: ''
  },
  {
    name: 'Bushfire Heavy Tanker',
    symbol: 'rfsrfstkr',
    description: ''
  },
  {
    name: 'Aerial Appliance',
    symbol: 'farsbronto',
    description: ''
  },
  {
    name: 'AS Command Vehicle',
    symbol: 'assru',
    description: ''
  },
  {
    name: 'FaRS Command Vehicle',
    symbol: 'farsfrute',
    description: ''
  },
  {
    name: 'RFS Command Vehicle',
    symbol: 'rfsrfsocv',
    description: ''
  },
  {
    name: 'SES Command Vehicle',
    symbol: 'sessesocv',
    description: ''
  },
  {
    name: 'Special Needs Transport',
    symbol: 'aspts',
    description: ''
  },
  {
    name: 'Off-Road Rescue',
    symbol: 'fars4x4rar',
    description: ''
  },
  {
    name: 'Breathing Apparatus Support',
    symbol: 'farsba',
    description: ''
  },
  {
    name: 'Superintendent',
    symbol: 'farsfirefighter',
    description: ''
  },
  {
    name: 'Bushfire Light Tanker',
    symbol: 'rfsrfslu',
    description: ''
  },
  {
    name: 'Bushfire Medium Tanker',
    symbol: 'rfsrfsmu',
    description: ''
  },
  {
    name: 'Medical Helicopter',
    symbol: 'ashelo-24',
    description: ''
  },
  {
    name: 'Fire Helicopter',
    symbol: 'rfsaircft',
    description: ''
  },
  {
    name: 'Hazmat Appliance',
    symbol: 'farshaz-v',
    description: ''
  },
  {
    name: 'Utility Vehicle',
    symbol: 'farsfrdo',
    description: ''
  },
  {
    name: 'Community Fire Unit',
    symbol: 'farsfrcfu',
    description: ''
  },
  {
    name: 'Airport Firefighting Appliance',
    symbol: 'farsairtnd',
    description: ''
  },
  {
    name: 'Vertical Rescue',
    symbol: 'farsvr',
    description: ''
  },
  {
    name: 'RFS Brigade ',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Off-Road 4WD Ambulance',
    symbol: 'as4x4amb',
    description: ''
  },
  {
    name: 'Heavy Plant',
    symbol: 'rfsplant',
    description: ''
  },
  {
    name: 'Grader',
    symbol: 'rfsplant',
    description: ''
  },
  {
    name: 'Bulldozer',
    symbol: 'rfsplant',
    description: ''
  },
  {
    name: 'Fire Safety Officer / Vehicle',
    symbol: 'farsfsafe-41',
    description: ''
  },
  {
    name: 'Bulk Water Carrier',
    symbol: 'farsfsafe-41-43',
    description: ''
  },
  {
    name: 'Boat',
    symbol: 'sesboat-47',
    description: ''
  },
  {
    name: 'Trailer',
    symbol: 'sestrailr-49',
    description: ''
  },
  {
    name: 'POD',
    symbol: 'farspod',
    description: ''
  },
  {
    name: 'Fire Tower',
    symbol: 'tower',
    description: ''
  },
  {
    name: 'Rivers RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Hall RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Molonglo RFS portable',
    symbol: 'rfsradio',
    description: ''
  },
  {
    name: 'Gungahlin RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Jerrabomberra RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Guises Creek RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Southern RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Tidbinbilla RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'RAFT RFS portable',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Majura Firing Range',
    symbol: 'adf_radio',
    description: ''
  },
  {
    name: 'Air Ops RFS portable',
    symbol: 'rfsaircft',
    description: ''
  },
  {
    name: 'RFS HQ portable',
    symbol: 'rfsradio',
    description: ''
  },
  {
    name: 'NSWRFS Lake George Zone',
    symbol: 'nsw_rfs',
    description: ''
  },
  {
    name: 'NSWRFS Southern Tablelands',
    symbol: 'nsw_rfs',
    description: ''
  },
  {
    name: 'NSW NPWS',
    symbol: 'nsw_npws',
    description: ''
  },
  {
    name: 'NSWRFS Cooma',
    symbol: 'nsw_rfs',
    description: ''
  },
  {
    name: 'NSWRFS Riverina',
    symbol: 'nsw_rfs',
    description: ''
  },
  {
    name: 'ACTRFS Logistics & Operations',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'Training',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'Liaison',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'RFS Staff',
    symbol: 'rfsfirefighter',
    description: ''
  },
  {
    name: 'SES Staff',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Risk & Planning Staff',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ESA IMT',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ESA Staff',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ESA Exec. staff',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ACTS Chief',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'ACTAS HQ Staff',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'ACTAS Supervisor',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Ambulance Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Aranda ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'ACTAS Admin',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Calwell ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Charnwood ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Dickson ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'ACTAS Duty Manager',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'ACTAS Education',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Fyshwick ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Greenway ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'HQ ACTAS Single Officer',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Non Emerg. Transport',
    symbol: 'aspts',
    description: ''
  },
  {
    name: 'ACTAS Ops Supervisor',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'ACTAS Ops',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'PACER ACTAS',
    symbol: 'assru',
    description: ''
  },
  {
    name: 'ACTAS Spare',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'St John AMB',
    symbol: 'acthealthicon',
    description: ''
  },
  {
    name: 'Calvary Hosp. port',
    symbol: 'acthealthicon',
    description: ''
  },
  {
    name: 'TCH port',
    symbol: 'acthealthicon',
    description: ''
  },
  {
    name: 'ACTAS Support',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Southcare',
    symbol: 'ashelo-24',
    description: ''
  },
  {
    name: 'Woden ACTAS Portable',
    symbol: 'paramedic',
    description: ''
  },
  {
    name: 'Parking Ops',
    symbol: 'actparkingopsicon',
    description: ''
  },
  {
    name: 'Health',
    symbol: 'acthealthicon',
    description: ''
  },
  {
    name: 'PCS',
    symbol: 'actparksradio',
    description: ''
  },
  {
    name: 'Courts',
    symbol: 'gavel_generic',
    description: ''
  },
  {
    name: 'BYJC',
    symbol: 'gavel_generic',
    description: ''
  },
  {
    name: 'AMC',
    symbol: 'gavel_generic',
    description: ''
  },
  {
    name: 'Arboretum',
    symbol: 'arboretumcmtedicon',
    description: ''
  },
  {
    name: 'Bus',
    symbol: 'actionicon',
    description: ''
  },
  {
    name: 'ACTION Rigid',
    symbol: 'actionicon',
    description: ''
  }, 
  {
    name: 'ACTION SNT',
    symbol: 'actionicon',
    description: ''
  },
  {
    name: 'ACTION Midi',
    symbol: 'actionicon',
    description: ''
  },
  {
    name: 'ACTION TAG',
    symbol: 'actionicon',
    description: ''
  },
  {
    name: 'ACTION Artic',
    symbol: 'actionicon',
    description: ''
  },
  {
    name: 'ACTION Belconnen Field Operations',
    symbol: 'actionfieldoperationshandheld',
    description: ''
  },
  {
    name: 'ACTION City Field Operations',
    symbol: 'actionfieldoperationshandheld',
    description: ''
  },
  {
    name: 'ACTION Woden Field Operations',
    symbol: 'actionfieldoperationshandheld',
    description: ''
  },
  {
    name: 'ACTION Tuggeranong Field Operations',
    symbol: 'actionfieldoperationshandheld',
    description: ''
  },
  {
    name: 'ACTION Field Operations North Manager',
    symbol: 'actionmanager',
    description: ''
  },
  {
    name: 'ACTION Field Operations South Manager',
    symbol: 'actionmanager',
    description: ''
  },
  {
    name: 'ACTION Transport Canberra Manager',
    symbol: 'actionmanager',
    description: ''
  },
  {
    name: 'ACTION Belconnen Field Operations Van',
    symbol: 'actionfieldoperationsvan',
    description: ''
  },
  {
    name: 'ACTION City Field Operations Van',
    symbol: 'actionfieldoperationsvan',
    description: ''
  },
  {
    name: 'ACTION Woden Field Operations Van',
    symbol: 'actionfieldoperationsvan',
    description: ''
  },
  {
    name: 'ACTION Tuggeranong Field Operations Van',
    symbol: 'actionfieldoperationsvan',
    description: ''
  },
  {
    name: 'ACTION Customer Service Assistant',
    symbol: 'actioncustomerserviceassistant',
    description: ''
  },
  {
    name: 'ACTION Tuggeranong Breakdown',
    symbol: 'actionbreakdowntruck',
    description: ''
  },
  {
    name: 'ACTION Belconnen Breakdown',
    symbol: 'actionbreakdowntruck',
    description: ''
  },
  {
    name: 'Media Team',
    symbol: 'esa_media',
    description: ''
  },
  {
    name: 'ICT',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'Workshops',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ESA Spare',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ESA Fleet',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'Belconnen SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Woden SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Tuggeranong SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'HQ SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'HQ Staff SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Gungahlin SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Rivers SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'Majura SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'SES Command',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'SES Ops',
    symbol: 'sesradio',
    description: ''
  },
  {
    name: 'SES Flood Boat',
    symbol: 'sesboat-47',
    description: ''
  },
  {
    name: 'SES Corp Spare',
    symbol: 'sesradio',
    description: ''
  },
  {
    name: 'SES IMT',
    symbol: 'sesradio',
    description: ''
  },
  {
    name: 'SES Spare',
    symbol: 'sesradio',
    description: ''
  },
  {
    name: 'Pialligo SES',
    symbol: 'sesvolunteer',
    description: ''
  },
  {
    name: 'TOSS Fire Rescue',
    symbol: 'farsfrdo',
    description: ''
  },
  {
    name: 'ESA ICT',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'PCS Ranger',
    symbol: 'actparksicon',
    description: ''
  },
  {
    name: 'PCS Fire Portable',
    symbol: 'actparksicon',
    description: ''
  },
  {
    name: 'Workshops mechanic',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'ACT Policing',
    symbol: 'afp_star',
    description: ''
  },
  {
    name: 'Helicopter',
    symbol: 'rfsaircft',
    description: ''
  },
  {
    name: 'Toll Heli Crew',
    symbol: 'ashelo-24',
    description: ''
  },
  {
    name: 'RFS Heli Crew',
    symbol: 'rfsaircft',
    description: ''
  },
  {
    name: 'Event Radio',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'Admin',
    symbol: 'esa_person',
    description: ''
  },
  {
    name: 'PCS Command Vehicle',
    symbol: 'actparksicon',
    description: ''
  },
  {
    name: 'PCS Duty Officer',
    symbol: 'actparksicon',
    description: ''
  }
].sort((a, b) => {
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1
  return 0
})
