import React from 'react'
import { UserInfo, Agency } from '../lib/models'
import {
  hasPlatformAdmin,
  hasAgencyAdminAccess,
  hasOpsAccess
} from '../lib/accessControl'

export const RequirePlatformAdminComponent: React.FunctionComponent<{
  user: UserInfo,
  children: any,
}> = props => {
  if (hasPlatformAdmin(props.user)) return <>{props.children}</>
  return <></>
}

export const RequireAgencyAdminComponent: React.FunctionComponent<{
  user: UserInfo
  agency: Agency,
  children: any,
}> = props => {
  if (hasAgencyAdminAccess(props.user, props.agency))
    return <>{props.children}</>
  return <></>
}

export const RequireOpsComponent: React.FunctionComponent<{
  user: UserInfo
  agency: Agency,
  children: any,
}> = props => {
  if (hasOpsAccess(props.user, props.agency)) return <>{props.children}</>
  return <></>
}
