import React from 'react'
import { ILatLng, PositionEvent } from './models'
import { renderToString } from 'react-dom/server'

declare var google: any

export const centerOfCanberra = { lat: -35.3082, lng: 149.1244 }
export const defaultZoomLevel = 12
export const closeInZoomLevel = 16

export const getBounds = (latLngs: ILatLng[]) => {
  return new google.maps.LatLngBounds(
    {
      lat: Math.max(...latLngs.map(x => x.latitude)),
      lng: Math.min(...latLngs.map(x => x.longitude))
    },
    {
      lat: Math.min(...latLngs.map(x => x.latitude)),
      lng: Math.max(...latLngs.map(x => x.longitude))
    }
  )
}

export const toLatLng = (position: ILatLng) => ({
  lat: position.latitude,
  lng: position.longitude
})

const minOpacity = 0.15
const halfLifeInHours = 2

export const getOpacity = (position?: PositionEvent, relativeTo?: number) => {
  if (!position) return 0
  if (!relativeTo) relativeTo = new Date().getTime() / 1000

  let positionAgeHours = Math.max(
    0,
    (relativeTo - position.timestamp) / (60 * 60)
  )

  // Take 10 minutes off the age as it could be that far behind anyway
  positionAgeHours = Math.max(0, positionAgeHours - 10 / 60)

  return (
    minOpacity +
    (1 - minOpacity) *
      Math.max(
        0,
        Math.min(1, Math.pow(0.5, positionAgeHours / halfLifeInHours))
      )
  )
}

function createButtonContainer(controlDiv: HTMLDivElement, title: string) {
  const controlUI = document.createElement('div')
  controlUI.style.backgroundColor = '#fff'
  controlUI.style.borderRadius = '2px'
  controlUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
  controlUI.style.cursor = 'pointer'
  controlUI.style.marginBottom = '20px'
  controlUI.style.marginRight = '10px'
  controlUI.style.textAlign = 'center'
  controlUI.style.width = '40px'
  controlUI.style.height = '40px'
  controlUI.title = title
  controlDiv.appendChild(controlUI)
  return controlUI
}

function createButtonContent(buttonContainer: HTMLDivElement, icon: string) {
  const controlText = document.createElement('i')
  controlText.className = `ui icon ${icon}`
  controlText.style.color = '#666'
  controlText.style.fontSize = '24px'
  controlText.style.marginLeft = '4px'
  controlText.style.marginTop = '8px'
  buttonContainer.appendChild(controlText)
}

export function addLocationControl(
  controlDiv: HTMLDivElement,
  onClick: () => void
) {
  const controlUI = createButtonContainer(
    controlDiv,
    'Click to centre the map on your location'
  )
  createButtonContent(controlUI, 'crosshairs')
  controlUI.addEventListener('click', onClick)
}

export function addHomeControl(
  controlDiv: HTMLDivElement,
  onClick: () => void
) {
  const controlUI = createButtonContainer(
    controlDiv,
    'Click to reset the map location'
  )
  controlUI.style.marginBottom = '10px'
  createButtonContent(controlUI, 'home')
  controlUI.addEventListener('click', onClick)
}

const legendColours = ['#84ca50', '#f07d02', '#e60e01', '#9e1213']

const Legend = () => (
  <>
    Fast
    <span style={{ marginLeft: 12, marginRight: 12 }}>
      {legendColours.map(x => (
        <span key={x} style={{ paddingLeft: 24, background: x, margin: 1 }}>
          {' '}
        </span>
      ))}
    </span>
    Slow
  </>
)

export function addLegendConrol() {
  const parentDiv = document.createElement('div')
  parentDiv.style.backgroundColor = '#fff'
  parentDiv.style.borderRadius = '2px'
  parentDiv.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
  parentDiv.style.padding = '12px'
  parentDiv.style.marginBottom = '12px'
  parentDiv.innerHTML = renderToString(<Legend />)
  return parentDiv
}

export const createPath = (map?: any, path?: any) => {
  const options: any = {
    geodesic: true,
    strokeColor: '#0000FF',
    strokeOpacity: 0.6,
    strokeWeight: 3,
    clickable: false
  }
  if (map) options.map = map
  if (path) options.path = path
  return new google.maps.Polyline(options)
}
