import React from 'react'
import styled from 'styled-components'

interface IProps {
  value: number
  min: number
  max: number
  onChange?: (value: number) => void
  onRelease?: (value: number) => void
  lower?: number
  upper?: number
  id?: string
  name?: string
}

interface IState {
  value: number
}

const StyledInput = styled.input`
  -webkit-appearance: none;
  height: 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    border: 0.5px solid #ccc;
    background: white;
    margin-top: -10px;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    border: 0.5px solid #ccc;
    background: white;
    margin-top: -10px;
  }

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: whitesmoke;
    border-radius: 4px;
    border: 0.5px solid #ccc;
  }
  &::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: whitesmoke;
    border-radius: 4px;
    border: 0.5px solid #ccc;
  }
  &::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: whitesmoke;
    border-radius: 4px;
    border: 0.5px solid #ccc;
  }
`

class SliderComponent extends React.Component<IProps, IState> {
  state: IState = {
    value: this.props.value
  }

  handleChange = (e: any) => {
    if (this.props.lower && this.props.lower > e.target.value) return
    if (this.props.upper && this.props.upper < e.target.value) return

    this.setState({ value: e.target.value })

    if (this.props.onChange) this.props.onChange(parseInt(e.target.value))
  }

  handleRelease = (e: any) => {
    if (this.props.onRelease)
      this.props.onRelease(parseInt(e.currentTarget.value))
  }

  render() {
    return (
      <StyledInput
        id={this.props.id}
        name={this.props.name}
        type="range"
        min={this.props.min}
        max={this.props.max}
        value={this.state.value}
        onChange={this.handleChange}
        onPointerUp={this.handleRelease}
        onKeyUp={this.handleRelease}
      />
    )
  }
}

export default SliderComponent
