import PubSub from 'pubsub-js'
import { messageTypes } from '../components/PubSub.component'

declare global {
  interface Window {
    locator: Locator
  }
}

class Locator {
  position?: GeolocationPosition
  started: boolean = false

  start = () => {
    if (this.started) return
    navigator.geolocation.watchPosition(this.handleNewPosition, console.error, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    })
    this.started = true
  }

  handleNewPosition = (position: GeolocationPosition) => {
    this.position = position
    PubSub.publish(messageTypes.newUserPosition.toString(), position)
  }

  getLastPosition = () => {
    return this.position
  }
}

if (!window.locator) {
  window.locator = new Locator()
}

export default window.locator
