import React from 'react'

const RangeComponent = (props: { range: number[] }) => (
  <span className="ui label">
    {props.range[0]}{' '}
    <i className="ui icon arrow right" style={{ marginLeft: 9 }}></i>
    {props.range[1]}
  </span>
)

export default RangeComponent
