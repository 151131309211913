import React, { createRef, useEffect, useState } from 'react'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import { Radio, RadioMetadata } from '../lib/models'
import styled from 'styled-components'
import MapDrawingTools from './MapDrawingTools.component'
import RadioInfoComponent from './RadioInfo.component'
import ICommonProps from './ICommonProps'
import { getAgencyForRadio, formatTimestamp } from '../lib/utils'
import { rowsToCsv } from '../lib/csv'
import ExpandoIconComponent from './ExpandoIcon.component'
import { RootState } from '../store'
import { connect } from 'react-redux'
import { setExpandedRadioId, setHighlightedRadios, setSelectedRadios } from '../store/mapDrawingSlice'
import { useAppDispatch } from '../store/hooks'
import { setHistory, setSelectedDuressRadio, setShowHistory } from '../store/mapHistorySlice'
// import { render } from 'react-dom'

const StyledHeading = styled.h1`
  display: inline-block;
  padding-right: 12px;
`

const PaddedDiv = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`

const ContainerDiv = styled.div`
  position: relative;
`

const TopRightDiv = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline-block;
  opacity: 0.33;
  font-size: 18px;
`

const HiddenLink = styled.a`
  display: none;
`

const RadioName = styled.span`
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
`

const BottomAlignedDiv = styled.div`
  text-align: center;
  padding: 12px;
`

const mapStateToProps = (state: RootState) => state

function RadioListComponent(props: ICommonProps & ReturnType<typeof mapStateToProps>) {
    // const [highlightedRadioIds, setHighlightedRadioIds] = useState<string[]>([])
    // const [expandedRadioId, setExpandedRadioId] = useState<string>('')
    const [selectedRadioId, setSelectedRadioId] = useState<string>('')

    const dispatch = useAppDispatch();
    const linkRef = createRef<HTMLAnchorElement>()

    const handleClearHighlighting = () => {
        setSelectedRadioId('')
        dispatch(setExpandedRadioId(''))
        dispatch(setHighlightedRadios([]))
        dispatch(setSelectedRadios([]))
        dispatch(setSelectedDuressRadio(null))
        dispatch(setHistory([]))
        dispatch(setShowHistory(false))
    }

    const handleShowRadio = (_: any, radio: Radio) => {
        dispatch(setExpandedRadioId(radio?.radio))
        setSelectedRadioId(radio?.radio)
    }

    PubSubSingleton.getInstance().subscribe(
        messageTypes.showRadio,
        handleShowRadio
    )

    const handleToggleRadioHighlighting = (radio: Radio, e: any) => {
        e.stopPropagation()
        e.preventDefault()

        let newRadioIds = [...props.mapDrawing.selectedRadios]

        if (newRadioIds.includes(radio.radio)) {
            // remove radio
            newRadioIds = newRadioIds.filter(x => x !== radio.radio)
            setSelectedRadioId('')
        } else {
            // add radio
            newRadioIds.push(radio.radio)
        }

        dispatch(setSelectedRadios(newRadioIds))
    }

    useEffect(() => {
        if (props.mapDrawing.expandedRadioId !== '') {
            setSelectedRadioId(props.mapDrawing.expandedRadioId)
        }
    }, [props.mapDrawing.expandedRadioId])

    //   const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
    const hasRadio = (radio: string) => {
        // console.log("hasRadio", radio, props.mapDrawing.selectedRadios, selectedRadioId)
        return props.mapDrawing.selectedRadios.includes(radio) || selectedRadioId === radio
    }

    const handleToggleRadioExpand = (radio: Radio) => {
        if (props.mapDrawing.expandedRadioId === radio.radio) {
            dispatch(setExpandedRadioId(''))
            return
        }
        dispatch(setExpandedRadioId(radio.radio))
    }

    const handleViewRadio = (radio: Radio) => dispatch(setSelectedDuressRadio(radio.radio))

    const generateCsv = () => {
        // find all highlighted radios
        const radios = props.radios.filter(r => hasRadio(r.radio))

        const metaKeys: (keyof RadioMetadata)[] = [
            'capability',
            'capacity',
            'home_base',
            'notes',
            'service_id',
            'type'
        ]

        // map all highlighted radios to include the radio ID and all metadata
        // escape quote and commas
        const lines = radios.map(radio => {
            const meta: RadioMetadata = radio.static || {}
            const metaValues = metaKeys.map(x =>
                (meta[x] === undefined ? '' : (meta[x] as any)).toString()
            )
            const agency = getAgencyForRadio(radio, props.agencies)
            const agencyAbbr = agency ? agency.abbr : ''
            const agencyName = agency ? agency.name : ''
            const pos = props.positions.get(radio.radio)
            const latitude = pos ? pos.position.latitude : ''
            const longitude = pos ? pos.position.longitude : ''
            const time = formatTimestamp(pos?.timestamp)

            return [
                radio.radio,
                radio.cam?.alias || '',
                agencyAbbr,
                agencyName,
                latitude,
                longitude,
                time,
                ...metaValues
            ] as string[]
        })

        const header = [
            'Radio',
            'Cam Alias',
            'Agency Code',
            'Agency Name',
            'Latitude',
            'Longitude',
            'Timestamp',
            ...metaKeys
        ]
        return rowsToCsv([header, ...lines])
    }

    const handleExport = () => {
        const link = linkRef.current
        if (!link) return
        const csv = generateCsv()
        link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
        link.click()
    }

    const renderRadioDetails = (radio: Radio) => {
        return (
            <RadioInfoComponent
                onView={() => handleViewRadio(radio)}
                showViewButton={true}
                agencies={props.agencies}
                positions={props.positions}
                radio={radio}
            />
        )
    }

    const renderRadio = (radio: Radio) => {
        const isExpanded = radio.radio === props.mapDrawing.expandedRadioId
        return (
            <ContainerDiv className="ui segment inverted blue" key={radio.radio}>
                <RadioName>
                    <div
                        title="Remove from selection"
                        style={{ display: 'inline' }}
                        onClick={e => handleToggleRadioHighlighting(radio, e)}
                    >
                        <i className="ui icon times"></i>
                    </div>
                    {!isExpanded && (radio?.cam?.alias || radio.radio)}
                </RadioName>
                <TopRightDiv>
                    <ExpandoIconComponent
                        expanded={isExpanded}
                        onClick={() => handleToggleRadioExpand(radio)}
                    />
                </TopRightDiv>
                {isExpanded && renderRadioDetails(radio)}
            </ContainerDiv>
        )
    }

    const renderCount = () => {
        if (props.mapDrawing.selectedRadios.length === 0) return <></>
        return <div className="ui label">{props.mapDrawing.selectedRadios.length}</div>
    }

    const renderAdditionalButtons = () => {
        if (props.mapDrawing.showDrawing) return <></>
        if (props.mapDrawing.selectedRadios.length === 0 && !selectedRadioId) return <></>
        return (
            <>
                <p>
                    <button className="fluid ui large labeled icon button" onClick={handleClearHighlighting}>
                        <i className="ui icon times"></i>
                        Clear selection
                    </button>
                </p>
                <p>
                    <button className="fluid ui large secondary labeled icon button" onClick={handleExport}>
                        <i className="ui icon download"></i>
                        Export as CSV
                    </button>
                    <HiddenLink download={`RPP Export ${new Date().toLocaleDateString()}.csv`} ref={linkRef}></HiddenLink>
                </p>
            </>
        )
    }

    return (
        <div>
            <div style={{ minHeight: props.isMobile ? '0px' : 'calc(100vh - 230px)' }}>
                <div>
                    <StyledHeading>Radios</StyledHeading> {renderCount()}
                </div>
                <PaddedDiv>
                    <MapDrawingTools onDrawing={() => {}} />
                </PaddedDiv>
                {renderAdditionalButtons()}
    
                {!props.mapDrawing.showDrawing && props.radios.filter(r => hasRadio(r.radio)).map(renderRadio)}
            </div>
            {!props.isMobile && (
                <BottomAlignedDiv>
                    <a href="https://esa.act.gov.au/">
                        <img src="/agency/ACTESA.png" alt="ACTESA logo" />
                    </a>
                    <br />
                    Provided by the
                    <br />
                    <a href="https://esa.act.gov.au">ACT Emergency Services Agency</a>
                </BottomAlignedDiv>
            )}
        </div>
    )
}

export default connect(mapStateToProps)(RadioListComponent)
