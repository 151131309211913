import PubSub from 'pubsub-js'
import { messageTypes } from '../components/PubSub.component'
import { ILatLng } from './models'

declare global {
  interface Window {
    googleMaps: any
  }
}

// wait for google maps to load, and then grab a reference to it
PubSub.subscribe(
  messageTypes.googleMapsLoaded.toString(),
  (_: any, googleMaps: any) => {
    window.googleMaps = googleMaps
  }
)

const geocode = (coords: ILatLng) => {
    return new Promise<string>((resolve, reject) => {
        let attempt = 0

        const geoCode = () => {
            if (!google.maps) {
                if (attempt > 10) {
                    reject('no google maps')
                    return
                }
                setTimeout(geoCode, 500)
                attempt += 1
                return
            }

            const { latitude, longitude } = coords

            const geocoder: any = new google.maps.Geocoder()
            geocoder.geocode(
                { location: { lat: latitude, lng: longitude } },
                (results: any[], status: any) => {
                    if (status !== 'OK' || !results[0]) {
                        reject()
                        return
                    }
                    resolve(results[0].formatted_address)
                }
            )
        }
        geoCode()
    })
}

export default geocode
