import React, { useState } from 'react'
// import styled from 'styled-components'
import { QueryResult, ILatLng } from '../lib/models'
import {
  createPath,
  centerOfCanberra,
  getBounds
} from '../lib/mapUtils'
import { groupBy, formatTimestamp, prettifyLatLng } from '../lib/utils'
import { GoogleMap, MarkerProps, MarkerF, Libraries, useJsApiLoader } from '@react-google-maps/api'

declare var google: any

interface IProps {
  queryResult: QueryResult
  showHistoryPaths: boolean
}

const mapLibraries: Libraries = ['core', 'maps', 'drawing']

function ReportingResultsMap({queryResult, showHistoryPaths}: IProps) {
    const mapRef = React.useRef<GoogleMap>(null);

    const [markers, setMarkers] = useState<any[]>([]);
    const [standardIcon, setStandardIcon] = useState<any>(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
        libraries: mapLibraries,
    })

    const clearMap = () => {
        markers.forEach(x => x.setMap(null))
        setMarkers([])
    }

    const toLatLng = (value: string[], latIndex: number, lngIndex: number) => {
        const lat = parseFloat(value[latIndex])
        const lng = parseFloat(value[lngIndex])
        return { lat, lng }
    }

    const toCoords = (value: string[], latIndex: number, lngIndex: number): ILatLng => {
        const latitude = parseFloat(value[latIndex])
        const longitude = parseFloat(value[lngIndex])
        return { latitude, longitude }
    }

    const getTimestamp = (value: string[], timestampIndex: number) => {
        if (timestampIndex >= 0) {
            return formatTimestamp(parseFloat(value[timestampIndex]))
        }
        return ''
    }

    const formatTitle = (value: string[], latIndex: number, lngIndex: number, timestampIndex: number) => {
        return [
            value[0], getTimestamp(value, timestampIndex), prettifyLatLng(toCoords(value, latIndex, lngIndex))
        ].join('\n')
    };

    const renderMarker = (map: google.maps.Map, value: string[], latIndex: number, lngIndex: number, timestampIndex: number) => {
        return new google.maps.Marker({
            position: toLatLng(value, latIndex, lngIndex),
            map: map,
            title: formatTitle(value, latIndex, lngIndex, timestampIndex),
            icon: standardIcon
        })
    }

    const renderPath = (
        map: google.maps.Map,
        values: string[][],
        latIndex: number,
        lngIndex: number,
        timestampIndex: number
    ) => {
        const returnValues: any[] = []
        if (values.length > 1) {
            const path = createPath(
                map,
                values.map(x => toLatLng(x, latIndex, lngIndex))
            )
            returnValues.push(path)

            values.map(value =>
                new google.maps.Marker({
                    position: toLatLng(value, latIndex, lngIndex),
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 5,
                        fillColor: '#0000FF',
                        fillOpacity: 0.8,
                        strokeOpacity: 0
                    },
                    draggable: false,
                    clickable: true,
                    map: map,
                    title: formatTitle(value, latIndex, lngIndex, timestampIndex)
                })
            ).forEach(x => returnValues.push(x))
        }

        const marker = renderMarker(
            map,
            values[0],
            latIndex,
            lngIndex,
            timestampIndex
        )
        returnValues.push(marker)

        return returnValues
    }

    const initMap = function callback(_map: google.maps.Map) {
        _map.setCenter(centerOfCanberra)
        _map.setZoom(12)
        
        setStandardIcon({
            url: '/marker.svg',
            size: new google.maps.Size(36, 36),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(18, 36),
            scaledSize: new google.maps.Size(36, 36)
        })

        clearMap()

        if (queryResult.values.length === 0) return

        const latIndex = queryResult.fields.indexOf('latitude')
        const lngIndex = queryResult.fields.indexOf('longitude')
        const timestampIndex = queryResult.fields.indexOf('timestamp')
        
        if (latIndex < 0 || lngIndex < 0) return

        if (showHistoryPaths) {
            const radioIndex = queryResult.fields.indexOf('radio')
            if (radioIndex < 0) return
            
            const groupedResults = groupBy(
                queryResult.values,
                x => x[radioIndex]
            )
            
            const newMarkers = Array.from(groupedResults.values()).map(group => renderPath(_map, group, latIndex, lngIndex, timestampIndex))
            setMarkers([...markers, ...newMarkers])
        } else {
            setMarkers(queryResult.values.map(x =>
                renderMarker(_map, x, latIndex, lngIndex, timestampIndex)
            ))
        }

        const coords = queryResult.values
            .map(x => toLatLng(x, latIndex, lngIndex))
            .map(x => ({ latitude: x.lat, longitude: x.lng }))

        _map.fitBounds(getBounds(coords))
    }

    if (!isLoaded) return <></>
    return (
        <GoogleMap
            mapContainerStyle={{width: "100%", height: "100%"}}
            center={centerOfCanberra}
            zoom={12}
            onLoad={initMap}
            ref={mapRef}
            options={{styles: [{
                featureType: "transit",
                elementType: "all",
                stylers: [{ visibility: "off" }],
            }]}}>

            {markers.map((marker: MarkerProps) => <MarkerF key={marker.title} {...marker} />)}
        </GoogleMap>
    )
}

export default ReportingResultsMap
