import React from 'react'
import { radiosToQueryResult } from './utilities'
import { Radio } from '../../lib/models'
import SearchButton from '../SearchButton.component'
import { ISearchForm } from '../ISearchForm'

class RadioWithNoAlias extends ISearchForm<{}, {}> {
  hasMissingAlias = (radio: Radio) => {
    if (!radio.cam) return true
    if (radio.cam.alias === undefined) return true
    if (radio.cam.alias.trim() === '') return true
    return false
  }
  handleSearch = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    const filteredRadios = this.props.radios.filter(this.hasMissingAlias)
    this.props.onResults(
      radiosToQueryResult(this.props.agencies, filteredRadios)
    )
  }
  render() {
    return (
      <form className="ui form" onSubmit={this.handleSearch}>
        <p>Find radios which do not have a CAM Alias</p>
        <SearchButton enable={true} />
      </form>
    )
  }
}

export default RadioWithNoAlias
