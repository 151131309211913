import { Agency, Radio, Duress, ILatLng } from './models'

export const doesAgencyHaveRadio = (agency: Agency, radio: Radio) => {
  if (!radio) return false
  if (!agency) return false
  const radioId = parseInt(radio.radio)
  for (let range of agency.ranges) {
    if (radioId >= range[0] && radioId <= range[1]) return true
  }
  return false
}

export const doesAgencyHaveDuress = (agency: Agency, duress: Duress) => {
  if (!duress) return false
  if (!agency) return false
  const radioId = parseInt(duress.radio)
  for (let range of agency.ranges) {
    if (radioId >= range[0] && radioId <= range[1]) return true
  }
  return false
}

export const getAgencyForRadio = (radio: Radio, agencies: Agency[]) => {
  return agencies.find(x => doesAgencyHaveRadio(x, radio))
}

export const toIsoDateString = (now: Date) => {
  return [
    now.getUTCFullYear().toString().padStart(4, '0'),
    (now.getUTCMonth() + 1).toString().padStart(2, '0'),
    now.getUTCDate().toString().padStart(2, '0'),
    'T',
    now.getUTCHours().toString().padStart(2, '0'),
    now.getUTCMinutes().toString().padStart(2, '0'),
    now.getUTCSeconds().toString().padStart(2, '0'),
    'Z'
  ].join('')
}

const timeFields = ['timestamp', 'created', 'source_timestamp', 'modified']

export const prettifyReportValues = (value: string, field: string) => {
  if (timeFields.includes(field)) {
    return formatTimestamp(parseFloat(value))
  }
  if (field === 'acknowledgements' && value) {
    const acks = JSON.parse(value) as string[][]
    return acks
      .map(ack => {
        const [type, user, time] = ack
        return [type, user, formatTimestamp(parseFloat(time))].join(', ')
      })
      .join('\n')
  }
  return value
}

export function groupBy<T>(list: T[], grouping: (value: T) => string) {
  const map = new Map<string, T[]>()
  list.forEach(item => {
    const key = grouping(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const prettifyLatLng = (coords: ILatLng) => {
  const { latitude, longitude } = coords

  const latDir = latitude > 0 ? 'N' : 'S'
  const lngDir = longitude < 0 ? 'W' : 'E'

  const values = [latitude, longitude].map(Math.abs).map(x => x.toFixed(5))

  return `${values[0]}°${latDir} ${values[1]}°${lngDir}`
}

export const formatTimestamp = (timestamp?: number) => {
  if (!timestamp) return ''
  return new Date(timestamp * 1000).toLocaleString()
}

export const isString = (value: any) =>
  typeof value === 'string' || value instanceof String

export const httpToHttps = (url: string | null): string => {
  if (!url) return ''

  if (url.startsWith('http:')) return 'https:' + url.substring(5)

  return url
}

export const titleCase = (value: string | null | undefined) => {
  if (!value) return ''

  const abbrs = ['id', 'ip', 'cidr', 'http', 'https']
  let title = value.replace(/_/g, ' ').toLowerCase().split(' ')

  return title
    .map(word => {
      if (abbrs.includes(word)) return word.toUpperCase()

      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const scrollToBottom = () =>
  window.scrollTo(0, document.body.scrollHeight)
