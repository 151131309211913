import React from 'react'
import { Radio } from '../../../lib/models'
import { ICriteriaComponent } from '../utilities'
import MultiValueCriteria from '../../MultiValueCriteria.component'
import { capabilityList } from '../../../lib/capabilities'

const values = capabilityList.map(x => ({
  key: x.name,
  value: x.name
}))

class CapabilityCriteria extends ICriteriaComponent<{}, { value: string }> {
  state = { value: capabilityList[0]?.name || '' }

  filter(radio: Radio) {
    return radio.static?.capability === this.state.value
  }

  handleOnChange = (value: string) => this.setState({ value })

  render() {
    return (
      <MultiValueCriteria
        value={this.state.value}
        values={values}
        title="Capability"
        onChange={this.handleOnChange}
      />
    )
  }
}

export default CapabilityCriteria
