import React, { useEffect, useState } from 'react'
import MapPage from './pages/Map.page'
import { Routes, Route, HashRouter } from 'react-router-dom'
import styled from 'styled-components'
import PubSub from 'pubsub-js'
import PubSubSingleton, { messageTypes } from './components/PubSub.component'
import isMobileFn from './lib/isMobile'
import { Duress, Radio, RadioEvent, PositionEvent, Agency, UserInfo } from './lib/models'
import { mapsApi, adminApi, authApi } from './lib/api'
import DuressAlarmComponent from './components/DuressAlarm.component'
import LoadingComponent from './components/Loading.component'
import EditAgencyPage from './pages/EditAgency.page'
import EditRadioPage from './pages/EditRadio.page'
import AdminWrapper from './pages/AdminWrapper'
import AdminPage from './pages/Admin.page'
import LoginPage from './pages/Login.page'
import UploadCamPage from './pages/UploadCam.page'
import jwtDecode from 'jwt-decode'
import ICommonProps from './components/ICommonProps'
import { parseToken } from './lib/accessControl'
import HeaderComponent from './components/Header.component'
import {
    doesAgencyHaveDuress,
    doesAgencyHaveRadio,
  toIsoDateString,
} from './lib/utils'
import ChangePasswordComponent from './components/PasswordChange.component'
import locator from './lib/locator'
import { getMapOptions, setMapOptions } from './lib/storage'
import ReportingPage from './pages/Reporting.page'
// import RefreshRedirector from './components/RefreshRedirector'
import UserDetailsComponent from './components/UserDetailsComponent'
import HelpPage from './pages/Help.page'
import { RootState } from './store'
import { connect } from 'react-redux'
import { useAppDispatch } from './store/hooks'
import { setDuressLoading } from './store/duressControl'
import ToastComponent from './components/Toast.component'

// import { useAppSelector, useAppDispatch } from './store/hooks'

const PaddedDiv = styled.div`
  margin: 20px;
`

const PageDiv = styled.div`
  position: relative;
  height: calc(100vh - 70px);
`

const MessageContainer = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 5000;
`

let isMobileWidth = isMobileFn()

const sortRadio = (a: Radio, b: Radio) => parseInt(a.radio) - parseInt(b.radio)

window.addEventListener('resize', () => {
  const currentSize = isMobileFn()
  if (currentSize !== isMobileWidth) {
    isMobileWidth = currentSize
    PubSub.publish(messageTypes.widthChanged.toString(), currentSize)
  }
})

const sortAgencies = (a: Agency, b: Agency) => {
  const nameA = a.agency
  const nameB = b.agency
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

// interface IState {
//   duresses: Duress[]
//   isMobile: boolean
//   radios: Radio[]
//   ready: boolean
//   positions: Map<string, PositionEvent>
//   agencies: Agency[]
//   authenticated: boolean
//   userInfo: UserInfo
//   showChangePassword: boolean
//   showUserDetails: boolean
//   mapOptions?: MapOptions
// }

const mapStateToProps = (state: RootState) => state

function App(props: ReturnType<typeof mapStateToProps>) {
    const [doOnce, setDoOnce] = useState<boolean>(false)
    const [duresses, setDuresses] = useState<Duress[]>([])
    const [isMobile, setIsMobile] = useState<boolean>(isMobileFn())
    const [radios, setRadios] = useState<Radio[]>([])
    const [ready, setReady] = useState<boolean>(false)
    const [positions, setPositions] = useState<Map<string, PositionEvent>>(new Map<string, PositionEvent>())
    const [agencies, setAgencies] = useState<Agency[]>([])
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState<UserInfo>({
        email: '',
        platformAdmin: false,
        accessLevels: [],
        groups: [],
        primaryAgency: ''
    })
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false)
    const [showUserDetails, setShowUserDetails] = useState<boolean>(false)

    const [loadCount, setLoadCount] = useState<number>(0)
    const [lastPoll, setLastPoll] = useState<number>(0)
    const [pollInterval, setPollInterval] = useState<ReturnType<typeof setInterval>>()
    const [initialised, setInitialised] = useState<boolean>(false)

    // const [toastMessages, setToastMessages] = useState<Message[]>([])

    const dispatch = useAppDispatch();
    
    const getCommonProps = (useFiltering: boolean): ICommonProps => {
        const filteredAgencies = props.mapOptions.hiddenAgencies || []
        let filteredRadios = radios.slice()
        let filteredDuresses = duresses.slice()
    
        if (useFiltering) {
            // remove agencies which have been filtered out by the user
            filteredAgencies.forEach(agencyCode => {
                const agency = agencies.find(x => x.agency === agencyCode)
                if (!agency) return
        
                const hasCorrectAccessLevel = userInfo.accessLevels
                    .filter(x => x.accessLevel === 'map-users')
                    .filter(x => x.agency !== userInfo.primaryAgency)
                    .find(x => x.agency === agencyCode)
        
                if (!hasCorrectAccessLevel) return
        
                filteredRadios = filteredRadios.filter(
                    radio => !doesAgencyHaveRadio(agency, radio)
                )
                filteredDuresses = filteredDuresses.filter(
                    duress => !doesAgencyHaveDuress(agency, duress)
                )
            })
        }
    
        return {
            isMobile: isMobile,
            duresses: filteredDuresses,
            radios: filteredRadios,
            positions: positions,
            agencies: agencies,
            user: userInfo,
            mapOptions: props.mapOptions,
        }
    }

    const handleSignout = () => {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refresh_token')
        window.localStorage.removeItem('access_token')
        window.location.href = '/'
    }
    
    const handlePasswordChange = () => setShowChangePassword(true)
    const handleClosePasswordChange = () => setShowChangePassword(false)
    
    const renderChangePassword = () => {
        if (!showChangePassword) return <></>
        return <ChangePasswordComponent onClose={handleClosePasswordChange} />
    }

    const handleAuthSuccess = (_: any, response: any) => {
        const token = jwtDecode(response.id_token) as any

        window.localStorage.setItem('token', response.id_token)
        if (response.refresh_token) {
            window.localStorage.setItem('refresh_token', response.refresh_token)
        }
        if (response.access_token) {
            window.localStorage.setItem('access_token', response.access_token)
        }
    
        // refresh the token 2 minutes before expiry
        const timeToRefresh = Math.max(
            0,
            token.exp * 1000 - new Date().getTime() - 2 * 60 * 1000
        )
        
        const userInfo = parseToken(token)
        setTimeout(() => handleRequestTokenRefresh(userInfo), timeToRefresh);
    
        if (response.primary_agency) {
            userInfo.primaryAgency = response.primary_agency
        }
        if (response.user_attributes) {
            let attrs = response.user_attributes
            if (attrs['custom:primary_agency']) {
                userInfo.primaryAgency = attrs['custom:primary_agency']
                window.localStorage.setItem('primary_agency', userInfo.primaryAgency)
            }
        }
        const mapOptions = getMapOptions(userInfo)
        setAuthenticated(true);
        setUserInfo(userInfo);
        setMapOptions(mapOptions);

        if (!initialised) {
            locator.start() // start tracking the user's location
            loadRadios()
            loadAgencies()
            loadPositions()
            clearInterval(pollInterval)
            setPollInterval(setInterval(loadPositions, 10 * 1000))
            setInitialised(true);
        }
    }
    
    // const handleUpdateMapOptions = (_: any, mapOptions: MapOptions) => setMapOptions(mapOptions)
    
    const loadRadios = () => {
        mapsApi
            .getAllRadios()
            .then(handleRadiosLoaded)
            .catch(handleError.bind(null, 'radio'))
        setLoadCount(loadCount + 1)
        setReady(loadCount === 0)
    }
    
    const loadAgencies = () => {
        adminApi.agencies
            .getAll()
            .then(handleAgenciesLoaded)
            .catch(handleError.bind(null, 'agency'))
        setLoadCount(loadCount + 1)
        setReady(loadCount === 0)
    }

    const handleRequestTokenRefresh = (userInfo: UserInfo) => {
        const refreshToken = window.localStorage.getItem('refresh_token')
        if (!refreshToken) return
    
        authApi.requestRefreshToken({
            refresh_token: refreshToken,
            username: userInfo.email
        }).then(response => {
            handleAuthSuccess(null, response)
        }).catch((e) => {
            handleSignout()
        })
    }

    const handleAgenciesLoaded = (data: Agency[]) => {
        setLoadCount(loadCount - 1)
        const agencies = data.sort(sortAgencies)
        setAgencies(agencies);
        setReady(loadCount === 0)
    }
    
    const loadPositions = async () => {
        try {
            const currentTime = new Date().getTime()
        
            // RA 20/08/2020: asking for all points since the last time window introduces a data leak
            // whereby 14% of the records are lost, the line below expands the time window to
            // 10 seconds before the last sync (20 seconds in total) which dramatically decreases the
            // data leak.
            mapsApi.getAllPositions(toIsoDateString(new Date(lastPoll - 10 * 1000))).then(newPositions => {
                setLastPoll(currentTime);
        
                for (let newPos of newPositions) {
                    setPositions(updatePosition(newPos))
                }
            })

            mapsApi.getAllDuresses().then(duresses => {
                setDuresses(duresses)
                // if (props.duressControl.duressLoading) {
                    dispatch(setDuressLoading(false))
                // }
            })
        
            // const [newPositions] = await Promise.all([
            //     newPositionsPromise,
            //     // newDuressPromise
            // ])
        
            // setLastPoll(currentTime);
        
            // for (let newPos of newPositions) {
            //     setPositions(updatePosition(newPos))
            // }
            // setDuresses(duresses)
        } catch (ex) {
            PubSubSingleton.getInstance().publishError(
                'An unexpected error was encountered when loading position data'
            )
        }
    }
    
    const updatePosition = (position: PositionEvent): Map<string, PositionEvent> => {
        const old = positions.get(position.radio)
        if (!old || old.timestamp < position.timestamp) {
            positions.set(position.radio, position)
        }
        return positions
    }
    
    const handleShowHistory = (_: any, history: RadioEvent[]) => {
        if (history.length > 0) {
            const last = history[history.length - 1]
            const event = {
                radio: last.radio,
                timestamp: last.timestamp,
                position: last.location,
                expiry: last.expiry
            } as PositionEvent
            const positions = updatePosition(event)
            setPositions(positions)
        }
    }
    
    const handleRadiosLoaded = (radios: Radio[]) => {
        radios = radios.sort(sortRadio)
        setLoadCount(loadCount - 1)
        setRadios(radios)
        setReady(loadCount === 0)
    }
    
    const handleError = (type: string) => {
        PubSubSingleton.getInstance().publishError(
            `An unexpected error was encountered when loading ${type} data`
        )
        setLoadCount(loadCount - 1)
        setReady(loadCount === 0)
    }

    useEffect(() => {
        // attempt to use a token stored in the browser
        if (window.localStorage.getItem('token')) {
            const tokenString = window.localStorage.getItem('token') || ''
            const token = jwtDecode(tokenString) as any
            if ((token.exp || 0) * 1000 > new Date().getTime()) {
                const primary: string = window.localStorage.getItem('primary_agency') ?? '';
        
                PubSubSingleton.getInstance().publish(messageTypes.authSuccess, {
                    id_token: tokenString,
                    refresh_token: window.localStorage.getItem('refresh_token'),
                    primary_agency: primary
                })
                return
            }
        }
        
        return () => {
            clearInterval(pollInterval)
        }
    }, [pollInterval])
    
    if (!doOnce) {
        PubSubSingleton.getInstance().subscribe(messageTypes.widthChanged, (_: any, isMobile: boolean) =>
            setIsMobile(isMobile)
        )
        PubSubSingleton.getInstance().subscribe(messageTypes.reloadAgencies, loadAgencies)
        PubSubSingleton.getInstance().subscribe(messageTypes.reloadRadios, loadRadios)
        PubSubSingleton.getInstance().subscribe(messageTypes.authSuccess, handleAuthSuccess)
        PubSubSingleton.getInstance().subscribe(messageTypes.reloadPositions, loadPositions)
        PubSubSingleton.getInstance().subscribe(messageTypes.showHistory, handleShowHistory)
        // PubSubSingleton.getInstance().subscribe(messageTypes.updatedMapOptions, handleUpdateMapOptions)
        setDoOnce(true);
    }

    if (!authenticated) {
        return <LoginPage />
    }
    if (!ready) {
        return <LoadingComponent />
    }
    return (
        <>
            <DuressAlarmComponent duresses={duresses} />
            {/* toast messages */}
            {props.toastControl.toastMessages.length > 0 && 
                <MessageContainer>
                    <div className="ui grid">
                        <div className="four wide column"></div>
                        <div className="eight wide column">
                            {props.toastControl.toastMessages.map((message, index) => <ToastComponent key={index} message={message} />)}
                        </div>
                        <div className="four wide column"></div>
                    </div>
                </MessageContainer>
            }
            {renderChangePassword()}
            {/* <RefreshRedirector /> */}
            {showUserDetails && (
                <UserDetailsComponent
                    title="User Details"
                    onClose={() => setShowUserDetails(false)}
                />
            )}
              
            <HashRouter>
                <HeaderComponent
                    {...getCommonProps(true)}
                    onPasswordChange={handlePasswordChange}
                    onSignout={handleSignout}
                    onUserDetails={() => setShowUserDetails(true)}
                />
                <Routes>
                    <Route path="/" element={
                    <PageDiv>
                        <MapPage {...getCommonProps(true)} onRefresh={() => loadPositions} />
                    </PageDiv>
                    } />
                    <Route path="/administration/newagency" element={
                    <PaddedDiv>
                        <AdminWrapper user={userInfo}>
                        <EditAgencyPage
                            isNew={true}
                            {...getCommonProps(false)}
                        />
                        </AdminWrapper>
                    </PaddedDiv>
                    } />
                    <Route path="/administration/agency/:agencyId" element={
                    <PaddedDiv>
                        <AdminWrapper user={userInfo}>
                        <EditAgencyPage
                            isNew={false}
                            {...getCommonProps(false)}
                        />
                        </AdminWrapper>
                    </PaddedDiv>
                    } />
                    <Route path="/administration/radio/:radioId" element={
                    <PaddedDiv>
                        <AdminWrapper user={userInfo}>
                        <EditRadioPage {...getCommonProps(false)} />
                        </AdminWrapper>
                    </PaddedDiv>
                    } />
                    <Route path="/administration/cam" element={
                    <PaddedDiv>
                        <AdminWrapper user={userInfo}>
                        <UploadCamPage {...getCommonProps(false)} />
                        </AdminWrapper>
                    </PaddedDiv>
                    } />
                    <Route path="/administration" element={
                    <PaddedDiv>
                        <AdminWrapper user={userInfo}>
                        <AdminPage {...getCommonProps(false)} />
                        </AdminWrapper>
                    </PaddedDiv>
                    } />
                    <Route path="/reporting" element={
                    <PaddedDiv>
                        <ReportingPage {...getCommonProps(false)} />
                    </PaddedDiv>
                    } />
                    <Route path="/help/:page?" element={
                    <PaddedDiv>
                        <HelpPage isMobile={isMobile} />
                    </PaddedDiv>
                    } />
                </Routes>
            </HashRouter>
        </>
    )
}

export default connect(mapStateToProps)(App)
