import React from 'react'

interface IProps {
  title: string
  buttons: string[]
  buttonIcons?: string[]
  onClick: (buttonName: string) => void
  isInvalid?: boolean
  loading?: boolean,
  children: any
}

function ConfirmationComponent(props: IProps) {
    return (
        <div
            className="ui dimmer modals page transition active"
            style={{ display: 'flex !important' }}
        >
            <div className="ui standard test modal transition visible active">
                <div className="header">{props.title}</div>
                <div className="content">{props.children}</div>
                <div className="actions">
                    {props.buttons.map((name, index) => (
                        <button
                            key={name}
                            className={`ui button large ${
                            props.loading && index === 0 ? 'loading' : ''
                            } ${index === 0 ? 'secondary' : ''}`}
                            disabled={index === 0 && props.isInvalid}
                            onClick={props.onClick.bind(null, name)}
                        >
                            {props.buttonIcons && props.buttonIcons[index] && (
                                <i className={`${props.buttonIcons[index]} icon`}></i>
                            )}
                            {name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ConfirmationComponent
