import React, { useEffect } from 'react'
import styled from 'styled-components'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import { Duress, DuressGroup } from '../lib/models'
import { groupBy } from '../lib/utils'
import DuressComponent from './Duress.component'
import DuressGroupComponent from './DuressGroup.component'
import ICommonProps from './ICommonProps'
import LoadingOverlay from './LoadingOverlay'
import { RootState } from '../store'
import { connect } from 'react-redux'

const StyledContainer = styled.div`
  padding: 12px;
`

const isOpen = (duress: Duress) => duress.acknowledgements.length === 0
const isClosed = (duress: Duress) => !isOpen(duress)

const sortDuress = (a: Duress, b: Duress) => b.timestamp - a.timestamp
const sortDuressGroup = (a: DuressGroup, b: DuressGroup) => b.latest - a.latest

const groupDuress = (duresses: Duress[]): DuressGroup[] => {
    const groupMap = groupBy(duresses, (x: Duress) => x.radio)

    return Array.from(groupMap.entries()).map(x => {
        const [radio, duresses] = x
        return {
            radio: radio,
            latest: Math.max(...duresses.map((x: Duress) => x.timestamp)),
            active: duresses.map((x: Duress) => x.active).reduce((x: boolean, y: boolean) => x || y),
            duresses: duresses.sort(sortDuress)
        } as DuressGroup
    })
}

const mapStateToProps = (state: RootState) => state.duressControl

function DuressListComponent(props: ICommonProps & {onRefresh: () => void} & ReturnType<typeof mapStateToProps>) {
    const [isMobile, setIsMobile] = React.useState<boolean>(false)
    const [expandOnMobile, setExpandOnMobile] = React.useState<boolean>(false)

    useEffect(() => {
        PubSubSingleton.getInstance().subscribe(messageTypes.widthChanged, (_: any, _isMobile: boolean) =>
            setIsMobile(_isMobile)
        )
    }, [])

    const handleShowAll = (e: any) => {
        e.target.blur()
        PubSubSingleton.getInstance().publish(messageTypes.viewDuress, props.duresses.filter(isOpen))
    }

    // const handleShowAllMobile = (e: any) => {
    //     e.target.blur()
    //     PubSubSingleton.getInstance().publish(
    //         messageTypes.viewDuress,
    //         props.duresses.filter(x => x.active)
    //     )
    // }

    const handleToggleOnMobile = () => setExpandOnMobile(!expandOnMobile)

    const renderDuress = (duress: Duress) => {
        return (
            <DuressComponent
                duress={duress}
                titleType="radio"
                key={duress.radio + duress.timestamp}
                {...props}
            />
        )
    }

    const renderDuressGroup = (group: DuressGroup) => {
        if (group.duresses.length > 1)
            return (
                <DuressGroupComponent group={group} key={group.radio} {...props} />
            )

        return renderDuress(group.duresses[0])
    }

    const renderDesktop = () => {
        return (
            <StyledContainer>
                <h1>Duress Alerts</h1>
                <LoadingOverlay show={props.duressLoading}></LoadingOverlay>
                {props.duresses.filter(isOpen).length > 1 && (
                    <button className="fluid ui secondary large labeled icon button" onClick={handleShowAll}>
                        <i className="ui icon map marker alternate"></i>
                        View all
                    </button>
                )}
                {props.duresses
                    .filter(isOpen)
                    .sort(sortDuress)
                    .map(renderDuress)}
                {groupDuress(props.duresses.filter(isClosed))
                    .sort(sortDuressGroup)
                    .map(renderDuressGroup)}
            </StyledContainer>
        )
    }

    const renderInactiveDuressesOnMobile = () => {
        const inactiveDuresses = props.duresses.filter(x => !x.active)

        if (inactiveDuresses.length === 0) return <></>

        if (expandOnMobile) {
            return (
                <>
                    <div className="ui button fluid large" onClick={handleToggleOnMobile}>
                        Hide inactive duresses
                    </div>
                    {inactiveDuresses.sort(sortDuress).map(renderDuress)}
                    <div className="ui button fluid large" onClick={handleToggleOnMobile}>
                        Hide inactive duresses
                    </div>
                </>
            )
        }

        return (
            <div className="ui button fluid large" onClick={handleToggleOnMobile}>
                Show {inactiveDuresses.length} inactive duress
                {inactiveDuresses.length === 1 ? '' : 'es'}
            </div>
        )
    }

    const renderMobile = () => {
        return (
            <StyledContainer>
                {props.duresses.filter(x => x.active).length > 1 && (
                    <button className="fluid ui secondary large labeled icon button" onClick={handleShowAll}>
                        <i className="ui icon map marker alternate"></i>
                        View all
                    </button>
                )}
                {props.duresses.filter(x => x.active).sort(sortDuress).map(renderDuress)}

                {renderInactiveDuressesOnMobile()}
            </StyledContainer>
        )
    }

    
    if (isMobile) {
        return renderMobile()
    }
    return renderDesktop()
}

export default connect(mapStateToProps)(DuressListComponent)
