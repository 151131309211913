import React, { useState } from 'react'
import styled from 'styled-components'
import MapOptionsComponent from './MapOptions.component'
import RadioListComponent from './RadioList.component'
import ICommonProps from './ICommonProps'
import { connect } from 'react-redux'
import { RootState } from '../store'

const SettingsDiv = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`

const StyledHeading = styled.h1`
  margin-top: 0;
  margin-bottom: 12px;
`

const PaddedDiv = styled.div`
  padding: 12px;
`

const mapStateToProps = (state: RootState) => state.mapDrawing

function MapSidebarComponent(props: ICommonProps) {
    const [showMapOptions, setShowMapOptions] = useState<boolean>(false)

    const handleOptionsClick = () => setShowMapOptions(!showMapOptions)

    const renderOptions = () => {
        return (
            <>
                {!props.isMobile && <StyledHeading>Options</StyledHeading>}
                <MapOptionsComponent {...props} />
            </>
        )
    }

    const renderDefault = () => <RadioListComponent {...props} />

    const renderDesktop = () => {
        const optionsIcon = showMapOptions ? 'close icon' : 'bars icon'
        return (
            <>
                <SettingsDiv>
                    <button className="ui icon button" onClick={handleOptionsClick}>
                        <i className={optionsIcon}></i>
                    </button>
                </SettingsDiv>
                <PaddedDiv>
                    {showMapOptions ? renderOptions() : renderDefault()}
                </PaddedDiv>
            </>
        )
    }

    const renderMobile = () => {
        if (showMapOptions) {
            return (
                <PaddedDiv>
                    <button
                        className="ui large fluid button"
                        onClick={handleOptionsClick}>
                        Hide map options
                    </button>
                    {renderOptions()}
                </PaddedDiv>
            )
        }
        return (
            <PaddedDiv>
                <button
                    className="ui large fluid button"
                    onClick={handleOptionsClick}>
                    Show map options
                </button>
            </PaddedDiv>
        )
    }

    return props.isMobile ? renderMobile() : renderDesktop()
}

export default connect(mapStateToProps)(MapSidebarComponent)
