import React from 'react'

const ExpandoIconComponent = (props: {
    expanded: boolean
    onClick?: () => void
}) => (
    <i
        onClick={props.onClick ? props.onClick : () => {}}
        className="ui icon angle down"
        style={{
            fontSize: 18,
            cursor: 'pointer',
            transition: 'transform 0.25s',
            transform: props.expanded ? 'rotate(180deg)' : ''
        }}
    ></i>
)

export default ExpandoIconComponent
