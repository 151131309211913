import React from 'react'
import styled from 'styled-components'
import { authApi } from '../lib/api'
import { PasswordResetRequest } from '../lib/models'

const PaddedField = styled.div`
  padding-top: 12px;
  padding-top: 12px;
`

const PaddedLabel = styled.label`
  padding-bottom: 12px;
  font-size: 18px !important;
  text-align: left;
`

const StyledIconContainer = styled.div`
  font-size: 100px;
  padding-top: 24px;
  color: #2185d0;
`

const StyledH1 = styled.h1`
  margin-top: 0 !important;
`

interface IProps {
  challenge: any
  onResponse: (response: any) => void
}

interface IState {
  password1: string
  password2: string
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export class PasswordResetComponent extends React.Component<IProps, IState> {
  state: IState = {
    password1: '',
    password2: '',
    loading: false,
    hasError: false,
    errorMessage: ''
  }

  handleUpdate = (name: keyof IState, e: any) => {
    const newState = {} as any
    newState[name] = e.target.value
    this.setState(newState)
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const request: PasswordResetRequest = {
      challenge_name: this.props.challenge.message,
      session: this.props.challenge.details.session,
      challenge_response: {
        NEW_PASSWORD: this.state.password1,
        USERNAME: this.props.challenge.details.USER_ID_FOR_SRP
      }
    }

    authApi
      .challengeResponse(request)
      .then(this.handleSuccess)
      .catch(this.handleFailure)

    this.setState({ loading: true })
  }

  handleSuccess = (authResponse: any) => {
    this.props.onResponse(authResponse)
    this.setState({ loading: false, password1: '', password2: '' })
  }

  handleFailure = (err: any) => {
    console.error(err)
    const { message } = err
    this.setState({
      loading: false,
      password1: '',
      password2: '',
      hasError: true,
      errorMessage: message
    })
  }

  render() {
    return (
      <>
        <StyledIconContainer>
          <i className="ui icon user circle" />
        </StyledIconContainer>
        <StyledH1>Please Choose a New Password</StyledH1>
        <form className="ui form">
          <div className="ui segments">
            <div
              className={`ui segment ${this.state.hasError ? 'red' : 'blue'}`}
            >
              {this.state.hasError && (
                <div className="ui message visible error">
                  {this.state.errorMessage}
                </div>
              )}

              <PaddedField className="field">
                <PaddedLabel>Password</PaddedLabel>
                <div className="ui left icon input">
                  <i className="lock icon"></i>
                  <input
                    type="password"
                    autoComplete="new-password"
                    placeholder="Password..."
                    value={this.state.password1}
                    onChange={this.handleUpdate.bind(null, 'password1')}
                  />
                </div>
              </PaddedField>
              <PaddedField className="field">
                <PaddedLabel>Confirm Password</PaddedLabel>
                <div className="ui left icon input">
                  <i className="lock icon"></i>
                  <input
                    type="password"
                    autoComplete="new-password"
                    placeholder="Confirm password..."
                    value={this.state.password2}
                    onChange={this.handleUpdate.bind(null, 'password2')}
                  />
                </div>
              </PaddedField>
            </div>
            <div className="ui segment secondary">
              <button
                disabled={
                  this.state.password1 !== this.state.password2 ||
                  !this.state.password1
                }
                className={`ui fluid large secondary button ${
                  this.state.loading ? 'loading' : ''
                }`}
                onClick={this.handleSubmit}
              >
                Reset Password
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default PasswordResetComponent
