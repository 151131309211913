import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Message } from '../lib/models'
import { useAppDispatch } from '../store/hooks'
import { removeToastMessage } from '../store/toastControl'


const MessageDiv = styled.div`
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset,
    0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
`

const errorTimeout = 60000
const successTimeout = 4000

function ToastComponent(props: {message: Message}) {
    // const [messages, setMessages] = useState<Message[]>([])
    const [show, setShow] = useState<boolean>(true)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const timeout = props.message.type === 'error' ? errorTimeout : successTimeout
        setTimeout(() => {
            setShow(false)
            dispatch(removeToastMessage(props.message))
        }, timeout)
    }, [dispatch, props.message])

    const handleCloseMessage = (message: Message) => {
        dispatch(removeToastMessage(message))
    }
    
    const renderTitle = (message: Message) => {
        switch (message.type) {
            case 'error':
                return <>Error</>
            case 'warning':
                return <>Warning</>
            case 'success':
                return <>Success</>
        }
    }
    
    const renderIconClass = (message: Message) => {
        switch (message.type) {
            case 'error':
                return 'exclamation circle'
            case 'warning':
                return 'exclamation triangle'
            case 'success':
                return 'check circle'
        }
    }
    
    return (
        <>
            {show && 
                <MessageDiv className={`ui icon message ${props.message.type}`}>
                    <i className={`icon ${renderIconClass(props.message)}`}></i>
                    <i className="close icon" onClick={() => handleCloseMessage(props.message)}></i>
                    <div className="content">
                        <div className="header">{renderTitle(props.message)}</div>
                        {props.message.message}
                    </div>
                </MessageDiv>
            }
        </>
    )
}

export default ToastComponent
