
// import { useState } from 'react'
import PubSub from 'pubsub-js'
import { addToastMessage } from '../store/toastControl';
import { useAppDispatch } from '../store/hooks';

export enum messageTypes {
  widthChanged,
  viewDuress,
  updatedMapOptions,
  startDrawing,
  stopDrawing,
  radiosHighlightedOnMap,
  radiosHighlighted,
  showRadio,
  googleMapsLoaded,
  displayMessage,
  reloadAgencies,
  reloadRadios,
  authSuccess,
  showHistory,
  newUserPosition,
  clearMap,
  reloadPositions,
  navigateTo
}

class PubSubSingleton {
    private static instance: any;

    public static getInstance() {
        if (!PubSubSingleton.instance) {
            PubSubSingleton.instance = PubSubComponent();
        }

        return PubSubSingleton.instance;
    }
}

function PubSubComponent() {
    // const [tokens, setTokens] = useState<Array<string>>([]);
    const dispatch = useAppDispatch();

    const subscribe = (messageType: messageTypes, func: PubSubJS.SubscriptionListener<any>) => {
        // console.log("SUB", messageTypes[messageType], messageType)
        PubSub.subscribeOnce(messageType.toString(), func)
        // tokens.push(token)
        // tokens.push(PubSub.subscribe(messageType.toString(), func))
    }

    const componentWillUnmount = () => {
        PubSub.clearAllSubscriptions()
        // tokens.forEach(t => PubSub.unsubscribe(t))
        // setTokens([])
    }

    const publish = (messageType: messageTypes, msg?: any) => {
        // dispatch(addToastMessage({message: msg, type: messageType.toString()}))
        PubSub.publish(messageType.toString(), msg)
    }

    const publishError = (text: string) => {
        dispatch(addToastMessage({message: text, type: 'error'}))
        // publish(messageTypes.displayMessage, {
        //     message: text,
        //     type: 'error'
        // })
    }

    const publishWarning = (text: string) => {
        dispatch(addToastMessage({message: text, type: 'warning'}))
        // publish(messageTypes.displayMessage, {
        //     message: text,
        //     type: 'warning'
        // })
    }

    const publishSuccess = (text: string) => {
        dispatch(addToastMessage({message: text, type: 'success'}))
        // publish(messageTypes.displayMessage, {
        //     message: text,
        //     type: 'success'
        // })
    }

    return {componentWillUnmount, subscribe, publish, publishError, publishWarning, publishSuccess};
}

export default PubSubSingleton
