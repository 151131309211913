import React from "react";
import styled, {keyframes} from "styled-components";

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Overlay = styled.div`
    right: 0;
    top: 70px;
    width: 306px;
    height: 100%;
    position: fixed;
    background: #222;
    opacity: 0.3;
    z-index: 100000;
`;

const OverlayContent = styled.div`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const OverlaySpinner = styled.span`
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: ${spin} 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
`;

function LoadingOverlay(props: {show: boolean}) {
    return (
        <>
            {props.show && 
                <Overlay>
                    <OverlayContent>
                        <OverlaySpinner />
                    </OverlayContent>
                </Overlay>}
        </>
    )
}

export default LoadingOverlay