import React, { useState, useEffect } from 'react'
// import PubSubSingleton, { messageTypes } from './PubSub.component'
import { MapOptions, setMapOptions as setBrowserMapOptions, getMapOptions as getBrowserMapOptions } from '../lib/storage'
import styled from 'styled-components'
import HorizontalLineComponent from './HorizontalLine.component'
import SliderComponent from './Slider.component'
import ICommonProps from './ICommonProps'

const PaddedDiv = styled.div`
  margin-bottom: 12px;
  margin-right: 24px !important;
`

function MapOptionsComponent(props: ICommonProps) {
    //const mapOptions = useAppSelector((state: any) => state.mapOptions)
    const [mapOptions, setMapOptions] = useState<MapOptions>(getBrowserMapOptions(props.user));

    const [localMinAge, setLocalMinAge] = useState<number|null>(mapOptions.minAge)
    const [localMaxAge, setLocalMaxAge] = useState<number|null>(mapOptions.maxAge)

    // Listen for mapOptions changes
    useEffect(() => {
        setBrowserMapOptions(mapOptions); //Update browser map options

        if (props.onOptionsUpdated) { //Notify parent
            props.onOptionsUpdated(mapOptions)
        }
    }, [props, mapOptions]);

    const toggleAgency = (agency: string, setVisible: boolean) => {
        const inMapOptions = mapOptions.hiddenAgencies.indexOf(agency) >= 0;
        //When setVisible is true check if agency is in mapOptions.hiddenAgencies, add it if not
        if (!setVisible && !inMapOptions) {
            setMapOptions({...mapOptions, hiddenAgencies: [...mapOptions.hiddenAgencies, agency]})
        } 
        //When setVisible is false check if agency is in mapOptions.hiddenAgencies, remove it if so
        if (setVisible && inMapOptions) {        
            setMapOptions({...mapOptions, hiddenAgencies: mapOptions.hiddenAgencies.filter((str: string) => str !== agency)})
        }
        console.log(agency);
    }

    const renderAgency = (agencyCode: string) => {
        const agency = props.agencies.find(x => x.agency === agencyCode)
        if (!agency) return <></>

        const isAgencySelected = mapOptions.hiddenAgencies.indexOf(agencyCode) < 0

        return (
            <PaddedDiv className="ui toggle checkbox" key={agencyCode}>
                <input
                    type="checkbox"
                    defaultChecked={isAgencySelected}
                    onChange={(event) => toggleAgency(agencyCode, event.target.checked)}
                />
                <label>{agency.name}</label>
            </PaddedDiv>
        )
    }

    const renderAdditionalAgencyLayers = () => {
        const readonlyAgencies = props.user.accessLevels
            .filter(x => x.accessLevel === 'map-users')
            .filter(x => x.agency !== props.user.primaryAgency)
            .map(x => x.agency)

        if (readonlyAgencies.length === 0) {
            return <></>
        }

        return (
            <>
                <HorizontalLineComponent />
                <h4>Additional Agencies</h4>
                {readonlyAgencies.map(renderAgency)}
            </>
        )
    }

    return (
        <>
            <h4>Map Layers</h4>
            <PaddedDiv className="ui toggle checkbox">
                <input
                    type="checkbox"
                    defaultChecked={mapOptions.showTraffic}
                    onChange={(event) => setMapOptions({...mapOptions, showTraffic: event.target.checked})} />
                <label>Traffic</label>
            </PaddedDiv>
            <PaddedDiv className="ui toggle checkbox">
                <input
                    type="checkbox"
                    defaultChecked={mapOptions.showTransit}
                    onChange={(event) => setMapOptions({...mapOptions, showTransit: event.target.checked})} />
                <label>Public transport</label>
            </PaddedDiv>
            <PaddedDiv className="ui toggle checkbox">
                <input
                    type="checkbox"
                    defaultChecked={mapOptions.showEsaIncidents}
                    onChange={(event) => setMapOptions({...mapOptions, showEsaIncidents: event.target.checked})} />
                <label>ESA incidents</label>
            </PaddedDiv>
            <PaddedDiv className="ui toggle checkbox">
                <input
                    type="checkbox"
                    defaultChecked={mapOptions.showFiresNearMe}
                    onChange={(event) => setMapOptions({...mapOptions, showFiresNearMe: event.target.checked})} />
                <label>Fires near me</label>
            </PaddedDiv>

            {renderAdditionalAgencyLayers()}

            <h4>Radio Position Options</h4>
            <h5>Age of last known position</h5>

            <div className="field">
                <label htmlFor="map-option-input-min-age">
                    Minimum age &mdash;{' '}
                    {localMinAge!! > 0 ? localMinAge + ' hours ago' : 'Any'}
                </label>
                <SliderComponent
                    id="map-option-input-min-age"
                    name="minAge"
                    onChange={value => setLocalMinAge(value)}
                    onRelease={(value) => setMapOptions({...mapOptions, minAge: value})}
                    value={mapOptions.minAge}
                    upper={localMaxAge!! - 1}
                    min={0}
                    max={48}
                />
            </div>
            <div className="field">
                <label htmlFor="map-option-input-max-age">
                    Maximum age &mdash; {localMaxAge} hours ago
                </label>
                <SliderComponent
                    id="map-option-input-max-age"
                    name="maxAge"
                    onChange={value => setLocalMaxAge(value)}
                    onRelease={(value) => setMapOptions({...mapOptions, maxAge: value})}
                    value={mapOptions.maxAge}
                    lower={localMinAge!! + 1}
                    min={0}
                    max={48}
                />
            </div>
        </>
    )
}

export default MapOptionsComponent
