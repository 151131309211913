import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import MapSidebarComponent from '../components/MapSidebar.component'
import DuressListComponent from '../components/DuressList.component'
import PubSubSingleton, { messageTypes } from '../components/PubSub.component'
import isMobile from '../lib/isMobile'
import ICommonProps from '../components/ICommonProps'
import Map2Component from '../components/Map2.component'
import { MapOptions, getMapOptions } from '../lib/storage'
import { useAppDispatch } from '../store/hooks'
import { setMapOptions } from '../store/mapOptionsSlice'

const duressDivWidth = 300
const radioDivWidth = 250
const panelDivWidth = radioDivWidth + 12

const SidebarDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${radioDivWidth}px;
  background: whitesmoke;
  transition: width 0.5s;
  overflow-x: hidden;
  overflow-y: auto;
`

const MapDiv = styled.div`
  position: absolute;
  left: ${radioDivWidth}px;
  top: 0;
  bottom: 0;
  transition: right 0.5s, left 0.5s;
`

const DuressDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${duressDivWidth}px;
  transition: width 0.5s;
  overflow-x: hidden;
  overflow-y: auto;
  background: whitesmoke;
`

const FixedWidthDuressDiv = styled.div`
  width: ${duressDivWidth}px;
  height: 100%;
`

const FixedWidthRadioDiv = styled.div`
  width: ${radioDivWidth}px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`

const ExpandoTab = styled.div`
  padding-top: 10px;
  position: absolute;
  top: 100px;
  background-color: whitesmoke;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #666666;
  cursor: pointer;
`

const RightExpandoTab = styled(ExpandoTab)`
  right: 0;
  border-radius: 5px 0 0 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
`

const LeftExpandoTab = styled(ExpandoTab)`
  border-radius: 0 5px 5px 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px -1px;
`

function MapPage(props: ICommonProps & {onRefresh: () => void}) {
    const [showDuress] = useState<boolean>(false)
    const prevDuressRef = useRef<boolean>(showDuress);
    const [_isMobile, setIsMobile] = useState<boolean>(isMobile())
    const [userExpandedDuressPanel, setUserExpandedDuressPanel] = useState<boolean>(false)
    const [userExpandedRadioPanel, setUserExpandedRadioPanel] = useState<boolean>(true)
    const [mapOptions, setLocalMapOptions] = useState<MapOptions>(getMapOptions(props.user));
    
    // Sssshhhhh Typescript
    if (false) console.log(mapOptions)

    const dispatch = useAppDispatch();

    const handleRadiosHighlighted = () => {
        if (userExpandedRadioPanel) return
        setUserExpandedRadioPanel(true)
    }

    PubSubSingleton.getInstance().subscribe(messageTypes.widthChanged, (_: any, isMobile: boolean) => setIsMobile(isMobile))
    PubSubSingleton.getInstance().subscribe(messageTypes.radiosHighlighted, handleRadiosHighlighted)

    useEffect(() => {
        if (prevDuressRef.current !== showDuress) {
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'))
            }, 1000)
        }

        prevDuressRef.current = showDuress
    }, [showDuress])

    const handleDuressExpandoClick = () => setUserExpandedDuressPanel(!userExpandedDuressPanel)

    const renderDuressExpandoTab = () => {
        const icon = userExpandedDuressPanel
            ? 'ui icon chevron right'
            : 'ui icon chevron left'
        const title = userExpandedDuressPanel
            ? 'Collapse duress panel'
            : 'Expand duress panel'
        return (
            <RightExpandoTab title={title} onClick={handleDuressExpandoClick}>
                <i className={icon}></i>
            </RightExpandoTab>
        )
    }

    const handleRadioExpandoClick = () => setUserExpandedRadioPanel(!userExpandedRadioPanel)

    const renderLeftExpandoTab = () => {
        const icon = userExpandedRadioPanel
            ? 'ui icon chevron left'
            : 'ui icon chevron right'
        const title = userExpandedRadioPanel
            ? 'Collapse radio panel'
            : 'Expand radio panel'
        return (
            <LeftExpandoTab title={title} onClick={handleRadioExpandoClick}>
                <i className={icon}></i>
            </LeftExpandoTab>
        )
    }

    const updateMapOptions = (mapOptions: MapOptions) => {
        setLocalMapOptions(mapOptions)
        dispatch(setMapOptions(mapOptions))
    }

    const renderMobile = () => {
        return (
            <>
                <DuressListComponent {...props}  />
                <MapSidebarComponent {...props} onOptionsUpdated={updateMapOptions} />
                <Map2Component {...props} />
            </>
        )
    }

    const renderDesktop = (showDuress: boolean) => {
        const showExpandoTab = !props.duresses.find(
            x => x.active || x.acknowledgements.length === 0
        )
        return (
            <>
                <SidebarDiv style={{ width: userExpandedRadioPanel ? panelDivWidth : 0 }}>
                    <FixedWidthRadioDiv>
                        <MapSidebarComponent {...props} onOptionsUpdated={updateMapOptions} />
                    </FixedWidthRadioDiv>
                </SidebarDiv>

                <MapDiv style={{ right: showDuress ? duressDivWidth : 0, left: userExpandedRadioPanel ? panelDivWidth : 0 }}>
                    <Map2Component {...props} />
                    {renderLeftExpandoTab()}
                    {showExpandoTab && renderDuressExpandoTab()}
                </MapDiv>

                <DuressDiv style={{ width: showDuress ? duressDivWidth + 6 : 0 }}>
                    <FixedWidthDuressDiv>
                        <DuressListComponent {...props} onRefresh={props.onRefresh} />
                    </FixedWidthDuressDiv>
                </DuressDiv>
            </>
        )
    }

    return (
        _isMobile
        ? renderMobile()
        : renderDesktop(!!props.duresses.find(
            x => x.active || x.acknowledgements.length === 0
        ) || userExpandedDuressPanel)
    )
}

export default MapPage
