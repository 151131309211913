import React from 'react'
import styled from 'styled-components'
import { authApi } from '../lib/api'
import { AuthRequest } from '../lib/models'
import { PageMode } from '../pages/Login.page'

const PaddedField = styled.div`
  padding-top: 12px;
  padding-top: 12px;
`

const PaddedLabel = styled.label`
  padding-bottom: 12px;
  font-size: 18px !important;
  text-align: left;
`

const StyledIconContainer = styled.div`
  font-size: 100px;
  padding-top: 24px;
  color: #2185d0;
`

const StyledH1 = styled.h1`
  margin-top: 0 !important;
`

interface IProps {
  onSuccess: (response: any) => void
  onSwitchPage: (newPage: PageMode) => void
}

interface IState {
  username: string
  password: string
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export class UsernamePasswordComponent extends React.Component<IProps, IState> {
  state: IState = {
    username: '',
    password: '',
    loading: false,
    hasError: false,
    errorMessage: ''
  }

  handleUpdate = (name: keyof IState, e: any) => {
    const newState = {} as any
    newState[name] = e.target.value
    this.setState(newState)
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { username, password } = this.state

    const request: AuthRequest = {
      username: username.toLowerCase(),
      password
    }

    authApi.login(request).then(this.handleSuccess).catch(this.handleFailure)

    this.setState({ loading: true })
  }

  handleSuccess = (authResponse: any) => {
    this.props.onSuccess(authResponse)
    this.setState({ loading: false, username: '', password: '' })
  }

  handleFailure = (err: any) => {
    const { message } = err
    this.setState({
      loading: false,
      password: '',
      hasError: true,
      errorMessage: message
    })
  }

  handleForgottenPassword = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onSwitchPage(PageMode.PasswordReset)
  }

  render() {
    return (
      <>
        <StyledIconContainer>
          <i className="ui icon user circle" />
        </StyledIconContainer>
        <StyledH1>Please Log In</StyledH1>
        <form className="ui form">
          <div className="ui segments">
            <div
              className={`ui segment ${this.state.hasError ? 'red' : 'blue'}`}
            >
              {this.state.hasError && (
                <div className="ui message visible error">
                  {this.state.errorMessage}
                </div>
              )}

              <PaddedField className="field">
                <PaddedLabel>Email address</PaddedLabel>
                <div className="ui left icon input">
                  <i className="user icon"></i>
                  <input
                    type="email"
                    placeholder="Email address..."
                    value={this.state.username}
                    onChange={this.handleUpdate.bind(null, 'username')}
                  />
                </div>
              </PaddedField>
              <PaddedField className="field">
                <PaddedLabel>Password</PaddedLabel>
                <div className="ui left icon input">
                  <i className="lock icon"></i>
                  <input
                    type="password"
                    placeholder="Password..."
                    value={this.state.password}
                    onChange={this.handleUpdate.bind(null, 'password')}
                  />
                </div>
              </PaddedField>
            </div>
            <div className="ui segment secondary">
              <button
                disabled={!(this.state.username && this.state.password)}
                className={`ui fluid large secondary button ${
                  this.state.loading ? 'loading' : ''
                }`}
                onClick={this.handleSubmit}
              >
                Login
              </button>
            </div>
          </div>
          <div>
            <button
              className="ui basic button"
              onClick={this.handleForgottenPassword}
            >
              Forgotten your password?
            </button>
          </div>
        </form>
      </>
    )
  }
}

export default UsernamePasswordComponent
