import React, { useEffect, useState } from 'react'
import { Radio, UserInfo, Changelog, VersionedAgency } from '../lib/models'
import { adminApi } from '../lib/api'
import styled from 'styled-components'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import ConfirmationComponent from './Confirmation.component'
import { hasPlatformAdmin, hasAgencyIdAdminAccess } from '../lib/accessControl'
import {
  RequirePlatformAdminComponent,
  RequireAgencyAdminComponent
} from './AccessControl.component'
import { formatTimestamp } from '../lib/utils'
import LoadingComponent from './Loading.component'
import { redirect, useNavigate } from 'react-router-dom'
// import ICommonProps from './ICommonProps';
// import { withRouter } from './WithRouter.component'

const RightAlignedTd = styled.td`
  text-align: right !important;
`

const RightFloatedButton = styled.button`
  float: right;
`

const ReadonlyValue = styled.div`
  padding: 15px;
`

// interface IState {
//   saving: boolean
//   deleting: boolean
//   name: string
//   ranges: number[][]
//   newUpperRange: string
//   newLowerRange: string
//   code: string
//   showModal: boolean
//   abbr: string
//   loading: boolean
//   agencyDetails?: any
// }

type IProps = { // ICommonProps & { // RouterProps & 
  agencyId: string
  user: UserInfo
  radios: Radio[]
  isNew: boolean
};

function EditAgencyComponent(props: IProps) {
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [name, setName] = useState('');
    const [ranges, setRanges] = useState<number[][]>([]);
    const [newUpperRange, setNewUpperRange] = useState('');
    const [newLowerRange, setNewLowerRange] = useState('');
    const [code, setCode] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [abbr, setAbbr] = useState('');
    const [loading, setLoading] = useState(true);
    const [agencyDetails, setAgencyDetails] = useState<any>();
    
    const navigate = useNavigate();

    window.scrollTo(0, 0)
    if (props.isNew) {
      setLoading(false)
    //   return null
    }

    const [doOnce, setDoOnce] = useState(false);

    useEffect(() => {
        if (!doOnce) {
            try {
                setLoading(true)
                adminApi.agencies.get(props.agencyId).then((agencyDetails) => {

                    setAgencyDetails(agencyDetails)
                    setName(agencyDetails.name)
                    setRanges(agencyDetails.ranges)
                    setCode(agencyDetails.agency)
                    setAbbr(agencyDetails.abbr)
                    setDoOnce(true)
                })
            } catch (e) {
                console.error(e)
                PubSubSingleton.getInstance().publishError('Unexpected error loading agency details')
            } finally {
                setLoading(false)
            }
        }
    }, [doOnce, setDoOnce, props.agencyId, ranges])

    const handleSave = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setSaving(true);

        const newAgency: VersionedAgency = {
            abbr: abbr,
            agency: code,
            name: name,
            ranges: ranges,
            version: agencyDetails?.version
        }

        try {
            const updatedAgencyFromServer = await adminApi.agencies.put(newAgency)
            updatedAgencyFromServer.agency = props.agencyId
            PubSubSingleton.getInstance().publishSuccess('Agency updated')
            PubSubSingleton.getInstance().publish(messageTypes.reloadAgencies)
            redirect('/administration')
        } catch (err) {
            console.error(err)
            PubSubSingleton.getInstance().publishError(
                typeof err == 'string' ? err : 'An error was encountered when saving the agency'
            )
        } finally {
            setSaving(false)
        }
    }

    const handleViewRadio = (radio: Radio) => {
        navigate(`/administration/radio/${radio.radio}`)
    }

    // const handleUpdate = (key: keyof IState, e: any) => {
    //     const { state } = this as any
    //     state[key] = e.target.value
    //     setState(state)
    // }

    const handleRemove = (index: number, e: any) => {
        e.preventDefault()
        e.stopPropagation()
        const _ranges = [...ranges];
        _ranges.splice(index, 1)
        setRanges(_ranges)
    }

    const handleAdd = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        let _ranges = ranges
        _ranges.push([
            parseInt(newLowerRange),
            parseInt(newUpperRange)
        ])
        setRanges(_ranges)
        setNewLowerRange('')
        setNewUpperRange('')
    }

    const handleDelete = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setShowModal(true)
    }

    const handleConfirmationResult = (result: string) => {
        switch (result) {
        case 'Delete':
            setDeleting(true)
            setShowModal(false)
            adminApi.agencies.del(agencyDetails).then(() => {
                setDeleting(false)
                PubSubSingleton.getInstance().publish(messageTypes.reloadAgencies)
                PubSubSingleton.getInstance().publishSuccess('Agency deleted')
                redirect('/administration')
            }).catch(() => {
                PubSubSingleton.getInstance().publishError(
                'An error was encountered when deleting the agency'
                )
            })

            break
        case 'Cancel':
            setShowModal(false)
            break
        }
    }

    const doesAgencyHaveRadio = (radio: Radio) => {
        const radioId = parseInt(radio.radio)
        for (let range of ranges) {
        if (radioId >= range[0] && radioId <= range[1]) return true
        }
        return false
    }

    const renderModal = () => {
        if (!showModal) return <></>
        return (
        <ConfirmationComponent
            title="Confirm Delete"
            onClick={handleConfirmationResult}
            buttons={['Delete', 'Cancel']}
        >
            <>Are you sure you want to delete this agency?</>
        </ConfirmationComponent>
        )
    }

    const renderRadios = () => {
        if (props.isNew) return <></>
        return (
        <>
            <h2>Radios</h2>

            <table className="ui table">
            <thead>
                <tr>
                <th>Radio ID</th>
                <th>Alias</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {props.radios
                .filter(x => doesAgencyHaveRadio(x))
                .map(radio => (
                    <tr key={radio.radio}>
                    <td>{radio.radio}</td>
                    <td>{radio.cam && radio.cam.alias && radio.cam.alias}</td>
                    <RightAlignedTd>
                        <button
                        className="ui button"
                        onClick={handleViewRadio.bind(null, radio)}
                        >
                        Edit
                        </button>
                    </RightAlignedTd>
                    </tr>
                ))}
            </tbody>
            </table>
        </>
        )
    }

    const renderEditableRanges = () => {
        return (
        <div className="field">
            <label>Ranges</label>

            <table className="ui table">
            <thead>
                <tr>
                <th>From</th>
                <th>To</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {ranges.map((x, index) => {
                return (
                    <tr key={index}>
                    <td>{x[0]}</td>
                    <td>{x[1]}</td>
                    <RightAlignedTd>
                        <button
                        className="ui button small"
                        onClick={handleRemove.bind(null, index)}
                        >
                        <i className="icon minus"></i>
                        Remove
                        </button>
                    </RightAlignedTd>
                    </tr>
                )
                })}
                <tr>
                <td>
                    <div className="ui fluid input">
                    <input
                        type="number"
                        placeholder="From..."
                        value={newLowerRange}
                        onChange={(e) => setNewLowerRange(e.target.value)}
                    />
                    </div>
                </td>
                <td>
                    <div className="ui fluid input">
                    <input
                        type="number"
                        placeholder="To..."
                        value={newUpperRange}
                        onChange={(e) => setNewUpperRange(e.target.value)}
                    />
                    </div>
                </td>
                <RightAlignedTd>
                    <button
                    className="ui secondary button large"
                    onClick={handleAdd}
                    disabled={
                        !newLowerRange || !newUpperRange
                    }
                    >
                    <i className="icon plus"></i>
                    Add
                    </button>
                </RightAlignedTd>
                </tr>
            </tbody>
            </table>
        </div>
        )
    }
  
    const renderReadonlyRanges = () => {
        return (
        <div className="field">
            <label>Ranges</label>

            <table className="ui table">
            <thead>
                <tr>
                <th>From</th>
                <th>To</th>
                </tr>
            </thead>
            <tbody>
                {(agencyDetails?.ranges || []).map(
                (x: number[], index: number) => {
                    return (
                    <tr key={index}>
                        <td>{x[0]}</td>
                        <td>{x[1]}</td>
                    </tr>
                    )
                }
                )}
            </tbody>
            </table>
        </div>
        )
    }

    const calculateChanges = (changes: any) => {
        const output: string[] = []
        if (changes.ranges) {
        const ranges = changes.ranges as number[][]

        output.push(
            `ranges = ${ranges
            .map(range => `${range[0]} - ${range[1]}`)
            .join(', ')}`
        )
        }

        Object.keys(changes).forEach(key => {
            if (key === 'ranges') return
            output.push(`${key} = ${(changes as any)[key]}`)
        })
        return output
    }

    const renderChangelog = () => {
        if (!hasPlatformAdmin(props.user)) return <></>

        return (
        <>
            <h2>Change Log</h2>
            <table className="ui table">
            <thead>
                <tr>
                <th>Date</th>
                <th>User</th>
                <th>Changes</th>
                </tr>
            </thead>
            <tbody>
                {(agencyDetails?.changelog || []).map((log: Changelog, index: number) => {
                    return (
                        <tr key={index}>
                            <td>{formatTimestamp(log.timestamp)}</td>
                            <td>{log.email}</td>
                            <td>{calculateChanges(log.changes).join(', ')}</td>
                        </tr>
                    )
                })}
            </tbody>
            </table>
        </>
        )
    }


    if (!hasAgencyIdAdminAccess(props.user, props.agencyId)) {
      return <>Permission denied</>
    }

    if (loading) {
      return <LoadingComponent />
    }


    const canSave = !!name && !!code && !!abbr
    return (
      <>
        <h1>{name}</h1>

        <form className="ui form">
          <button style={{ display: 'none' }} onClick={handleAdd}></button>

          <div className="ui segments">
            <div className="ui segment">
              {props.isNew && (
                <div className="field">
                  <label>Identifier</label>
                  <input
                    type="text"
                    value={code}
                    placeholder="Agency identifier..."
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              )}

              <div className="field">
                <label>Name</label>
                <input
                  type="text"
                  value={name}
                  placeholder="Agency name..."
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="field">
                <label>Code</label>

                {hasPlatformAdmin(props.user) && (
                  <input
                    type="text"
                    value={abbr}
                    placeholder="Agency code..."
                    onChange={(e) => setAbbr(e.target.value)}
                  />
                )}

                {!hasPlatformAdmin(props.user) && (
                  <ReadonlyValue>{abbr}</ReadonlyValue>
                )}
              </div>

              {hasPlatformAdmin(props.user)
                ? renderEditableRanges()
                : renderReadonlyRanges()}
            </div>

            <RequireAgencyAdminComponent
              user={props.user}
              agency={agencyDetails}
            >
              <div className="ui segment secondary">
                <button
                  className={`ui large secondary ${
                    saving ? 'loading' : ''
                  } button`}
                  disabled={saving || !canSave}
                  onClick={handleSave}
                >
                  <i className="icon check"></i>
                  Save
                </button>
                <button
                  className="ui large button"
                  onClick={() => redirect('/administration')}
                >
                  Cancel
                </button>

                <RequirePlatformAdminComponent user={props.user}>
                  {!props.isNew && (
                    <RightFloatedButton
                      onClick={handleDelete}
                      className={`ui large red button ${
                        deleting ? 'loading' : ''
                      }`}
                    >
                      <i className="icon trash"></i>
                      Delete
                    </RightFloatedButton>
                  )}
                </RequirePlatformAdminComponent>
              </div>
            </RequireAgencyAdminComponent>
          </div>
        </form>
        {renderChangelog()}
        {renderRadios()}
        {renderModal()}
      </>
    )
}

export default EditAgencyComponent
