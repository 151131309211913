import React from 'react'
import { Radio } from '../../../lib/models'
import { ICriteriaComponent } from '../utilities'

class AliasCriteria extends ICriteriaComponent<
  {},
  { value: string; exactMatch: boolean }
> {
  state = {
    value: '',
    exactMatch: false
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value })
  }

  handleToggle = () => this.setState({ exactMatch: !this.state.exactMatch })

  filter(radio: Radio) {
    if (this.state.exactMatch) {
      return (
        (radio?.cam?.alias || '').toLowerCase() ===
        this.state.value.toLowerCase()
      )
    }
    return (
      (radio?.cam?.alias || '')
        .toLowerCase()
        .indexOf(this.state.value.toLowerCase()) >= 0
    )
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>Alias</h4>
        </div>
        <div className="four wide column">
          <input
            type="text"
            placeholder="alias..."
            value={this.state.value}
            onChange={this.handleChange}
          />
        </div>
        <div className="four wide column">
          <div className="ui toggle checkbox">
            <input
              type="checkbox"
              checked={this.state.exactMatch}
              onChange={this.handleToggle}
            />
            <label>Exact match</label>
          </div>
        </div>
      </>
    )
  }
}

export default AliasCriteria
