import React from 'react'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import { createParameter } from '../../lib/api'

interface IState {
  type: string
  code: string
}

class FilterByHTTPMethodCriteria extends IQueryBuilderComponent<{}, IState> {
  state: IState = {
    type: 'success',
    code: ''
  }

  handleTypeChange = (type: string) => {
    this.setState({ type })
  }

  handleCodeChange = (code: string) => {
    this.setState({ code })
  }

  getParameters() {
    switch (this.state.type) {
      case 'code':
        return [createParameter('status', this.state.code || '')]
      case 'success':
        return [createParameter('success', '1')]
      case 'error':
        return [createParameter('error', '1')]
    }
    return []
  }

  getType() {
    return 'status'
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>HTTP Response Status</h4>
        </div>
        <div className="eight wide column">
          <div className="ui form">
            <div className="inline fields">
              <div className="field">
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    name="type"
                    value="success"
                    checked={this.state.type === 'success'}
                    onChange={e => this.handleTypeChange('success')}
                  />
                  <label>Success</label>
                </div>
              </div>
              <div className="field">
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    name="type"
                    value="error"
                    checked={this.state.type === 'error'}
                    onChange={e => this.handleTypeChange('error')}
                  />
                  <label>Error</label>
                </div>
              </div>
              <div className="field">
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    name="type"
                    value="code"
                    checked={this.state.type === 'code'}
                    onChange={e => this.handleTypeChange('code')}
                  />
                  <label>Exact status code:</label>
                </div>
              </div>
              <div className="field">
                <input
                  type="text"
                  name="code"
                  value={this.state.code}
                  onChange={e => this.handleCodeChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FilterByHTTPMethodCriteria
