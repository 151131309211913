import React from 'react'
// import { RouterProps } from 'react-router-dom'
// import { withRouter } from './WithRouter.component'
import { UserInfo } from '../lib/models'
import { adminApi } from '../lib/api'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import { RequirePlatformAdminComponent } from './AccessControl.component'
import { parse } from 'papaparse'

type IProps = { // RouterProps & 
  user: UserInfo
}

interface IState {
  loading: boolean
}

export class UploadCamComponent extends React.Component<IProps, IState> {
  state = {
    loading: false
  }

  uploadCamData = (source: string) => {
    const parsed = parse(source)
    const rows = parsed.data as Array<string[]>
    /*
     * We expect a decimal radio ID in the 2nd column, and the alias in the
     * 8th.  All other columns are junk.  Disregard any rows where the radio ID
     * field is not a sequence of digits, or the alias is blank -- this should
     * strip out header and padding rows, if present, and just leave legitimate
     * data rows.
     */
    const digits = /^\d+$/
    const items = rows
      .map(x => [x[1], x[7]] as [string, string])
      .filter(x => x && x[0] && x[1] && x[0].match(digits))

    this.setState({ loading: true })
    adminApi.cam.post(items).then(res => {
      if (res.success_count > 0) {
        PubSubSingleton.getInstance().publishSuccess(
          `${res.success_count} aliases updated successfully.`
        )
      }
      if (res.errors.length > 0) {
        res.errors.forEach(x => {
            PubSubSingleton.getInstance().publishError(`Error on line ${x.record_number}: ${x.message}`)
        })
      }
      this.setState({ loading: false })
      PubSubSingleton.getInstance().publish(messageTypes.reloadRadios)
    })
  }

  handleUpload = (evt: any) => {
    const file = evt.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.readAsText(file, 'UTF-8')
    reader.onload = e => {
      if (
        e !== null &&
        e.target !== null &&
        e.target.result !== null &&
        typeof e.target.result === 'string'
      ) {
        this.uploadCamData(e.target.result)
      }
    }
    reader.onerror = e => {
        PubSubSingleton.getInstance().publishError('Error reading file')
    }
  }

  render() {
    const loading = this.state.loading ? 'loading' : ''
    let fileInput: HTMLInputElement | null = null
    return (
      <RequirePlatformAdminComponent user={this.props.user}>
        <h1>Upload CAM alias file</h1>
        <button
          className={`ui huge labeled icon ${loading} button`}
          onClick={() => fileInput!.click()}
        >
          <i className="upload icon"></i>
          Select CSV file for upload
        </button>
        <input
          id="file-upload"
          type="file"
          hidden
          ref={x => {
            fileInput = x
          }}
          onChange={this.handleUpload}
        />
        <h2>Notes</h2>
        <p>
          The file should be formatted as CSV (Comma-Separated Values) in UTF-8,
          with:
          <ul>
            <li>
              radio ID as a decimal integer in the <strong>2nd</strong> column
              &ldquo;B&rdquo;, and
            </li>
            <li>
              radio alias as a string in the <strong>8th</strong> column
              &ldquo;H&rdquo;.
            </li>
          </ul>
        </p>
        <p>All other columns will be disregarded.</p>
        <p>A header row is allowed but not required.</p>
        <p>Empty aliases are not valid, and will be ignored.</p>
        <h2>Example</h2>
        <p>The following is an example of a minimal valid file for upload:</p>
        <pre>
          <code>,1000001,,,,,,TEST ALIAS</code>
        </pre>
      </RequirePlatformAdminComponent>
    )
  }
}

export default UploadCamComponent
