
import { createSlice } from '@reduxjs/toolkit'

export interface MapOptionsState {
    showTraffic: boolean,
    showEsaIncidents: boolean,
    showFiresNearMe: boolean,
    showTransit: boolean,
    hiddenAgencies: string[],
    minAge: number,
    maxAge: number,
    username: string
}

const initialState: MapOptionsState = {
    showTraffic: true,
    showEsaIncidents: true,
    showFiresNearMe: true,
    showTransit: true,
    hiddenAgencies: [],
    minAge: 0,
    maxAge: 48,
    username: ''
}

export const mapOptionsSlice = createSlice({
    name: 'mapOptions',
    initialState,
    reducers: {
        setMapOptions: (state, action) => {
            state = Object.assign(state, action.payload)
        },
    },
})

// Action creators are generated for each case reducer function
export const { setMapOptions } = mapOptionsSlice.actions

export default mapOptionsSlice.reducer
