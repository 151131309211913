import React from 'react'
import DropdownComponent from './Dropdown.component'
import SearchButton from './SearchButton.component'
import { ISearchForm } from './ISearchForm'
import { IQueryBuilderComponent } from './IQueryBuilderComponent'
import { QueryParameter, QueryResult } from '../lib/models'
import { messageTypes } from './PubSub.component'
import styled from 'styled-components'

const HiddenDiv = styled.div`
  display: none;
`

interface IProps {
  filters: any[]
  filterNames: string[]
  onSearch: (parameters: QueryParameter[]) => Promise<QueryResult>
}

interface IState {
  criteria: any[]
  loading: boolean
}

class SearchByFilterComponent extends ISearchForm<IProps, IState> {
  state: IState = {
    criteria: [],
    loading: false
  }

  customRefs: IQueryBuilderComponent<any, any>[] = []

  handleAddFilter = (name: string) => {
    const { criteria } = this.state
    const i = this.props.filterNames.indexOf(name)

    if (i >= 0) {
      criteria.push(this.props.filters[i])
      criteria[criteria.length - 1].id = new Date().getTime()
      this.setState({ criteria: criteria })
    }
  }
  handleSearch = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      this.setState({ loading: true })
      this.props.onResults()
      const parameters: QueryParameter[] = []
      for (const criteriaComponent of this.customRefs) {
        if (!criteriaComponent) continue
        criteriaComponent.getParameters().forEach(x => parameters.push(x))
      }

      const queryResult = await this.props.onSearch(parameters)
      this.props.onResults(queryResult)
    } catch (e) {
      console.error(e)
      PubSub.publish(messageTypes.displayMessage.toString(), {
        message: 'Unexpected error when attempting to load report data',
        type: 'error'
      })
      // todo, show error
    } finally {
      this.setState({ loading: false })
    }
  }

  handleRemove = (index: number, e: any) => {
    e.preventDefault()
    e.stopPropagation()
    let { criteria: filters } = this.state
    filters = filters.filter((_, i) => index !== i)
    this.setState({ criteria: filters })
  }

  renderFilterWarning = () => {
    if (this.state.criteria.length === 0) return <></>
    const types = new Set<string>()
    for (const Criteria of this.state.criteria) {
      const type = new Criteria().getType()
      if (types.has(type))
        return (
          <div className="ui message blue">
            You have multiple search criteria for {type}. Consider having just
            one.
          </div>
        )
      types.add(type)
    }
    return <></>
  }

  render() {
    this.customRefs = []
    return (
      <form className="ui form" onSubmit={this.handleSearch}>
        <HiddenDiv>
          <SearchButton enable={this.state.criteria.length > 0} />
        </HiddenDiv>
        {this.renderFilterWarning()}
        <div className="ui grid" style={{ paddingBottom: 40 }}>
          {this.state.criteria.map((X: any, index: number) => {
            return (
              <React.Fragment key={X.id}>
                <X
                  {...this.props}
                  ref={(x: any) => {
                    if (x) this.customRefs.push(x)
                  }}
                />
                <div
                  className="four wide column"
                  style={{ textAlign: 'right' }}
                >
                  <button
                    className="ui button"
                    onClick={this.handleRemove.bind(null, index)}
                  >
                    <i className="minus icon"></i>
                    Remove
                  </button>
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <SearchButton
          enable={this.state.criteria.length > 0}
          loading={this.state.loading}
        />{' '}
        <DropdownComponent
          title="Add filter"
          icon="plus"
          onSelect={this.handleAddFilter}
          values={this.props.filterNames}
          buttonClass="ui large button dropdown"
        />
      </form>
    )
  }
}

export default SearchByFilterComponent
