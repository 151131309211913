import React from 'react'
import { QueryResult } from '../lib/models'
import { prettifyReportValues, titleCase } from '../lib/utils'

const shouldShow = (field: string, fieldsToIgnore?: string[]) => {
  if (!fieldsToIgnore) return true
  return !fieldsToIgnore.includes(field)
}

const ReportingResultsComponent = (props: {
  queryResult: QueryResult
  fieldsToIgnore?: string[]
}) => {
  const filteredFields = props.queryResult.fields.filter(x =>
    shouldShow(x, props.fieldsToIgnore)
  )

  return (
    <table className="ui table">
      <thead>
        <tr>
          {filteredFields.map(field => (
            <th key={field}>{titleCase(field)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredFields.length === 0 && (
          <tr>
            <td colSpan={filteredFields.length}>
              <i>No results</i>
            </td>
          </tr>
        )}

        {props.queryResult.values
          .filter((_, index) => index < 1000)
          .map((record, index) => (
            <tr key={index}>
              {record
                .filter((_, index) =>
                  shouldShow(
                    props.queryResult.fields[index],
                    props.fieldsToIgnore
                  )
                )
                .map((value, index) => (
                  <td key={index}>
                    {prettifyReportValues(value, filteredFields[index])}
                  </td>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default ReportingResultsComponent
