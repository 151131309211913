import React from 'react'
import PubSubSingleton, { messageTypes } from '../components/PubSub.component'
import { Radio, UserInfo } from '../lib/models'
import { hasAnyAdminAccess } from '../lib/accessControl'
import { useNavigate } from 'react-router-dom'

function AdminWrapper({user, children}: { user: UserInfo, children?: React.ReactNode }) { // RouterProps & 
    const navigate = useNavigate();

    PubSubSingleton.getInstance().subscribe(messageTypes.showRadio, (_: any, radio: Radio) => {
        if (!radio) return
        navigate(`/administration/radio/${radio.radio}`)
    })

    if (!hasAnyAdminAccess(user)) {
      return <></>
    }
    return <>{children}</>
}

export default AdminWrapper
