import React from 'react'
import { Agency } from '../lib/models'
import styled from 'styled-components'
import RangeComponent from './Range.component'
import { useNavigate } from 'react-router-dom'
import { doesAgencyHaveRadio } from '../lib/utils'
import { hasAgencyAdminAccess } from '../lib/accessControl'
import ICommonProps from './ICommonProps'
import { RequirePlatformAdminComponent } from './AccessControl.component'

const RightAlignedTd = styled.td`
  text-align: right !important;
`

// type IProps = RouterProps & ICommonProps

function AgencyListComponent(props: ICommonProps) {
    const navigate = useNavigate();
    const renderAgency = (agency: Agency) => {
        return (
            <tr key={agency.agency}>
                <td>{agency.abbr || agency.agency}</td>
                <td>{agency.name}</td>
                <td>
                    {agency.ranges.map((x, i) => (
                        <RangeComponent range={x} key={i} />
                    ))}
                </td>
                <td>
                    {props.radios.filter(x => doesAgencyHaveRadio(agency, x)).length}{' '}
                    radios
                </td>
                <RightAlignedTd>
                    <button
                        className="ui button"
                        onClick={() =>
                            navigate(`/administration/agency/${agency.agency}`)
                        }
                    >
                        Edit
                    </button>
                </RightAlignedTd>
            </tr>
        )
    }

    const agencies = props.agencies.filter(x =>
        hasAgencyAdminAccess(props.user, x)
    )

    return (
        <>
            <h1>
                Agencies
                <RequirePlatformAdminComponent user={props.user}>
                <button
                    className="ui button secondary right floated"
                    onClick={() => navigate('/administration/newagency')}>
                        <i className="icon plus"></i>
                        Add agency
                </button>
                </RequirePlatformAdminComponent>
            </h1>

            <table className="ui table large">
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Agency</th>
                    <th>Ranges</th>
                    <th>Radios</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>{agencies.map(renderAgency)}</tbody>
            </table>
        </>
    )
}

export default AgencyListComponent
