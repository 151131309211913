import React from 'react'
import { toIsoDateString } from '../../lib/utils'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { createParameter } from '../../lib/api'

const oneDay = 1000 * 60 * 60 * 24
const roundedDate = () => {
  const d = new Date()
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

class FilterByDateRangeCriteria extends IQueryBuilderComponent<
  {},
  {
    from: Date
    to: Date
  }
> {
  state = {
    from: new Date(roundedDate().getTime() - oneDay),
    to: roundedDate()
  }

  getParameters() {
    return [
      createParameter('start', toIsoDateString(this.state.from)),
      createParameter('end', toIsoDateString(this.state.to))
    ]
  }

  getType() {
    return 'date'
  }

  handleOnChange = (key: string, value: Date) => {
    const newState = {} as any
    newState[key] = value
    this.setState(newState)
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>Date Range</h4>
        </div>
        <div className="four wide column">
          <DatePicker
            selected={this.state.from}
            onChange={this.handleOnChange.bind(null, 'from')}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="dd/MM/yyyy HH:mm"
          />
        </div>
        <div className="four wide column">
          <DatePicker
            selected={this.state.to}
            onChange={this.handleOnChange.bind(null, 'to')}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="dd/MM/yyyy HH:mm"
          />
        </div>
      </>
    )
  }
}

export default FilterByDateRangeCriteria
