import { UserInfo, Agency, UserAccessLevel } from './models'

const platformAdminGroup = 'platform-admins'
const cognitoGroupProperty = 'cognito:groups'

export const parseToken = (token: any): UserInfo => {
  if (!token)
    return {
      accessLevels: [],
      email: '',
      platformAdmin: false,
      groups: [],
      primaryAgency: ''
    }
  const groups = (token[cognitoGroupProperty] || []) as string[]
  return {
    email: token.email,
    groups: groups,
    platformAdmin: groups.includes(platformAdminGroup),
    accessLevels: groups
      .filter(x => x !== platformAdminGroup)
      .map(x => x.split('.'))
      .map(x => ({ accessLevel: x[0], agency: x[1] } as UserAccessLevel)),
    primaryAgency: ''
  }
}

export const hasPlatformAdmin = (userInfo: UserInfo) => {
  if (!userInfo) return false
  return userInfo.platformAdmin
}

export const hasAgencyAdminAccess = (userInfo: UserInfo, agency?: Agency) => {
  if (!userInfo) return false
  if (userInfo.platformAdmin) return true
  return !!userInfo.accessLevels.find(
    x => x.agency === agency?.agency && x.accessLevel === 'agency-admins'
  )
}

export const hasAgencyIdAdminAccess = (
  userInfo: UserInfo,
  agencyId: string
) => {
  if (!userInfo) return false
  if (userInfo.platformAdmin) return true
  return !!userInfo.accessLevels.find(
    x => x.agency === agencyId && x.accessLevel === 'agency-admins'
  )
}

export const hasOpsAccess = (userInfo: UserInfo, agency: Agency) => {
  if (!userInfo) return false
  if (userInfo.platformAdmin) return true
  return !!userInfo.accessLevels.find(
    x =>
      x.agency === agency.agency &&
      (x.accessLevel === 'ops-users' || x.accessLevel === 'agency-admins')
  )
}

export const hasMapsAccess = (userInfo: UserInfo, agency: Agency) => {
  if (!userInfo) return false
  if (userInfo.platformAdmin) return true
  return !!userInfo.accessLevels.find(
    x =>
      x.agency === agency.agency &&
      (x.accessLevel === 'ops-users' ||
        x.accessLevel === 'agency-admins' ||
        x.accessLevel === 'map-users')
  )
}

export const hasAnyAdminAccess = (userInfo: UserInfo) => {
  if (!userInfo) return false
  if (userInfo.platformAdmin) return true
  return !!userInfo.accessLevels.find(x => x.accessLevel === 'agency-admins')
}

export const hasAnyOperationsAccess = (userInfo: UserInfo) => {
  if (!userInfo) return false
  if (userInfo.platformAdmin) return true
  return !!userInfo.accessLevels.find(
    x => x.accessLevel === 'agency-admins' || x.accessLevel === 'ops-users'
  )
}
