import React, { useEffect, useState } from 'react'
import {
  Radio,
  Agency,
  UserInfo,
  RadioMetadata,
  RadioDetails,
  Changelog
} from '../lib/models'
import { adminApi } from '../lib/api'
import PubSubSingleton, { messageTypes } from './PubSub.component'
import { useParams, useNavigate } from 'react-router-dom'
// import { withRouter } from './WithRouter.component';
import { getAgencyForRadio, formatTimestamp, titleCase } from '../lib/utils'
import { hasAgencyAdminAccess, hasPlatformAdmin } from '../lib/accessControl'
import RadioMetadataComponent from './RadioMetadata.component'
import { capabilityList } from '../lib/capabilities'
import { SearchableDropdown } from './SearchableDropdown.component'
import baseStations from '../lib/baseStations'
import radioTypes from '../lib/radioTypes'
import styled from 'styled-components'
import DisplaySlugComponent from './DisplaySlug.component'
// import { render } from 'react-dom'
// import { RouterProps } from 'react-router-dom'

const ReadonlyValue = styled.div`
  padding: 15px;
`

const Small = styled.div`
  font-size: 14px;
  color: #999;
`

const basicMetadataFields: (keyof RadioMetadata)[] = [
  'service_id',
  'notes',
  'capacity'
]

type IProps = { // RouterProps &
  user: UserInfo
  radio: Radio
  agencies: Agency[]
}

function EditRadioComponent(props: IProps) {

    const [data, setData] = useState<RadioMetadata>(props.radio.static || {})
    // const [newKey, setNewKey] = useState<string>('')
    // const [newValue, setNewValue] = useState<string>('')
    const [saving, setSaving] = useState<boolean>(false)
    const [valid, setValid] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [radioDetails, setRadioDetails] = useState<RadioDetails>()

    const params = useParams()
    const navigate = useNavigate()

    const [doOnce, setDoOnce] = useState<boolean>(false)

    useEffect(() => {
        if (!doOnce) {
            window.scrollTo(0, 0)
            if (!hasPlatformAdmin(props.user)) return

            try {
                setLoading(true)
                adminApi.radios.get(props.radio.radio).then(radioDetails => {
                    setRadioDetails(radioDetails)
                })
            } catch (e) {
                console.error(e)
                PubSubSingleton.getInstance().publishError('Unexpected error loading radio details')
            } finally {
                setLoading(false)
            }
            setDoOnce(true)
        }

        if (params.radio !== undefined && params.radio !== props.radio.radio){ 
            setData(props.radio.static)
            if (!hasPlatformAdmin(props.user)) return

            try {
                setLoading(true)
                adminApi.radios.get(props.radio.radio).then(radioDetails => {
                    setRadioDetails(radioDetails)
                })
            } catch (e) {
                console.error(e)
                PubSubSingleton.getInstance().publishError('Unexpected error loading radio details')
            } finally {
                setLoading(false)
            }
        }
    }, [doOnce, params.radio, props])


    const loadRadioDetails = async () => {
        if (!hasPlatformAdmin(props.user)) return

        try {
            setLoading(true)
            const radioDetails = await adminApi.radios.get(props.radio.radio)
            setRadioDetails(radioDetails)
        } catch (e) {
            console.error(e)
            PubSubSingleton.getInstance().publishError('Unexpected error loading radio details')
        } finally {
            setLoading(false)
        }
    }

    // const handleUpdate = (key: keyof IState, e: any) => {
    //     const { state } = this as any
    //     state[key] = e.target.value
    //     setState(state)
    // }

    const handleUpdateMeta = (key: string, e: any) => {
        let _data = {...data}
        _data[key as keyof RadioMetadata] = e.target.value as string
        setData(_data)
    }

    const handleCancel = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setData(props.radio.static)
        loadRadioDetails()
    }

    const handleSave = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        const { radio } = props
        radio.static = data
        setSaving(true)
        adminApi.radios
        .put(radio)
        .then(() => {
            setSaving(false)
            PubSubSingleton.getInstance().publish(messageTypes.reloadRadios)
            PubSubSingleton.getInstance().publishSuccess('Radio updated')
        })
        .catch(err => {
            setSaving(false)
            PubSubSingleton.getInstance().publishError(
                typeof err == 'string' ? err : 'An error was encountered when saving the radio'
            )
        })
    }

  const renderReadonlyMetadata = () => {
        return <RadioMetadataComponent radio={props.radio} />
    }

    const renderMetadataField = (field: keyof RadioMetadata) => {
        let value = ''
        if (data) {
            value = data[field] || ''
        } 

        return (
            <div className="field" key={field}>
                <label>
                    <DisplaySlugComponent value={field} />
                </label>
                <input
                    type="text"
                    value={value}
                    placeholder={`${titleCase(field)}...`}
                    onChange={handleUpdateMeta.bind(null, field)}
                    />
            </div>
        )
    }

    const handleNavigateAgency = (agency: string) => {
        navigate(`/administration/agency/${agency}`)
    }

    const handleUpdateDropdown = (key: string, e: any, isValid: boolean) => {
        setValid(isValid)
        handleUpdateMeta(key, e)
    }

    const renderEditMetadata = () => {
        const { radio } = props
        const cap_options = capabilityList.map(v => ({
            key: v.name,
            value: v.name,
            title: v.description
        }))

        const capability = capabilityList.find(
            c => c.name === data?.capability
        )

        const homebases = baseStations.map(station => ({
            key: station,
            value: station
        }))

        return (
            <>
                <div className="field">
                    <label>Alias</label>
                    <ReadonlyValue>{radio?.cam?.alias}</ReadonlyValue>
                    {radio.cam_modified && (
                        <Small>
                            Last updated by {radio.cam_modified_by} on{' '}
                            {formatTimestamp(radio.cam_modified)}
                        </Small>
                    )}
                </div>

                {basicMetadataFields.map(renderMetadataField)}

                <div className="field">
                    <label>Type</label>
                    <select
                        className="ui input"
                        onChange={handleUpdateMeta.bind(null, 'type')}
                        value={data?.type || ""}>
                            <option key="empty" value=""></option>
                            {radioTypes.map(type => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                    </select>
                </div>

                <SearchableDropdown
                    label="Home Base"
                    options={homebases}
                    placeHolder="Select home base..."
                    onChange={(e, isOption) =>
                        handleUpdateDropdown('home_base', e, isOption)
                    }
                    value={data?.home_base || ""}
                    />
                <SearchableDropdown
                    label="Capability"
                    options={cap_options}
                    placeHolder="Add capability..."
                    onChange={(e, isOption) =>
                        handleUpdateDropdown('capability', e, isOption)
                    }
                    value={capability?.name}
                    title={capability?.description}
                    />
            </>
        )
    }

    const calculateChanges = (changes: any) => {
        const output: string[] = []
        if (changes.cam && changes.cam.alias) {
            output.push(`cam alias = ${changes?.cam?.alias}`)
        }
        if (changes.static) {
            Object.keys(changes.static).forEach(key => {
                output.push(`${key} = ${(changes.static as any)[key]}`)
            })
        }
        return output
    }

    const renderChangelogLine = (log: Changelog, index: number) => (
        <tr key={index}>
            <td>{formatTimestamp(log.timestamp)}</td>
            <td>{log.email}</td>
            <td>{calculateChanges(log.changes).join(', ')}</td>
        </tr>
    )

    const renderChangelog = () => {
        if (!hasPlatformAdmin(props.user)) return <></>
        if (loading) {
            return <div className="ui message">Loading change log...</div>
        }
        if (!radioDetails) {
            return <div className="ui message error">Unable to load changelog</div>
        }

        return (
            <>
                <h2>Change Log</h2>
                <table className="ui table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>User</th>
                        <th>Changes</th>
                    </tr>
                </thead>
                <tbody>
                    {(radioDetails.changelog || []).map(renderChangelogLine)}
                </tbody>
                </table>
            </>
        )
    }

    const {radio, user, agencies} = props
    const agency = getAgencyForRadio(radio, agencies)
    if (!agency) return <>Agency for radio not found</>
    const canEdit = hasAgencyAdminAccess(user, agency)

    return (
        <>
        <h1>
            Radio {radio.radio}{' '}
            <button className="ui button" onClick={handleNavigateAgency.bind(null, agency.agency)}>
                {agency.name} Agency
            </button>
        </h1>

        <form>
            <div className="ui segments">
            <div className="ui segment">
                <div className="ui form">
                {canEdit
                    ? renderEditMetadata()
                    : renderReadonlyMetadata()}

                {radio.admin_modified && (
                    <Small>
                    Last updated by {radio.admin_modified_by} on{' '}
                    {formatTimestamp(radio.admin_modified)}
                    </Small>
                )}
                </div>
            </div>
            {canEdit && (
                <div className="ui segment secondary">
                    <button
                        className={`ui large secondary ${
                        saving ? 'loading' : ''
                        } button`}
                        disabled={saving || !valid}
                        onClick={handleSave}
                    >
                        <i className="icon check"></i>
                        Save
                    </button>
                    <button className="ui large button" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            )}
            </div>
        </form>
        {renderChangelog()}
        </>
    )
  
}

export default EditRadioComponent
