import React from 'react'
import { useParams } from 'react-router'
// import { withRouter } from '../components/WithRouter.component'
import EditAgencyComponent from '../components/EditAgency.component'
import ICommonProps from '../components/ICommonProps'

type PathParamsType = {
  agencyId: string
}

// Your component own properties
type PropsType = // RouterProps &
  ICommonProps & { isNew: boolean }

function EditAgencyPage({isNew, radios, user, agencies}: PropsType) {
    const { agencyId } = useParams<PathParamsType>()
 
    if (isNew) {
      return (
        <EditAgencyComponent
          isNew={true}
          agencyId=""
          radios={radios}
          user={user}
        />
      )
    }

    const agency = agencies.find(
      x => x.agency === agencyId
    )
    if (!agency) return <>Not found</>
    return (
      <EditAgencyComponent
        isNew={false}
        agencyId={agency.agency}
        radios={radios}
        user={user}
      />
    )
  
}

export default EditAgencyPage
