import React from 'react'

type option = {
  key: string
  value: string
}

interface IProps {
  values: option[]
  title: string
  onChange: (value: string) => void
  value: string
}

class MultiValueCriteria extends React.Component<IProps, {}> {
  handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange(e.target.value)
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>{this.props.title}</h4>
        </div>
        <div className="eight wide column">
          <select onChange={this.handleChange} value={this.props.value}>
            {this.props.values.map(option => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
      </>
    )
  }
}

export default MultiValueCriteria
