
import React from 'react'
import { Duress } from '../lib/models'

const alarmIntervalMs = 15 * 1000 // 15 seconds

interface IProps {
  duresses: Duress[]
}

interface IState {
  soundAlarm: boolean
}

declare global {
  interface Window {
    lastSounded?: number
  }
}

class DuressAlarmComponent extends React.Component<IProps, IState> {
  state = {
    soundAlarm: false
  }
  duressInterval: NodeJS.Timeout = null as unknown as NodeJS.Timeout

  componentDidMount() {
    this.startAlarm()
  }

  componentDidUpdate() {
    this.startAlarm()
  }

  startAlarm() {
    if (this.props.duresses.length > 0 && !this.duressInterval) {
      if (window.lastSounded) {
        // in this case the component has been reloaded
        // so don't play the sound straight away,
        // wait for the remainder of the period to elapse
        const timeout = Math.max(
          0,
          alarmIntervalMs - (new Date().getTime() - window.lastSounded)
        )
        this.duressInterval = setTimeout(this.startAlarmInterval, timeout)
        return
      }
      this.startAlarmInterval()
    }
  }

  componentWillUnmount() {
    clearInterval(this.duressInterval)
  }

  startAlarmInterval = () => {
    clearInterval(this.duressInterval)
    this.soundAlarm()
    setInterval(this.resoundAlarm, alarmIntervalMs)
  }

  resoundAlarm = () => {
    if (
      this.props.duresses.filter(
        x => x.active && x.acknowledgements.length === 0
      ).length === 0
    ) {
      clearInterval(this.duressInterval)
      return
    }
    this.soundAlarm()
  }

  soundAlarm = () => {
    window.lastSounded = new Date().getTime()
    try {
      this.audioElement.current?.play()
    } catch {
      console.error(
        'cannot sound the alarm, perhaps the user has not interacted with the page yet?'
      )
    }
  }

  audioElement = React.createRef<HTMLAudioElement>()

  render() {
    return (
      <audio hidden ref={this.audioElement}>
        <source src="/got-it-done.mp3" type="audio/mp3" />
      </audio>
    )
  }
}

export default DuressAlarmComponent
