import React from 'react'
import DropdownComponent from '../Dropdown.component'
import { radiosToQueryResult } from './utilities'
import { Radio } from '../../lib/models'
import SearchButton from '../SearchButton.component'
import AgencyCriteria from './filterCriteria/AgencyCriteria.component'
import RadioRangeCriteria from './filterCriteria/RadioRangeCriteria.component'
import AliasCriteria from './filterCriteria/AliasCriteria.component'
import HomeBaseCriteria from './filterCriteria/HomeBaseCriteria.component'
import CapabilityCriteria from './filterCriteria/CapabilityCriteria.component'
import { ISearchForm } from '../ISearchForm'
import styled from 'styled-components'

const HiddenDiv = styled.div`
  display: none;
`

interface IState {
  criteria: any[]
}

class RadioByFilter extends ISearchForm<{}, IState> {
  state: IState = {
    criteria: []
  }
  customRefs: any[] = []
  handleAddFilter = (name: string) => {
    const { criteria } = this.state
    switch (name) {
      case 'Agency':
        criteria.push(AgencyCriteria)
        break
      case 'Radio ID Range':
        criteria.push(RadioRangeCriteria)
        break
      case 'Alias':
        criteria.push(AliasCriteria)
        break
      case 'Home Base':
        criteria.push(HomeBaseCriteria)
        break
      case 'Capability':
        criteria.push(CapabilityCriteria)
        break
    }
    criteria[criteria.length - 1].id = new Date().getTime()
    this.setState({ criteria: criteria })
  }
  handleSearch = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const filteredRadios = this.props.radios.filter(radio => {
      for (const criteriaComponent of this.customRefs) {
        if (!criteriaComponent) continue
        if (!criteriaComponent.filter(radio)) return false
      }
      return true
    }) as Radio[]
    this.props.onResults(
      radiosToQueryResult(this.props.agencies, filteredRadios)
    )
  }

  handleRemove = (index: number, e: any) => {
    e.preventDefault()
    e.stopPropagation()
    let { criteria: filters } = this.state
    filters = filters.filter((_, i) => index !== i)
    this.setState({ criteria: filters })
  }

  render() {
    this.customRefs = []
    const filterNames = [
      'Radio ID Range',
      'Agency',
      'Home Base',
      'Capability',
      'Alias'
    ]
    return (
      <form className="ui form" onSubmit={this.handleSearch}>
        <HiddenDiv>
          <SearchButton enable={this.state.criteria.length > 0} />
        </HiddenDiv>
        <div className="ui grid" style={{ paddingBottom: 40 }}>
          {this.state.criteria.map((X: any, index: number) => {
            return (
              <React.Fragment key={X.id}>
                <X {...this.props} ref={(x: any) => this.customRefs.push(x)} />
                <div
                  className="four wide column"
                  style={{ textAlign: 'right' }}
                >
                  <button
                    className="ui button"
                    onClick={this.handleRemove.bind(null, index)}
                  >
                    <i className="minus icon"></i>
                    Remove
                  </button>
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <SearchButton enable={this.state.criteria.length > 0} />{' '}
        <DropdownComponent
          title="Add filter"
          icon="plus"
          onSelect={this.handleAddFilter}
          values={filterNames}
          buttonClass="ui large button dropdown"
        />
      </form>
    )
  }
}

export default RadioByFilter
