import React from 'react'
import { Radio, RadioMetadata } from '../lib/models'

const RadioMetadataComponent = (props: {
  radio: Radio
  classNames?: string
}) => {
  if (Object.keys(props.radio.static || {}).length === 0) return <></>
  return (
    <div>
      {Object.keys(props.radio.static || {}).length > 0 && (
        <table className={`ui table very compact small ${props.classNames}`}>
          <tbody>
            {(Object.keys(props.radio.static || {}) as Array<
              keyof RadioMetadata
            >).map(x => {
              return (
                <tr key={x}>
                  <td>{x}</td>
                  <td>{props.radio.static[x]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default RadioMetadataComponent
