import React from 'react'
import { IQueryBuilderComponent } from '../IQueryBuilderComponent'
import RadioIdInput from '../RadioIdInput.component'
import { Radio } from '../../lib/models'
import { createParameter } from '../../lib/api'

interface IState {
  value?: Radio
}

class FilterByRadioIDCriteria extends IQueryBuilderComponent<{}, IState> {
  state: IState = {}

  handleChange = (value?: Radio) => {
    this.setState({ value })
  }

  getParameters() {
    return [createParameter('radio', this.state.value?.radio || '_')]
  }

  getType() {
    return 'radio'
  }

  render() {
    return (
      <>
        <div className="four wide column">
          <h4>Radio ID</h4>
        </div>
        <div className="eight wide column">
          <RadioIdInput
            onChange={this.handleChange}
            radios={this.props.radios}
          />
        </div>
      </>
    )
  }
}

export default FilterByRadioIDCriteria
