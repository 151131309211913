import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { withRouter } from '../components/WithRouter.component'
import { RequirePlatformAdminComponent } from '../components/AccessControl.component'
import AgencyListComponent from '../components/AgencyList.component'
import ICommonProps from '../components/ICommonProps'
import HorizontalLineComponent from '../components/HorizontalLine.component'

// Your component own properties
// type PropsType = RouterProps & ICommonProps

function AdminPage(props: ICommonProps) {
    const navigate = useNavigate();
    
    return (
      <>
        <RequirePlatformAdminComponent {...props}>
          <p>
            <button
              className="ui right floated secondary button"
              onClick={() => navigate('/administration/cam')}>
              <i className="upload icon"></i>
              Upload CAM aliases    
            </button>
          </p>
          <h1>Platform Administration</h1>
          <HorizontalLineComponent />
        </RequirePlatformAdminComponent>

        <AgencyListComponent {...props} />
      </>
    )
}


export default AdminPage
