import React from 'react'
// import { RouterProps } from 'react-router'
// import { withRouter } from '../components/WithRouter.component'
import ICommonProps from '../components/ICommonProps'
import UploadCamComponent from '../components/UploadCam.component'

// type IProps = RouterProps & ICommonProps

class UploadCamPage extends React.Component<ICommonProps> {
  render() {
    return <UploadCamComponent {...this.props} />
  }
}

export default UploadCamPage
