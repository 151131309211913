import React from 'react'

export type DropdownOption = {
  value: string
  key: string
  title?: string
}

type IProps = {
  label: string
  options: DropdownOption[]
  value?: string
  placeHolder?: string
  title?: string
  onChange: (e: any, isOption: boolean, option?: DropdownOption) => void
}

type IState = {
  label: string
  value?: string
  placeHolder?: string
  title?: string
}

export class SearchableDropdown extends React.Component<IProps, IState> {
  state: IState = {
    value: this.props.value,
    label: this.props.label,
    placeHolder: this.props.placeHolder,
    title: this.props.title
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value,  })
    }
  }

  onChange = (e: any) => {
    this.setState({ value: e.target.value })
    const item = this.props.options.find(n => n.value === e.target.value)
    const isOption = !!(item || !e.target.value)
    this.props.onChange(e, isOption, item)
  }

  render() {
    const { options } = this.props
    const { label, value, title, placeHolder } = this.state
    const dataList = options.map(v => (
      <option key={v.key} value={v.value} title={v.title} />
    ))

    return (
      <div className="field">
        <label>{label}</label>
        <input
          type="search"
          placeholder={placeHolder}
          list={label}
          className="ui input"
          onChange={this.onChange}
          value={value}
          title={title}
        />
        <datalist id={label}>{dataList}</datalist>
      </div>
    )
  }
}
