import React from 'react'
import { Radio } from '../../../lib/models'
import { doesAgencyHaveRadio } from '../../../lib/utils'
import { ICriteriaComponent } from '../utilities'
import MultiValueCriteria from '../../MultiValueCriteria.component'

class AgencyCriteria extends ICriteriaComponent<{}, { value: string }> {
  state = { value: this.props.agencies[0].agency }

  filter(radio: Radio) {
    const agency = this.props.agencies.find(x => x.agency === this.state.value)
    if (!agency) return false
    return doesAgencyHaveRadio(agency, radio)
  }

  handleOnChange = (value: string) => this.setState({ value })

  render() {
    const values = this.props.agencies.map(x => ({
      key: x.agency,
      value: x.name
    }))
    return (
      <MultiValueCriteria
        value={this.state.value}
        values={values}
        title="Agency"
        onChange={this.handleOnChange}
      />
    )
  }
}

export default AgencyCriteria
