import { Agency, QueryResult, Radio } from '../../lib/models'
import { getAgencyForRadio } from '../../lib/utils'
import React from 'react'
import { IReportingProps } from '../ICommonProps'

export abstract class ICriteriaComponent<T, Y> extends React.Component<
  T & IReportingProps,
  Y
> {
  abstract filter(radio: Radio): boolean
}

export const radiosToQueryResult = (
  agencies: Agency[],
  radios: Radio[]
): QueryResult => {
  return {
    fields: [
      'Radio ID',
      'Alias',
      'Type',
      'Home Base',
      'Capability',
      'Service ID',
      'Notes',
      'Capacity',
      'Agency Abbreviation',
      'Agency Name'
    ],
    values: radios.map(radio => {
      const agency = getAgencyForRadio(radio, agencies)
      return [
        radio.radio,
        radio.cam?.alias || '',
        radio.static?.type || '',
        radio.static?.home_base || '',
        radio.static?.capability || '',
        radio.static?.service_id || '',
        radio.static?.notes || '',
        radio.static?.capacity || '',
        agency?.abbr || '',
        agency?.name || ''
      ]
    })
  }
}
