import React from 'react'
import styled from 'styled-components'

const ClickableDiv = styled.div`
  cursor: pointer;
`

interface IProps {
  tabClass?: string
  tabs: string[]
  activeIndex: number
  onChange: (index: number) => void
}

const TabsComponent = (props: IProps) => (
  <div className={props.tabClass || 'ui tabular menu'}>
    {props.tabs.map((tab, index) => (
      <ClickableDiv
        key={tab}
        className={`${index === props.activeIndex ? 'active ' : ''}item`}
        onClick={props.onChange.bind(null, index)}
      >
        {tab}
      </ClickableDiv>
    ))}
  </div>
)

export default TabsComponent
